import React, { memo } from 'react';
import PropTypes from 'prop-types';

import { ReactComponent as MasterCard } from 'assets/cards/mastercard.svg';
import { ReactComponent as Visa } from 'assets/cards/visa.svg';

import { IconsStyled } from './Icons.styles';

const { Flag, FlagNotFound } = IconsStyled;

const renderFlag = (flag) => {
  switch (flag) {
    case 'visa':
      return <Visa />;
    case 'mastercard':
      return <MasterCard />;
    default:
      return <FlagNotFound/>;
  }
};

const CardFlag = ({ flag }) => (
  <Flag data-testid="card-flag">
    {renderFlag(flag)}
  </Flag>
);

CardFlag.propTypes = {
  flag: PropTypes.string,
};

CardFlag.defaultProps = {
  flag: 'mastercard',
};

export default memo(CardFlag);
