import { handleActions } from 'redux-actions';
import produce from 'immer';

import { STATUS } from 'core/constants';
import { AccountConstants } from '../constants';

export const accountState = {
  closeAccount: {
    accountNumber: '',
    message: '',
    reason: '',
    status: STATUS.IDLE,
  },
  reopenAccount: {
    message: '',
    status: STATUS.IDLE,
  },
  earningReport: {
    availableDates: {
      years: [],
      message: '',
      status: STATUS.IDLE,
    },
    report: {
      message: '',
      status: STATUS.IDLE,
    },
  },
  fetch: {
    data: {},
    message: '',
    status: STATUS.IDLE,
  },
  judicialBlockState: {
    data: {},
    message: '',
    status: STATUS.IDLE,
  },
  kyc: {
    accountOcr: {
      data: {},
      status: STATUS.IDLE,
    },
    backgroundCheck: {
      checks: [],
      data: {},
      status: STATUS.IDLE,
    },
    companyProcess: {
      checks: [],
      data: {},
      status: STATUS.IDLE,
    },
    documentRequired: {
      message: '',
      status: STATUS.IDLE,
    },
    facematch: {
      data: {},
      status: STATUS.IDLE,
    },
    files: {
      data: {
        liveness: [],
        facematch: [],
      },
      status: STATUS.IDLE,
    },
    pepAndSanctions: {
      data: {},
      status: STATUS.IDLE,
    },
    statusUpdate: {
      message: '',
      status: STATUS.IDLE,
    },
  },
  makeAccountSuspended: {
    nationalId: '',
    message: '',
    reason: '',
    status: STATUS.IDLE,
  },
  makeAccountUnlimited: {
    message: '',
    status: STATUS.IDLE,
  },
  pj: {
    message: '',
    status: STATUS.IDLE,
  },
  receipt: {
    fileContent: '',
    filename: '',
    message: '',
    status: STATUS.IDLE,
  },
  removeAccountSuspension: {
    nationalId: '',
    message: '',
    status: STATUS.IDLE,
  },
  shipmentOrders: {
    data: [],
    message: '',
    status: STATUS.IDLE,
  },
  statement: {
    fileContent: '',
    filename: '',
    message: '',
    startDate: '',
    endDate: '',
    status: STATUS.IDLE,
  },
  ted: {
    message: '',
    status: STATUS.IDLE,
  },
  transactions: {
    data: [],
    filters: {
      data: {},
      startDate: '',
      endDate: '',
    },
    message: '',
    pagination: {
      currentPage: '',
      hasNextPage: null,
      hasPreviousPage: null,
      totalCount: '',
      totalPages: '',
    },
    status: STATUS.IDLE,
  },
  updateAccountData: {
    message: '',
    status: STATUS.IDLE,
  },
  userLogs: {
    data: [],
    message: '',
    status: STATUS.IDLE,
  },
};

export default {
  accounts: handleActions({
    [AccountConstants.ACCOUNT_HOLDER_CLOSE_LEDGER_ACCOUNT_REQUEST]: (state, { payload: { accountNumber, reason } }) => produce(state, draft => {
      draft.closeAccount.accountNumber = accountNumber;
      draft.closeAccount.reason = reason;
      draft.closeAccount.status = STATUS.RUNNING;
    }),
    [AccountConstants.ACCOUNT_HOLDER_CLOSE_LEDGER_ACCOUNT_SUCCESS]: (state) => produce(state, draft => {
      draft.closeAccount.status = STATUS.SUCCESS;
    }),
    [AccountConstants.ACCOUNT_HOLDER_CLOSE_LEDGER_ACCOUNT_FAILURE]: (state, { payload: { message } }) => produce(state, draft => {
      draft.closeAccount.message = message;
      draft.closeAccount.status = STATUS.ERROR;
    }),
    [AccountConstants.ACCOUNT_HOLDER_REOPEN_LEDGER_ACCOUNT_REQUEST]: (state) => produce(state, draft => {
      draft.reopenAccount.status = STATUS.RUNNING;
    }),
    [AccountConstants.ACCOUNT_HOLDER_REOPEN_LEDGER_ACCOUNT_SUCCESS]: (state) => produce(state, draft => {
      draft.reopenAccount.status = STATUS.SUCCESS;
    }),
    [AccountConstants.ACCOUNT_HOLDER_REOPEN_LEDGER_ACCOUNT_FAILURE]: (state, { payload: { message } }) => produce(state, draft => {
      draft.reopenAccount.message = message;
      draft.reopenAccount.status = STATUS.ERROR;
    }),
    [AccountConstants.ACCOUNT_EARNING_REPORT_REQUEST]: (state) => produce(state, draft => {
      draft.earningReport.report.status = STATUS.RUNNING;
    }),
    [AccountConstants.ACCOUNT_EARNING_REPORT_SUCCESS]: (state) => produce(state, draft => {
      draft.earningReport.report.status = STATUS.SUCCESS;
    }),
    [AccountConstants.ACCOUNT_EARNING_REPORT_FAILURE]: (state, { payload: { message } }) => produce(state, draft => {
      draft.earningReport.report.message = message;
      draft.earningReport.report.status = STATUS.ERROR;
    }),
    [AccountConstants.ACCOUNT_JUDICIAL_BLOCK_REQUEST]: (state) => produce(state, draft => {
      draft.judicialBlockState.status = STATUS.RUNNING;
    }),
    [AccountConstants.ACCOUNT_JUDICIAL_BLOCK_SUCCESS]: (state) => produce(state, draft => {
      draft.judicialBlockState.status = STATUS.SUCCESS;
    }),
    [AccountConstants.ACCOUNT_JUDICIAL_BLOCK_FAILURE]: (state, { payload: { message } }) => produce(state, draft => {
      draft.judicialBlockState.message = message;
      draft.judicialBlockState.status = STATUS.ERROR;
    }),
    [AccountConstants.ACCOUNT_EARNING_REPORT_AVAILABLE_YEARS_REQUEST]: (state) => produce(state, draft => {
      draft.earningReport.availableDates.status = STATUS.RUNNING;
    }),
    [AccountConstants.ACCOUNT_EARNING_REPORT_AVAILABLE_YEARS_SUCCESS]: (state, { payload: { years } }) => produce(state, draft => {
      draft.earningReport.availableDates.years = years;
      draft.earningReport.availableDates.status = STATUS.SUCCESS;
    }),
    [AccountConstants.ACCOUNT_EARNING_REPORT_AVAILABLE_YEARS_FAILURE]: (state, { payload: { message } }) => produce(state, draft => {
      draft.earningReport.availableDates.message = message;
      draft.earningReport.availableDates.status = STATUS.ERROR;
    }),
    [AccountConstants.ACCOUNT_HOLDER_FETCH_REQUEST]: (state) => produce(state, draft => {
      draft.fetch.status = STATUS.RUNNING;
    }),
    [AccountConstants.ACCOUNT_HOLDER_FETCH_SUCCESS]: (state, { payload: { data } }) => produce(state, draft => {
      draft.fetch.data = data;
      draft.kyc.files.data.facematch = [];
      draft.kyc.files.data.liveness = [];
      draft.fetch.status = STATUS.SUCCESS;
    }),
    [AccountConstants.ACCOUNT_HOLDER_FETCH_FAILURE]: (state, { payload: { message } }) => produce(state, draft => {
      draft.fetch = {
        data: null,
        message,
        status: STATUS.ERROR,
      };
    }),
    [AccountConstants.ACCOUNT_HOLDER_GET_RECEIPT_REQUEST]: (state) => produce(state, draft => {
      draft.receipt.status = STATUS.RUNNING;
    }),
    [AccountConstants.ACCOUNT_HOLDER_GET_RECEIPT_SUCCESS]: (state, { payload: { fileContent, filename } }) => produce(state, draft => {
      draft.receipt.fileContent = fileContent;
      draft.receipt.filename = filename;
      draft.receipt.status = STATUS.SUCCESS;
    }),
    [AccountConstants.ACCOUNT_HOLDER_GET_RECEIPT_FAILURE]: (state, { payload: { message } }) => produce(state, draft => {
      draft.receipt.message = message;
      draft.receipt.status = STATUS.ERROR;
    }),
    [AccountConstants.ACCOUNT_HOLDER_SET_DATE_STATEMENT]: (state, { payload }) => produce(state, draft => {
      draft.statement = {
        startDate: payload.startDate,
        endDate: payload.endDate,
      };
    }),
    [AccountConstants.ACCOUNT_HOLDER_PJ_FETCH_REQUEST]: (state) => produce(state, draft => {
      draft.pj.status = STATUS.RUNNING;
    }),
    [AccountConstants.ACCOUNT_HOLDER_PJ_FETCH_SUCCESS]: (state, { payload: { data } }) => produce(state, draft => {
      draft.fetch.data = data;
      draft.kyc.files.data.facematch = [];
      draft.kyc.files.data.liveness = [];
      draft.pj.status = STATUS.SUCCESS;
    }),
    [AccountConstants.ACCOUNT_HOLDER_PJ_FETCH_FAILURE]: (state, { payload: { message } }) => produce(state, draft => {
      draft.pj = {
        data: null,
        message,
        status: STATUS.ERROR,
      };
    }),
    [AccountConstants.ACCOUNT_HOLDER_CREATE_STATEMENT_REQUEST]: (state) => produce(state, draft => {
      draft.statement.status = STATUS.RUNNING;
    }),
    [AccountConstants.ACCOUNT_HOLDER_CREATE_STATEMENT_SUCCESS]: (state, { payload: { fileContent, filename } }) => produce(state, draft => {
      draft.statement.fileContent = fileContent;
      draft.statement.filename = filename;
      draft.statement.status = STATUS.SUCCESS;
    }),
    [AccountConstants.ACCOUNT_HOLDER_CREATE_STATEMENT_FAILURE]: (state, { payload: { message } }) => produce(state, draft => {
      draft.statement.message = message;
      draft.statement.status = STATUS.ERROR;
    }),
    [AccountConstants.ACCOUNT_HOLDER_UPDATE_DATA_REQUEST]: (state) => produce(state, draft => {
      draft.updateAccountData.status = STATUS.RUNNING;
    }),
    [AccountConstants.ACCOUNT_HOLDER_UPDATE_DATA_SUCCESS]: (state) => produce(state, draft => {
      draft.updateAccountData.status = STATUS.SUCCESS;
    }),
    [AccountConstants.ACCOUNT_HOLDER_UPDATE_DATA_FAILURE]: (state, { payload: { message } }) => produce(state, draft => {
      draft.updateAccountData = {
        message,
        status: STATUS.ERROR,
      };
    }),
    [AccountConstants.ACCOUNT_HOLDER_MAKE_ACCOUNT_UNLIMITED_REQUEST]: (state) => produce(state, draft => {
      draft.makeAccountUnlimited.status = STATUS.RUNNING;
    }),
    [AccountConstants.ACCOUNT_HOLDER_MAKE_ACCOUNT_UNLIMITED_SUCCESS]: (state) => produce(state, draft => {
      draft.makeAccountUnlimited.status = STATUS.SUCCESS;
    }),
    [AccountConstants.ACCOUNT_HOLDER_MAKE_ACCOUNT_UNLIMITED_FAILURE]: (state, { payload: { message } }) => produce(state, draft => {
      draft.makeAccountUnlimited = {
        message,
        status: STATUS.ERROR,
      };
    }),
    [AccountConstants.ACCOUNT_HOLDER_UPDATE_ONBOARDING_DOC_REQ_REQUEST]: (state) => produce(state, draft => {
      draft.kyc.documentRequired.status = STATUS.RUNNING;
    }),
    [AccountConstants.ACCOUNT_HOLDER_UPDATE_ONBOARDING_DOC_REQ_SUCCESS]: (state) => produce(state, draft => {
      draft.kyc.documentRequired.status = STATUS.SUCCESS;
    }),
    [AccountConstants.ACCOUNT_HOLDER_UPDATE_ONBOARDING_DOC_REQ_FAILURE]: (state, { payload: { message } }) => produce(state, draft => {
      draft.kyc.documentRequired = {
        message,
        status: STATUS.ERROR,
      };
    }),
    [AccountConstants.ACCOUNT_OPERATION_MANUAL_TED_REQUEST]: (state) => produce(state, draft => {
      draft.ted.status = STATUS.RUNNING;
    }),
    [AccountConstants.ACCOUNT_OPERATION_MANUAL_TED_SUCCESS]: (state) => produce(state, draft => {
      draft.ted.status = STATUS.SUCCESS;
    }),
    [AccountConstants.ACCOUNT_OPERATION_MANUAL_TED_FAILURE]: (state, { payload: { message } }) => produce(state, draft => {
      draft.ted = {
        message,
        status: STATUS.ERROR,
      };
    }),
    [AccountConstants.ACCOUNT_HOLDER_REMOVE_ACCOUNT_SUSPENSION_REQUEST]: (state, { payload: { nationalId } }) => produce(state, draft => {
      draft.removeAccountSuspension.nationalId = nationalId;
      draft.removeAccountSuspension.status = STATUS.RUNNING;
    }),
    [AccountConstants.ACCOUNT_HOLDER_REMOVE_ACCOUNT_SUSPENSION_SUCCESS]: (state) => produce(state, draft => {
      draft.removeAccountSuspension.status = STATUS.SUCCESS;
    }),
    [AccountConstants.ACCOUNT_HOLDER_REMOVE_ACCOUNT_SUSPENSION_FAILURE]: (state, { payload: { message } }) => produce(state, draft => {
      draft.removeAccountSuspension.message = message;
      draft.removeAccountSuspension.status = STATUS.ERROR;
    }),
    [AccountConstants.ACCOUNT_SHIPMENT_ORDERS_REQUEST]: (state) => produce(state, draft => {
      draft.shipmentOrders.status = STATUS.RUNNING;
    }),
    [AccountConstants.ACCOUNT_SHIPMENT_ORDERS_SUCCESS]: (state, { payload: { data } }) => produce(state, draft => {
      draft.shipmentOrders.data = data;
      draft.shipmentOrders.status = STATUS.SUCCESS;
    }),
    [AccountConstants.ACCOUNT_SHIPMENT_ORDERS_FAILURE]: (state, { payload: { message } }) => produce(state, draft => {
      draft.shipmentOrders = {
        data: null,
        message,
        status: STATUS.ERROR,
      };
    }),
    [AccountConstants.ACCOUNT_HOLDER_MAKE_ACCOUNT_SUSPENDED_REQUEST]: (state, { payload: { nationalId, reason } }) => produce(state, draft => {
      draft.makeAccountSuspended.nationalId = nationalId;
      draft.makeAccountSuspended.reason = reason;
      draft.makeAccountSuspended.status = STATUS.RUNNING;
    }),
    [AccountConstants.ACCOUNT_HOLDER_MAKE_ACCOUNT_SUSPENDED_SUCCESS]: (state) => produce(state, draft => {
      draft.makeAccountSuspended.status = STATUS.SUCCESS;
    }),
    [AccountConstants.ACCOUNT_HOLDER_MAKE_ACCOUNT_SUSPENDED_FAILURE]: (state, { payload: { message } }) => produce(state, draft => {
      draft.makeAccountSuspended.message = message;
      draft.makeAccountSuspended.status = STATUS.ERROR;
    }),
    [AccountConstants.ACCOUNT_TRANSACTIONS_FETCH_REQUEST]: (state) => produce(state, draft => {
      draft.transactions = {
        data: [],
        filters: {
          data: {},
        },
        status: STATUS.ERROR,
      };
      draft.transactions.status = STATUS.RUNNING;
    }),
    [AccountConstants.ACCOUNT_TRANSACTIONS_SUCCESS]: (state, { payload: { data, filters } }) => produce(state, draft => {
      draft.transactions.data = data.edges.map(d => d.node).filter(transactions => !!transactions);
      draft.transactions.pagination = {
        currentPage: data.currentPage,
        hasNextPage: data.pageInfo.hasNextPage,
        hasPreviousPage: data.pageInfo.hasPreviousPage,
        totalCount: data.totalCount,
        totalPages: data.totalPages,
      };
      draft.transactions.filters = {
        data: filters,
        startDate: data.startDate,
        endDate: data.endDate,
      };
      draft.statement = {
        startDate: data.startDate,
        endDate: data.endDate,
      };
      draft.transactions.status = STATUS.SUCCESS;
    }),
    [AccountConstants.ACCOUNT_TRANSACTIONS_FAILURE]: (state, { payload: { message } }) => produce(state, draft => {
      draft.transactions = {
        data: [],
        filters: {
          data: {},
        },
        message,
        status: STATUS.ERROR,
      };
    }),
    [AccountConstants.ACCOUNT_USER_LOGS_FAILURE]: (state, { payload: { message } }) => produce(state, draft => {
      draft.userLogs = {
        data: [],
        message,
        status: STATUS.ERROR,
      };
    }),
    [AccountConstants.ACCOUNT_USER_LOGS_REQUEST]: (state) => produce(state, draft => {
      draft.userLogs.status = STATUS.RUNNING;
    }),
    [AccountConstants.ACCOUNT_USER_LOGS_SUCCESS]: (state, { payload: { data } }) => produce(state, draft => {
      draft.userLogs = {
        data,
        status: STATUS.SUCCESS,
      };
    }),
    [AccountConstants.KYC_BACKGROUND_CHECK_REQUEST]: (state) => produce(state, draft => {
      draft.kyc.backgroundCheck.status = STATUS.RUNNING;
    }),
    [AccountConstants.KYC_BACKGROUND_CHECK_SUCCESS]: (state, { payload: { data, checks } }) => produce(state, draft => {
      draft.kyc.backgroundCheck.data = data;
      draft.kyc.backgroundCheck.checks = checks;
      draft.kyc.backgroundCheck.status = STATUS.SUCCESS;
    }),
    [AccountConstants.KYC_BACKGROUND_CHECK_FAILURE]: (state, { payload: { message } }) => produce(state, draft => {
      draft.kyc.backgroundCheck = {
        message,
        status: STATUS.ERROR,
      };
    }),
    [AccountConstants.KYC_COMPANY_PROCESS_REQUEST]: (state) => produce(state, draft => {
      draft.kyc.companyProcess.status = STATUS.RUNNING;
    }),
    [AccountConstants.KYC_COMPANY_PROCESS_SUCCESS]: (state, { payload: { data, checks } }) => produce(state, draft => {
      draft.kyc.companyProcess.data = data;
      draft.kyc.companyProcess.checks = checks;
      draft.kyc.companyProcess.status = STATUS.SUCCESS;
    }),
    [AccountConstants.KYC_COMPANY_PROCESS_FAILURE]: (state, { payload: { message } }) => produce(state, draft => {
      draft.kyc.companyProcess = {
        message,
        status: STATUS.ERROR,
      };
    }),
    [AccountConstants.ACCOUNT_OCR_REQUEST]: (state) => produce(state, draft => {
      draft.kyc.accountOcr.status = STATUS.RUNNING;
    }),
    [AccountConstants.ACCOUNT_OCR_SUCCESS]: (state, { payload: { data } }) => produce(state, draft => {
      draft.kyc.accountOcr.data = data;
      draft.kyc.accountOcr.status = STATUS.SUCCESS;
    }),
    [AccountConstants.ACCOUNT_OCR_FAILURE]: (state, { payload: { message } }) => produce(state, draft => {
      draft.kyc.accountOcr = {
        message,
        status: STATUS.ERROR,
      };
    }),
    [AccountConstants.KYC_FACEMATCH_REQUEST]: (state, { payload: { data } }) => produce(state, draft => {
      draft.kyc.facematch.data = data;
      draft.kyc.facematch.status = STATUS.RUNNING;
    }),
    [AccountConstants.KYC_FACEMATCH_SUCCESS]: (state, { payload: { data } }) => produce(state, draft => {
      draft.kyc.facematch.data = data;
      draft.kyc.facematch.status = STATUS.SUCCESS;
    }),
    [AccountConstants.KYC_FACEMATCH_FAILURE]: (state, { payload: { message } }) => produce(state, draft => {
      draft.kyc.facematch = {
        data: {},
        message,
        status: STATUS.ERROR,
      };
    }),
    [AccountConstants.KYC_PEP_AND_SANCTIONS_REQUEST]: (state, { payload: { data } }) => produce(state, draft => {
      draft.kyc.pepAndSanctions.data = data;
      draft.kyc.pepAndSanctions.status = STATUS.RUNNING;
    }),
    [AccountConstants.KYC_PEP_AND_SANCTIONS_SUCCESS]: (state, { payload: { data } }) => produce(state, draft => {
      draft.kyc.pepAndSanctions.data = data;
      draft.kyc.pepAndSanctions.status = STATUS.SUCCESS;
    }),
    [AccountConstants.KYC_PEP_AND_SANCTIONS_FAILURE]: (state, { payload: { message } }) => produce(state, draft => {
      draft.kyc.pepAndSanctions = {
        data: {},
        message,
        status: STATUS.ERROR,
      };
    }),
    [AccountConstants.KYC_FACEMATCH_FILE_REQUEST]: (state) => produce(state, draft => {
      draft.kyc.files.status = STATUS.RUNNING;
    }),
    [AccountConstants.KYC_FACEMATCH_FILE_SUCCESS]: (state, { payload: { data } }) => produce(state, draft => {
      draft.kyc.files.data.liveness = [
        ...draft.kyc.files.data.liveness,
        {
          url: data?.liveness?.url,
          alt: data?.liveness?.alt,
        },
      ];
      draft.kyc.files.data.facematch = [
        ...draft.kyc.files.data.facematch,
        {
          url: data?.facematch?.url,
          alt: data?.facematch?.alt,
        },
      ];
      draft.kyc.files.status = STATUS.SUCCESS;
    }),
    [AccountConstants.KYC_FACEMATCH_FILE_FAILURE]: (state, { payload: { message } }) => produce(state, draft => {
      draft.kyc.files = {
        ...draft.kyc.files.data,
        message,
        status: STATUS.ERROR,
      };
    }),
    [AccountConstants.KYC_MAKE_ACCOUNT_KYC_REQUIRED_FAILURE]: (state, { payload: { message } }) => produce(state, draft => {
      draft.kyc.statusUpdate = {
        message,
        status: STATUS.ERROR,
      };
    }),
    [AccountConstants.KYC_MAKE_ACCOUNT_KYC_REQUIRED_REQUEST]: (state) => produce(state, draft => {
      draft.kyc.statusUpdate = {
        status: STATUS.RUNNING,
      };
    }),
    [AccountConstants.KYC_MAKE_ACCOUNT_KYC_REQUIRED_SUCCESS]: (state) => produce(state, draft => {
      draft.kyc.statusUpdate = {
        status: STATUS.SUCCESS,
      };
    }),
  }, accountState),
};
