import { all, put, takeLatest, call } from 'redux-saga/effects';

import { client, getRequestOptions } from 'core/modules/resources';
import { parseResponseError } from 'core/modules/client';

import { PixConstants } from '../constants';

import {
  queryCheckPixTransfer,
  queryGetPixInfraction,
  queryGetPixRefund,
  queryGetPixTransfer,
  queryListPixInfractions,
  queryListPixRefunds,
  queryListPixAddressKeys,
  queryListPixClaims,
  queryListFraudMark,
} from '../graphql/queries';

import {
  mutationAnswerInfraction,
  mutationReportInfraction,
  mutationDeletePixAddressKey,
  mutationCreateFraudMark,
} from '../graphql/mutations';

export function* getPixInfraction({ payload: { dictInfractionId } }) {
  try {
    const { data, errors } = yield call(client, {
      ...(yield getRequestOptions()),
      ...(yield queryGetPixInfraction(dictInfractionId)),
      method: 'POST',
      operationName: 'pixInfraction',
    });

    if (!data?.pixInfraction) {
      throw new Error(errors[0].message);
    }

    yield put({
      type: PixConstants.GET_PIX_INFRACTION_SUCCESS,
      payload: {
        data: data?.pixInfraction,
      },
    });
  }
  catch ({ message, status }) {
    const error = parseResponseError(message, status);

    yield put({
      type: PixConstants.GET_PIX_INFRACTION_FAILURE,
      payload: { message: error || '' },
    });
  }
}

export function* checkTransfer({ payload: { endToEndId } }) {
  try {
    const { data, errors } = yield call(client, {
      ...(yield getRequestOptions()),
      ...(yield queryCheckPixTransfer(endToEndId)),
      method: 'POST',
      operationName: 'checkPixTransfer',
    });

    if (!data?.checkPixTransfer) {
      throw new Error(errors[0].message);
    }

    yield put({
      type: PixConstants.CHECK_PIX_TRANSFER_SUCCESS,
      payload: {
        data: data?.checkPixTransfer,
      },
    });
  }
  catch ({ message, status }) {
    const error = parseResponseError(message, status);

    yield put({
      type: PixConstants.CHECK_PIX_TRANSFER_FAILURE,
      payload: { message: error || '' },
    });
  }
}

export function* listPixInfractions({ payload: variables }) {
  try {
    const { data, errors } = yield call(client, {
      ...(yield getRequestOptions()),
      ...(yield queryListPixInfractions(variables)),
      method: 'POST',
      operationName: 'listPixInfractions',
    });

    if (!data?.pixInfractions.edges) {
      throw new Error(errors[0].message);
    }

    yield put({
      type: PixConstants.LIST_INFRACTIONS_SUCCESS,
      payload: {
        data: {
          pageNumber: data?.pixInfractions.currentPage,
          totalCount: data?.pixInfractions.totalCount,
          totalPages: data?.pixInfractions.totalPages,
          infractions: data?.pixInfractions.edges.map((i) => i.node),
        },
      },
    });
  }
  catch ({ message, status }) {
    const error = parseResponseError(message, status);

    yield put({
      type: PixConstants.LIST_INFRACTIONS_FAILURE,
      payload: { message: error || '' },
    });
  }
}

export function* getPixTransfer({ payload: variables }) {
  try {
    const { data, errors } = yield call(client, {
      ...(yield getRequestOptions()),
      ...(yield queryGetPixTransfer(variables)),
      method: 'POST',
      operationName: 'pixTransfer',
    });

    if (!data?.pixTransfer) {
      throw new Error(errors[0].message);
    }

    yield put({
      type: PixConstants.GET_PIX_TRANSFER_SUCCESS,
      payload: {
        data: data?.pixTransfer,
      },
    });
  }
  catch ({ message, status }) {
    const error = parseResponseError(message, status);

    yield put({
      type: PixConstants.GET_PIX_TRANSFER_FAILURE,
      payload: { message: error || '' },
    });
  }
}

export function* answerPixInfraction({
  payload: { dictInfractionId, status, analysisDetails },
}) {
  try {
    const { data, errors } = yield call(client, {
      ...(yield getRequestOptions()),
      ...(yield mutationAnswerInfraction(
        dictInfractionId,
        status,
        analysisDetails
      )),
      method: 'POST',
      operationName: 'answerPixInfraction',
    });

    if (!data?.answerPixInfraction) {
      throw new Error(errors[0]?.message);
    }

    yield put({
      type: PixConstants.ANSWER_PIX_INFRACTION_SUCCESS,
      payload: {
        data: {
          sucessful: data?.answerPixInfraction,
        },
      },
    });
  }
  catch ({ message, errorStatus }) {
    const error = parseResponseError(message, errorStatus);

    yield put({
      type: PixConstants.ANSWER_PIX_INFRACTION_FAILURE,
      payload: { message: error || [] },
    });
  }
}

export function* getPixRefund({ payload: { dictRefundRequestId } }) {
  try {
    const { data, errors } = yield call(client, {
      ...(yield getRequestOptions()),
      ...(yield queryGetPixRefund(dictRefundRequestId)),
      method: 'POST',
      operationName: 'pixRefund',
    });

    if (!data?.pixRefund) {
      throw new Error(errors[0].message);
    }

    yield put({
      type: PixConstants.GET_PIX_REFUND_SUCCESS,
      payload: {
        data: data?.pixRefund,
      },
    });
  }
  catch ({ message, status }) {
    const error = parseResponseError(message, status);

    yield put({
      type: PixConstants.GET_PIX_REFUND_FAILURE,
      payload: { message: error || '' },
    });
  }
}

export function* listPixRefunds({
  payload: { receiverIspb, first, pageNumber },
}) {
  const filterInput = { receiverIspb };
  try {
    const { data, errors } = yield call(client, {
      ...(yield getRequestOptions()),
      ...(yield queryListPixRefunds(filterInput, first, pageNumber)),
      method: 'POST',
      operationName: 'listPixRefunds',
    });

    if (!data?.pixRefunds.edges) {
      throw new Error(errors[0].message);
    }

    yield put({
      type: PixConstants.LIST_REFUNDS_SUCCESS,
      payload: {
        data: {
          pageNumber: data?.pixRefunds.currentPage,
          totalCount: data?.pixRefunds.totalCount,
          totalPages: data?.pixRefunds.totalPages,
          refunds: data?.pixRefunds.edges.map((i) => i.node),
        },
      },
    });
  }
  catch ({ message, status }) {
    const error = parseResponseError(message, status);

    yield put({
      type: PixConstants.LIST_REFUNDS_FAILURE,
      payload: { message: error },
    });
  }
}

export function* reportInfraction({ payload: variables }) {
  try {
    const { data, errors } = yield call(client, {
      ...(yield getRequestOptions()),
      ...(yield mutationReportInfraction(variables)),
      method: 'POST',
      operationName: 'reportInfraction',
    });

    if (!data?.reportInfraction) {
      throw new Error(errors[0]?.message);
    }

    yield put({
      type: PixConstants.REPORT_INFRACTION_SUCCESS,
      payload: {
        data: data?.reportInfraction,
      },
    });
  }
  catch ({ message, errorStatus }) {
    const error = parseResponseError(message, errorStatus);

    yield put({
      type: PixConstants.REPORT_INFRACTION_FAILURE,
      payload: { message: error || [] },
    });
  }
}

export function* listPixAddressKeys({ payload: { accountId } }) {
  try {
    const { data, errors } = yield call(client, {
      ...(yield getRequestOptions()),
      ...(yield queryListPixAddressKeys(accountId)),
      method: 'POST',
      operationName: 'listPixAddressKeys',
    });

    if (!data?.pixAddressKeys) {
      throw new Error(errors[0].message);
    }

    yield put({
      type: PixConstants.LIST_PIX_ADDRESS_KEYS_SUCCESS,
      payload: {
        data: {
          addressKeys: data?.pixAddressKeys || [],
        },
      },
    });
  }
  catch ({ message, status }) {
    const error = parseResponseError(message, status);

    yield put({
      type: PixConstants.LIST_PIX_ADDRESS_KEYS_FAILURE,
      payload: { message: error || '' },
    });
  }
}

export function* deletePixAddressKey({ payload: { accountId, key } }) {
  try {
    const { data, errors } = yield call(client, {
      ...(yield getRequestOptions()),
      ...(yield mutationDeletePixAddressKey(accountId, key)),
      method: 'POST',
      operationName: 'deletePixAddressKey',
    });

    if (!data?.deletePixAddressKey) {
      throw new Error(errors[0]?.message);
    }

    yield put({
      type: PixConstants.DELETE_PIX_ADDRESS_KEY_SUCCESS,
      payload: {
        data: data?.deletePixAddressKey,
        accountId,
        key,
      },
    });
  }
  catch ({ message, status }) {
    const error = parseResponseError(message, status);

    yield put({
      type: PixConstants.DELETE_PIX_ADDRESS_KEY_FAILURE,
      payload: { message: error || [], data: null, accountId, key },
    });
  }
}

export function* listPixClaims({ payload: { accountId } }) {
  try {
    const { data, errors } = yield call(client, {
      ...(yield getRequestOptions()),
      ...(yield queryListPixClaims(accountId)),
      method: 'POST',
      operationName: 'listPixClaims',
    });

    if (!data?.pixClaims) {
      throw new Error(errors[0].message);
    }

    yield put({
      type: PixConstants.LIST_PIX_CLAIMS_SUCCESS,
      payload: {
        data: {
          pixClaims: data?.pixClaims || [],
        },
      },
    });
  }
  catch ({ message, status }) {
    const error = parseResponseError(message, status);

    yield put({
      type: PixConstants.LIST_PIX_CLAIMS_FAILURE,
      payload: { message: error || '' },
    });
  }
}


export function* createFraudMark({ payload: { nationalId, fraudType, personType } }) {
  try {
    const { data, errors } = yield call(client, {
      ...(yield getRequestOptions()),
      ...(yield mutationCreateFraudMark(nationalId, fraudType, personType)),
      method: 'POST',
      operationName: 'createFraudMark',
    });

    if (!data?.includeFraudMark) {
      throw new Error(errors[0]?.message);
    }

    yield put({
      type: PixConstants.INCLUDE_FRAUD_MARK_SUCCESS,
      payload: {
        data: data?.includeFraudMark,
      },
    });
  }
  catch ({ message, status }) {
    const error = parseResponseError(message, status);

    yield put({
      type: PixConstants.INCLUDE_FRAUD_MARK_FAILURE,
      payload: { message: error || [], data: null },
    });
  }
}


export function* listFraudMark({ payload: { nationalId } }) {
  try {
    const { data, errors } = yield call(client, {
      ...(yield getRequestOptions()),
      ...(yield queryListFraudMark(nationalId)),
      method: 'POST',
      operationName: 'listFraudMark',
    });

    if (!data?.fraudMarks) {
      throw new Error(errors[0]?.message);
    }

    yield put({
      type: PixConstants.LIST_FRAUD_MARK_SUCCESS,
      payload: {
        data: data?.fraudMarks,
      },
    });
  }
  catch ({ message, status }) {
    const error = parseResponseError(message, status);

    yield put({
      type: PixConstants.LIST_FRAUD_MARK_FAILURE,
      payload: { message: error || [], data: null },
    });
  }
}

export default function* root() {
  yield all([
    takeLatest(PixConstants.ANSWER_PIX_INFRACTION_REQUEST, answerPixInfraction),
    takeLatest(PixConstants.CHECK_PIX_TRANSFER_REQUEST, checkTransfer),
    takeLatest(PixConstants.GET_PIX_INFRACTION_REQUEST, getPixInfraction),
    takeLatest(PixConstants.GET_PIX_REFUND_REQUEST, getPixRefund),
    takeLatest(PixConstants.GET_PIX_TRANSFER_REQUEST, getPixTransfer),
    takeLatest(PixConstants.LIST_INFRACTIONS_REQUEST, listPixInfractions),
    takeLatest(PixConstants.LIST_REFUNDS_REQUEST, listPixRefunds),
    takeLatest(PixConstants.REPORT_INFRACTION_REQUEST, reportInfraction),
    takeLatest(PixConstants.LIST_PIX_ADDRESS_KEYS_REQUEST, listPixAddressKeys),
    takeLatest(PixConstants.LIST_PIX_CLAIMS_REQUEST, listPixClaims),
    takeLatest(
      PixConstants.DELETE_PIX_ADDRESS_KEY_REQUEST,
      deletePixAddressKey
    ),
    takeLatest(PixConstants.INCLUDE_FRAUD_MARK_REQUEST, createFraudMark),
    takeLatest(PixConstants.LIST_FRAUD_MARK_REQUEST, listFraudMark),
  ]);
}
