import { keyMirror } from 'core/modules/helpers';

export const cardConstants = keyMirror({
  CARDS_CANCEL_CARD_REQUEST: undefined,
  CARDS_CANCEL_CARD_SUCCESS: undefined,
  CARDS_CANCEL_CARD_FAILURE: undefined,
  CARDS_GET_ALL_REQUEST: undefined,
  CARDS_GET_ALL_SUCCESS: undefined,
  CARDS_GET_ALL_FAILURE: undefined,
  CARDS_ISSUE_CARD_REQUEST: undefined,
  CARDS_ISSUE_CARD_SUCCESS: undefined,
  CARDS_ISSUE_CARD_FAILURE: undefined,
  CARDS_TEMPORARY_BLOCK_REQUEST: undefined,
  CARDS_TEMPORARY_BLOCK_SUCCESS: undefined,
  CARDS_TEMPORARY_BLOCK_FAILURE: undefined,
  CARDS_UPDATE_CONTACTLESS_REQUEST: undefined,
  CARDS_UPDATE_CONTACTLESS_SUCCESS: undefined,
  CARDS_UPDATE_CONTACTLESS_FAILURE: undefined,
});
