import { keyMirror } from 'core/modules/helpers';

export const FinanceConstants = keyMirror({
  FINANCE_IRS_DATES_REQUEST: undefined,
  FINANCE_IRS_DATES_SUCCESS: undefined,
  FINANCE_IRS_DATES_FAILURE: undefined,
  FINANCE_IRS_DOWNLOAD_REPORT_REQUEST: undefined,
  FINANCE_IRS_DOWNLOAD_REPORT_SUCCESS: undefined,
  FINANCE_IRS_DOWNLOAD_REPORT_FAILURE: undefined,
});
