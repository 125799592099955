import React from 'react';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';
import { Sidebar } from '@sumup/circuit-ui';

import { history } from 'core/modules/history';

import { sidebarMessages } from 'core/modules/messages';
import { checkPermission } from 'core/modules/allowedPermissions';
import { SidebarStyled } from './Sidebar.styles';

const { SidebarIcon } = SidebarStyled;

const SidebarMenu = ({ expanded, handleCollapse, options }) => {
  const intl = useIntl();
  const currentLocation = history.location.pathname;

  const handleSidebar = (route) => {
    if (route && expanded) {
      window.location.href = route;
      return;
    }

    handleCollapse();
  };

  const shouldShowMenuItem = (option) => checkPermission(option.permission);

  const filterDisabledOptions = (opts) =>
    opts.filter((option) => {
      const showMenuItem = shouldShowMenuItem(option);
      if (option.children && showMenuItem) {
        option.children = filterDisabledOptions(option.children);
      }
      return showMenuItem;
    });

  const RenderMenuItem = (opt) => {
    const { children, icon, key, route } = opt;
    if (children) {
      return (
        <Sidebar.Aggregator
          defaultIcon={<SidebarIcon>{icon}</SidebarIcon>}
          key={key}
          onClick={expanded ? route : handleCollapse}
          label={expanded ? intl.formatMessage(sidebarMessages[key]) : ''}
        >
          {children.map((child, i) => (
            <Sidebar.NavItem
              href={child.route}
              key={i}
              label={
                expanded ? intl.formatMessage(sidebarMessages[child.key]) : ''
              }
              selected={currentLocation === child.route}
            />
          ))}
        </Sidebar.Aggregator>
      );
    }

    return (
      <Sidebar.NavItem
        defaultIcon={<SidebarIcon>{icon}</SidebarIcon>}
        key={key}
        selected={currentLocation === route}
        label={expanded ? intl.formatMessage(sidebarMessages[key]) : ''}
        onClick={() => handleSidebar(route)}
      />
    );
  };
  return (
    <>{filterDisabledOptions(options).map((opt) => RenderMenuItem(opt))}</>
  );
};

SidebarMenu.propTypes = {
  expanded: PropTypes.bool.isRequired,
  handleCollapse: PropTypes.func.isRequired,
  options: PropTypes.array.isRequired,
};

export default SidebarMenu;
