import { getEnv, getCountryUrlEnv } from 'core/modules/helpers';
import packageJson from '../../package.json';

const config = {
  apiBFB: `https://api.${getEnv()}.sumup-bank.info/bfb-graphql`,
  apiCountries: `https://${getCountryUrlEnv()}/v0.1/countries`,
  ted: {
    startTime: '06:30:00',
    endTime: '17:00:00',
  },
  dashboard: '/dashboard',
  datadog: {
    clientToken: 'pubfeb18f40fee3f26ed3711d7cb2a36977',
    datacenter: 'eu',
    forwardErrorsToLogs: true,
    sampleRate: 100,
  },
  enableAnimatedRoute: false,
  googleAuth2: {
    clientId: '541199800526-ugps1ahctabchsg5947h21r3sf6000hi.apps.googleusercontent.com',
    service: 'https://apis.google.com/js/platform.js',
  },
  googleSignIn: {
    clientId: '541199800526-ugps1ahctabchsg5947h21r3sf6000hi.apps.googleusercontent.com',
    service: 'https://accounts.google.com/gsi/client',
  },
  optimizely: {
    dev: 'Ji3XPaTDC65GVzAZUSNxX',
    stage: '6K9xCFBVYkLi2Koj7PtFq',
    prod: 'HbvRcSwbtCiY7CoDwQmAB',
  },
  siteName: 'SumUp Bank',
  sentry: {
    debug: false,
    dsn: 'https://38dd2dda089c4b4ab983f342a8348143@sentry.io/3601395',
    environment: `${getEnv()}`,
    release: packageJson.version,
    whitelistUrls: [/bankoffice.prod.sumup-bank\.info/],
    ignoreErrors: [
      "Cannot read property 'getHostNode' of null",
      'SecurityError',
      'Unexpected identifier',
      'Failed to update a ServiceWorker',
      'Failed to fetch',
      'Non-Error exception captured with keys: details, error',
      'idpiframe_initialization_failed',
      'Unexpected token '<'',
      /Blocked a frame with origin.*/,
      /.*An unknown error occurred when fetching the script.*/,
      /(cancelado|cancelled|timeout|conexão.*inativa|hostname could not be found|connection.*offline|Esgotou-se o tempo limite da solicitação.*)/,
    ],
  },
};

export default config;
