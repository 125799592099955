import { keyMirror } from 'core/modules/helpers';

export const AccountConstants = keyMirror({
  ACCOUNT_EARNING_REPORT_REQUEST: undefined,
  ACCOUNT_EARNING_REPORT_SUCCESS: undefined,
  ACCOUNT_EARNING_REPORT_FAILURE: undefined,
  ACCOUNT_EARNING_REPORT_AVAILABLE_YEARS_REQUEST: undefined,
  ACCOUNT_EARNING_REPORT_AVAILABLE_YEARS_SUCCESS: undefined,
  ACCOUNT_EARNING_REPORT_AVAILABLE_YEARS_FAILURE: undefined,
  ACCOUNT_HOLDER_CLOSE_LEDGER_ACCOUNT_REQUEST: undefined,
  ACCOUNT_HOLDER_CLOSE_LEDGER_ACCOUNT_SUCCESS: undefined,
  ACCOUNT_HOLDER_CLOSE_LEDGER_ACCOUNT_FAILURE: undefined,
  ACCOUNT_HOLDER_REOPEN_LEDGER_ACCOUNT_REQUEST: undefined,
  ACCOUNT_HOLDER_REOPEN_LEDGER_ACCOUNT_SUCCESS: undefined,
  ACCOUNT_HOLDER_REOPEN_LEDGER_ACCOUNT_FAILURE: undefined,
  ACCOUNT_HOLDER_MAKE_ACCOUNT_SUSPENDED_REQUEST: undefined,
  ACCOUNT_HOLDER_MAKE_ACCOUNT_SUSPENDED_SUCCESS: undefined,
  ACCOUNT_HOLDER_MAKE_ACCOUNT_SUSPENDED_FAILURE: undefined,
  ACCOUNT_HOLDER_FETCH_REQUEST: undefined,
  ACCOUNT_HOLDER_FETCH_SUCCESS: undefined,
  ACCOUNT_HOLDER_FETCH_FAILURE: undefined,
  ACCOUNT_HOLDER_GET_RECEIPT_REQUEST: undefined,
  ACCOUNT_HOLDER_GET_RECEIPT_SUCCESS: undefined,
  ACCOUNT_HOLDER_GET_RECEIPT_FAILURE: undefined,
  ACCOUNT_HOLDER_PJ_FETCH_REQUEST: undefined,
  ACCOUNT_HOLDER_PJ_FETCH_SUCCESS: undefined,
  ACCOUNT_HOLDER_PJ_FETCH_FAILURE: undefined,
  ACCOUNT_HOLDER_CREATE_STATEMENT_REQUEST: undefined,
  ACCOUNT_HOLDER_CREATE_STATEMENT_SUCCESS: undefined,
  ACCOUNT_HOLDER_CREATE_STATEMENT_FAILURE: undefined,
  ACCOUNT_HOLDER_UPDATE_ONBOARDING_DOC_REQ_REQUEST: undefined,
  ACCOUNT_HOLDER_UPDATE_ONBOARDING_DOC_REQ_SUCCESS: undefined,
  ACCOUNT_HOLDER_UPDATE_ONBOARDING_DOC_REQ_FAILURE: undefined,
  ACCOUNT_HOLDER_REMOVE_ACCOUNT_SUSPENSION_REQUEST: undefined,
  ACCOUNT_HOLDER_REMOVE_ACCOUNT_SUSPENSION_SUCCESS: undefined,
  ACCOUNT_HOLDER_REMOVE_ACCOUNT_SUSPENSION_FAILURE: undefined,
  ACCOUNT_HOLDER_SET_DATE_STATEMENT: undefined,
  ACCOUNT_HOLDER_UPDATE_DATA_REQUEST: undefined,
  ACCOUNT_HOLDER_UPDATE_DATA_SUCCESS: undefined,
  ACCOUNT_HOLDER_UPDATE_DATA_FAILURE: undefined,
  ACCOUNT_HOLDER_MAKE_ACCOUNT_UNLIMITED_REQUEST: undefined,
  ACCOUNT_HOLDER_MAKE_ACCOUNT_UNLIMITED_SUCCESS: undefined,
  ACCOUNT_HOLDER_MAKE_ACCOUNT_UNLIMITED_FAILURE: undefined,
  ACCOUNT_JUDICIAL_BLOCK_REQUEST: undefined,
  ACCOUNT_JUDICIAL_BLOCK_SUCCESS: undefined,
  ACCOUNT_JUDICIAL_BLOCK_FAILURE: undefined,
  ACCOUNT_OCR_REQUEST: undefined,
  ACCOUNT_OCR_SUCCESS: undefined,
  ACCOUNT_OCR_FAILURE: undefined,
  ACCOUNT_OPERATION_MANUAL_TED_REQUEST: undefined,
  ACCOUNT_OPERATION_MANUAL_TED_SUCCESS: undefined,
  ACCOUNT_OPERATION_MANUAL_TED_FAILURE: undefined,
  ACCOUNT_SHIPMENT_ORDERS_REQUEST: undefined,
  ACCOUNT_SHIPMENT_ORDERS_SUCCESS: undefined,
  ACCOUNT_SHIPMENT_ORDERS_FAILURE: undefined,
  ACCOUNT_TRANSACTIONS_FETCH_REQUEST: undefined,
  ACCOUNT_TRANSACTIONS_SUCCESS: undefined,
  ACCOUNT_TRANSACTIONS_FAILURE: undefined,
  ACCOUNT_USER_LOGS_REQUEST: undefined,
  ACCOUNT_USER_LOGS_SUCCESS: undefined,
  ACCOUNT_USER_LOGS_FAILURE: undefined,
  KYC_BACKGROUND_CHECK_REQUEST: undefined,
  KYC_BACKGROUND_CHECK_SUCCESS: undefined,
  KYC_BACKGROUND_CHECK_FAILURE: undefined,
  KYC_COMPANY_PROCESS_REQUEST: undefined,
  KYC_COMPANY_PROCESS_SUCCESS: undefined,
  KYC_COMPANY_PROCESS_FAILURE: undefined,
  KYC_FACEMATCH_REQUEST: undefined,
  KYC_FACEMATCH_SUCCESS: undefined,
  KYC_FACEMATCH_FAILURE: undefined,
  KYC_FACEMATCH_FILE_REQUEST: undefined,
  KYC_FACEMATCH_FILE_SUCCESS: undefined,
  KYC_FACEMATCH_FILE_FAILURE: undefined,
  KYC_MAKE_ACCOUNT_KYC_REQUIRED_REQUEST: undefined,
  KYC_MAKE_ACCOUNT_KYC_REQUIRED_SUCCESS: undefined,
  KYC_MAKE_ACCOUNT_KYC_REQUIRED_FAILURE: undefined,
  KYC_PEP_AND_SANCTIONS_REQUEST: undefined,
  KYC_PEP_AND_SANCTIONS_SUCCESS: undefined,
  KYC_PEP_AND_SANCTIONS_FAILURE: undefined,
});
