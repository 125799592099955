import styled from '@emotion/styled';
import { css } from '@emotion/react';

export const COPY_TO_CLIPBOARD = (theme) => ({
  copy: css`
    background-color: transparent;
    border: none;
    color: ${theme.colors.p500};

    svg {
      height: 18px;
    }
  `,
});

export const IconsStyled = {
  Icon: styled('div')(({ theme, property }) => css`
    align-items: center;
    background-color: transparent;
    color: black;
    display: flex;
    height: 22px;
    justify-content: center;
    width: 22px;

    ${COPY_TO_CLIPBOARD(theme)[property]}
  `),
  FlagNotFound: styled('div')(({ theme }) => css`
    height: 43px;
    margin-right: ${theme.spacings.kilo};
    width: 45px;
  `),
  Flag: styled('div')(({ theme }) => css`
    border-radius: ${theme.borderRadius.kilo};
    display: flex;
    height: 43px;
    justify-content: center;
    padding: ${theme.spacings.bit};
    transition: all cubic-bezier(0.4, 0.0, 0.2, 1) 0.3s;
    width: 45px;

    svg {
      height: 100%;
    }
  `),
};
