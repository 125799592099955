import styled from '@emotion/styled';
import { css } from '@emotion/react';
import { RiArrowDropRightLine } from 'react-icons/ri';

export const SidebarStyled = {
  SidebarWrapper: styled('aside')(({ theme, expanded }) => css`
    background-color: ${theme.colors.n200};
    flex: 0 0 auto;
    min-width: ${expanded ? '285px' : '70px'};
    position: relative;
  `),
  SidebarNav: styled('nav')(({ theme, expanded }) => css`
    align-items: center;
    background-color: #212933;
    display: flex;
    flex-direction: column;
    height: 100%;
    position: fixed;
    transition: all cubic-bezier(0.4, 0.0, 0.2, 1) 0.3s;
    width: ${expanded ? '285px' : '72px'};

    button {
      align-items: center;
    }

    a, button {      
      &:hover {
        background-color: #0F131A;
        border-radius: ${theme.borderRadius.kilo};
        color: #FFF;
        transition: all cubic-bezier(0.4, 0.0, 0.2, 1) 0.3s;
      }
    }

    ul {
      align-items: center;
      display: ${expanded ? 'normal' : 'flex'};
      flex-direction: column;
      font-size: 15px;
      line-height: 24px;
      overflow-y: -moz-hidden-unscrollable;
      width: 100%;
    }

    li {
      height: ${expanded ? 'normal' : '58px'};
      width:100%;

      ul > li > a:hover {
        background: none;
        font-weight: bold;
      }
    }
  `),
  SidebarToggle: styled(RiArrowDropRightLine)(({ expanded, theme }) => css`
    background-color: ${theme.colors.b500};
    border-radius: ${theme.borderRadius.circle};
    box-shadow: rgba(12, 15, 20, 0.02) 0 0 0 1px,
                rgba(12, 15, 20, 0.06) 0 0 1px 0,
                rgba(12, 15, 20, 0.06) 0 2px  2px 0;
    border: 0;
    color:  ${theme.colors.n100};
    cursor: pointer;
    font-size: 18px;
    font-weight: bold;
    height: ${theme.spacings.giga};
    line-height: 22px;
    outline: none;
    position: absolute;
    right: ${expanded ? '-9px' : '-13px'};
    text-align: center;
    top: 64px;
    transition: all 300ms ease-in-out;
    transform: rotate(${expanded ? '180deg' : '0deg'});
    width: 22px;
  `),
  SidebarIcon: styled('div')(({ theme }) => css`
    align-items: center;
    display: flex;
    justify-content: center;
    padding: ${theme.spacings.bit};;
    transition: all cubic-bezier(0.4, 0.0, 0.2, 1) 0.3s;

    svg {
      height: ${theme.spacings.giga};
      width:  ${theme.spacings.giga};
      path { fill: ${theme.colors.n100} };
    }
  `),

  SidebarFooter: styled('div')(({ theme, expanded }) => css`
    align-content: center;
    align-items: center;
    background-color: #0F131A;
    bottom: 0;
    display: flex;
    flex-direction: row;
    height: 64px;
    justify-content: center;
    padding: ${theme.spacings.mega};
    position: absolute;
    width:100%;

    a {
      display: block;
      height: auto;
      margin: 0;
    }

    span {
      font-size: 12px;
      color: ${theme.colors.r300};
      padding-left: ${theme.spacings.byte};
      display: ${expanded ? 'block' : 'none'};
    }
  `),
};
