import React from 'react';
import { ComponentsContext } from '@sumup/circuit-ui';

import AnchorLink from './AnchorLink';

const components = {
  Link: AnchorLink,
};

const ComponentsProvider = (props) => (
  <ComponentsContext.Provider value={components} {...props} />
);

export default ComponentsProvider;
