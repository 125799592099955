import { all, call, put, takeLatest } from 'redux-saga/effects';

import { AuthConstants } from 'core/constants';
import { client, getRequestOptions } from 'core/modules/resources';
import { getGoogleAuthRefreshToken } from 'core/modules/auth';
import { parseResponseError } from 'core/modules/client';

import { authorizeWithGoogle, formatGoogleAuthToken } from './side-effects';

import { queryGetViewer } from '../graphql/queries';

export function* getViewer() {
  try {
    const { data, errors } = yield call(client, {
      ...yield getRequestOptions(),
      ...yield queryGetViewer(),
      method: 'POST',
      operationName: 'getViewer',
    });

    if (!data?.viewer) {
      throw new Error(errors[0].message);
    }

    yield put({
      type: AuthConstants.AUTH_CREDENTIALS_SUCCESS,
      payload: data?.viewer,
    });
  }
  catch ({ message, status }) {
    const error = parseResponseError(message, status);

    yield put({
      type: AuthConstants.AUTH_CREDENTIALS_FAILURE,
      payload: { message: error, status },
    });

    yield put({
      type: AuthConstants.AUTH_LOGOUT,
    });
  }
}

export function* authLogin() {
  try {
    const { isSignedIn, ...userProfile } = yield authorizeWithGoogle();
    const { accessToken, expiresAt} = userProfile;

    if (isSignedIn) {
      yield put({
        type: AuthConstants.AUTH_TOKEN_SUCCESS,
        payload: {
          accessToken,
          expiresAt,
          legacy: true,
        },
      });

      yield put({
        type: AuthConstants.AUTH_LOGIN_SUCCESS,
        payload: userProfile,
      });

      yield put({
        type: AuthConstants.AUTH_CREDENTIALS_REQUEST,
      });
    }
  }
  catch (error) {
    yield put({
      type: AuthConstants.AUTH_LOGIN_FAILURE,
      payload: { message: error },
    });
  }
}

export function* tokenRefresh() {
  try {
    const { refreshToken, expiresAt } = yield getGoogleAuthRefreshToken();

    yield put({
      type: AuthConstants.AUTH_REFRESH_TOKEN_SUCCESS,
      payload: {
        expiresAt,
        refreshToken,
      },
    });
  }
  catch (error) {
    yield put({
      type: AuthConstants.AUTH_REFRESH_TOKEN_FAILURE,
      payload: { message: error },
    });

    yield put({
      type: AuthConstants.AUTH_LOGOUT,
    });
  }
}

export function* setToken({ payload: { accessToken, isLoginFlow } }) {
  try {
    yield put({
      type: AuthConstants.AUTH_TOKEN_SUCCESS,
      payload: formatGoogleAuthToken(accessToken),
    });

    if(isLoginFlow) {
      yield put({
        type: AuthConstants.AUTH_CREDENTIALS_REQUEST,
      });
    }
  }
  catch (error) {
    yield put({
      type: AuthConstants.AUTH_TOKEN_FAILURE,
      payload: { message: error },
    });
  }
}

export default function* root() {
  yield all([
    takeLatest(AuthConstants.AUTH_CREDENTIALS_REQUEST, getViewer),
    takeLatest(AuthConstants.AUTH_LOGIN_REQUEST, authLogin),
    takeLatest(AuthConstants.AUTH_REFRESH_TOKEN_REQUEST, tokenRefresh),
    takeLatest(AuthConstants.AUTH_TOKEN_REQUEST, setToken),
  ]);
}
