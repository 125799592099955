/**
 * Parser functions.
 * @module Parsers
 */

export function parseIrsReport(data) {
  const groupsByYears = data.reduce((groupsByYear, date) => {
    const [year, month] = date.split('-');

    groupsByYear[year] = groupsByYear[year] || [];
    groupsByYear[year].push(month);

    return groupsByYear;
  }, {});

  const groupArrays = Object.keys(groupsByYears).map((year) => ({
    year,
    month: groupsByYears[year],
  }));

  return groupArrays;
};

export function parserAccountHolderData(accountData, personType, nationalId) {
  const dataForm = {
    birthDate: accountData?.birthDate,
    companyName: accountData?.companyName,
    email: accountData?.email,
    fantasyName: accountData?.fantasyName,
    fullName: accountData?.fullName,
    nationalId,
    number: accountData?.number,
    personType,
    phoneNumber: accountData?.phoneNumber,
    sumupMerchantCode: accountData?.sumupMerchantCode,
    legalType: accountData?.legalType,
    address: {
      city: accountData?.city,
      complement: accountData?.complement,
      district: accountData?.district,
      number: accountData?.addressNumber,
      state: accountData?.state,
      street: accountData?.street,
      zipcode: accountData?.zipcode,
    },
    representative: {
      birthDate: accountData.representativeBirthDate,
      email: accountData.representativeEmail,
      fullName: accountData.representativeFullName,
    },
  };

  return dataForm;
};

export function parserKycFiles(data) {
  const isSelfie = data.filename === 'SELFIE';
  const newData = {
    liveness: {
      url: isSelfie ? data.fileContent : '',
      alt: isSelfie ? data.filename : '',
    },
    facematch: {
      url: !isSelfie ? data.fileContent : '',
      alt: !isSelfie ? data.filename : '',
    },
  };

  return newData;
}
