import React from 'react';
import { Upload, Profile, Settings, Account, CashStack } from '@sumup/icons';
import { ALLOWED_PERMISSIONS } from 'core/constants';
import Icons from 'core/components/Icons';

const options = [
  {
    permission: ALLOWED_PERMISSIONS.ACCOUNT_HOLDER,
    icon: <Profile />,
    key: 'account_holder',
    route: '/dashboard/account',
  },
  {
    permission: ALLOWED_PERMISSIONS.IRS_AVAILABLE_DATES,
    icon: <Account />,
    key: 'finance',
    route: '/dashboard/finance',
  },
  {
    permission: ALLOWED_PERMISSIONS.PIX_INFRACTIONS,
    icon: <Icons.PixSidebarIcon />,
    key: 'pix',
    expanded: true,
    children: [
      {
        permission: ALLOWED_PERMISSIONS.PIX_INFRACTIONS,
        icon: <CashStack />,
        key: 'infractions',
        route: '/dashboard/pix/infractions',
      },
      {
        permission: ALLOWED_PERMISSIONS.PIX_INFRACTIONS,
        icon: <CashStack />,
        key: 'create_infraction',
        route: '/dashboard/pix/infractions/new',
      },
      {
        permission: ALLOWED_PERMISSIONS.PIX_INFRACTIONS,
        icon: <CashStack />,
        key: 'refunds',
        route: '/dashboard/pix/refunds/open',
      },
    ],
  },
  {
    permission: ALLOWED_PERMISSIONS.PIX_CHECK_TRANSACTION,
    icon: <CashStack />,
    key: 'check_transaction',
    route: '/dashboard/pix/check-transaction',
  },
  {
    permission: ALLOWED_PERMISSIONS.USER_MANAGEMENT_USERS,
    icon: <Settings />,
    key: 'user_management',
    route: '/dashboard/user-management',
  },
  {
    permission: [ALLOWED_PERMISSIONS.VERIFICATION_MANAGEMENT_UPLOAD_INTENT_FILE, ALLOWED_PERMISSIONS.ACCOUNT_CREATION, ALLOWED_PERMISSIONS.PAYOUT_CHANGE],
    icon: <Upload />,
    key: 'account_management',
    expanded: true,
    children: [
      {
        permission: ALLOWED_PERMISSIONS.ACCOUNT_CREATION,
        icon: <Upload />,
        key: 'account_creation',
        route: '/dashboard/account-creation',
      },
      {
        permission: ALLOWED_PERMISSIONS.PAYOUT_CHANGE,
        icon: <Upload />,
        key: 'payout_change',
        route: '/dashboard/payout-change',
      },
      {
        permission: ALLOWED_PERMISSIONS.VERIFICATION_MANAGEMENT_UPLOAD_INTENT_FILE,
        icon: <Upload />,
        key: 'verification_management',
        route: '/dashboard/verification-management',
      },
    ],
  },
];

export default options;
