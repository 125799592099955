import React from 'react';
import PropTypes from 'prop-types';

import { ReactComponent as Brand } from 'assets/brand/bankoffice-logo.svg';
import { ReactComponent as BrandInverse } from 'assets/brand/bankoffice-logo-inverse.svg';
import { ReactComponent as Logotype } from 'assets/brand/logotype.svg';

import { LogoStyled } from './Logo.styles';

const { Link, LogoWrapper } = LogoStyled;

const Logo = ({ expanded, inTopbar }) => (
  inTopbar ? (
    <Link to="/dashboard" data-testid="logo">
      {expanded ? <BrandInverse /> : <Logotype />}
    </Link>
  ) : (
    <LogoWrapper data-testid="logo">
      <Brand />
    </LogoWrapper>
  )
);

Logo.propTypes = {
  expanded: PropTypes.bool,
  inTopbar: PropTypes.bool,
};

Logo.defaultProps = {
  inTopbar: false,
};

export default Logo;
