import { call } from 'redux-saga/effects';

export function* authorizeWithGoogle() {
  try {
    const googleAuth = window.gapi.auth2.getAuthInstance();

    const googleUser = yield call(() =>
      new Promise((resolve, reject) =>
        googleAuth
          .signIn()
          .then(resolve, reject)
      ));

    const { expires_at } = googleUser.getAuthResponse();

    return {
      accessToken: googleUser.getAuthResponse(true).access_token,
      expiresAt: expires_at,
      isSignedIn: googleUser.isSignedIn(),
    };
  }
  catch(err) {
    throw new Error(err);
  }
}

export function formatGoogleAuthToken(token) {
  return {
    accessToken: token.access_token,
    expiresAt: token.expires_in,
  };
}

