export const mutationGetReceipt = (payload) => ({
  payload: {
    query: `
      mutation Transactions($getManualReceiptInput: getManualReceiptInput!) {
        getManualReceipt(input: $getManualReceiptInput) {
          successful
          messages{
            message
            code
          }
          result{
            fileContent
            filename
          }
        }
      }`,
    variables: {
      getManualReceiptInput: {
        nationalId: payload.nationalId,
        transactionId: payload.transactionId,
        transactionType: payload.transactionType,
      },
    },
  },
});

export const mutationRefundTransaction = ({ merchantCode, transferId }) => ({
  payload: {
    query: `
        mutation RefundTransaction($merchantCode: String!, $transferId: String!){
          refundTransaction(input: {
              merchantCode: $merchantCode,
              transferId: $transferId
            }) {
            successful
            messages{
              message
              code
            }
            result{
              status
              transferId
          }
        }
      }
    `,
    variables: {
      merchantCode,
      transferId,
    },
  },
});

