import { handleActions } from 'redux-actions';
import produce from 'immer';

import { STATUS, AuthConstants } from 'core/constants';

export const authState = {
  token: {
    expiresAt: '',
    legacy: false,
    message: '',
    code: '',
    status: STATUS.IDLE,
  },
  credentials: {
    allowedPermissions: [],
    message: '',
    status: STATUS.IDLE,
  },
  login: {
    message: '',
    status: STATUS.IDLE,
  },
  isLogged: false,
  user: {
    email: '',
    name: '',
    thumb: '',
    role: '',
  },
  refreshToken: {
    message: '',
    status: STATUS.IDLE,
  },
};

export default {
  auth: handleActions({
    [AuthConstants.AUTH_TOKEN_FAILURE]:
      (state, { payload: { message } }) => produce(state, draft => {
        draft.token.code = '';
        draft.token.expiresAt = '';
        draft.token.status = STATUS.ERROR;
        draft.token.message = message;
      }),
    [AuthConstants.AUTH_TOKEN_SUCCESS]: (state, { payload }) => produce(state, draft => {
      draft.token.code = payload.accessToken;
      draft.token.expiresAt = payload.expiresAt;
      draft.token.status = STATUS.SUCCESS;
      draft.token.legacy = payload.legacy;
    }),
    [AuthConstants.AUTH_CREDENTIALS_REQUEST]: state => produce(state, draft => {
      draft.credentials = {
        status: STATUS.RUNNING,
      };
    }),
    [AuthConstants.AUTH_CREDENTIALS_SUCCESS]:
      (state, { payload }) => produce(state, draft => {
        draft.credentials = {
          allowedPermissions: payload.allowedActions,
          status: STATUS.SUCCESS,
        };
        draft.user = {
          ...draft.user,
          name: payload.fullName,
          email: payload.email,
          role: payload.profile,
        };
        draft.isLogged = true;
      }),
    [AuthConstants.AUTH_CREDENTIALS_FAILURE]: state => produce(state, draft => {
      draft.credentials = {
        status: STATUS.ERROR,
      };
      draft.isLogged = false;
    }),
    [AuthConstants.AUTH_LOGIN_REQUEST]: state => produce(state, draft => {
      draft.login = {
        status: STATUS.RUNNING,
      };
    }),
    [AuthConstants.AUTH_LOGIN_SUCCESS]: (state, { payload }) => produce(state, draft => {
      draft.login = {
        status: STATUS.SUCCESS,
      };
      draft.user = {
        name: payload.name,
        email: payload.email,
        thumb: payload.imageUrl,
      };
    }),
    [AuthConstants.AUTH_LOGIN_FAILURE]:
      (state, { payload: { message } }) => produce(state, draft => {
      draft.login = {
        message,
        status: STATUS.ERROR,
      };
      draft.isLogged = false;
    }),
    [AuthConstants.AUTH_LOGOUT]: state => produce(state, draft => {
      draft.token.token = '';
      draft.token.expiresAt = '';
      draft.token.legacy = false;
      draft.token.status = STATUS.IDLE;
      draft.login = {
        status: STATUS.IDLE,
      };
      draft.user = {
        email: '',
        name: '',
        thumb: '',
        role: '',
      };
      draft.isLogged = false;
    }),
    [AuthConstants.AUTH_REFRESH_TOKEN_REQUEST]: state => produce(state, draft => {
      draft.refreshToken = {
        status: STATUS.RUNNING,
      };
    }),
    [AuthConstants.AUTH_REFRESH_TOKEN_SUCCESS]:
      (state, { payload }) => produce(state, draft => {
        draft.token.code = payload.refreshToken;
        draft.token.expiresAt = payload.expiresAt;
        draft.refreshToken = {
          status: STATUS.SUCCESS,
        };
      }),
    [AuthConstants.AUTH_REFRESH_TOKEN_FAILURE]:
      (state, { payload: { message } }) => produce(state, draft => {
        draft.isLogged = false;
        draft.refreshToken = {
          message,
          status: STATUS.ERROR,
        };
      }),
  }, authState),
};
