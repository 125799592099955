import styled from '@emotion/styled';

import { Link } from 'react-router-dom';

export const LogoStyled = {
  LogoWrapper: styled('div')`
    display: inline-block;
    height: 50px;
    
    svg {
      height: 100%;
    }
  `,
  Link: styled(Link)`
    display: inline-block;
    height: 32px;
    margin-bottom: 40px;
    
    svg {
      height: 100%;
    }
  `,
};
