import React from 'react';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';

import Icons from 'core/components/Icons';

import { Button, ButtonIcon, ButtonText } from './GoogleAuthButton.styles';

const GoogleAuthButton = ({ onClick }) => {
  const intl = useIntl();

  return (
    <Button
      data-testid="google-auth-button"
      onClick={() => onClick()}
    >
      <ButtonIcon>
        <Icons.Google />
      </ButtonIcon>
      <ButtonText>
        {intl.formatMessage({
          id: 'login.cta',
          defaultMessage: 'Sign in with your Google account',
        })}
      </ButtonText>
    </Button>
  );
};

GoogleAuthButton.propTypes = {
  onClick: PropTypes.func,
};

export default GoogleAuthButton;
