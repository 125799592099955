import { keyMirror } from 'core/modules/helpers';

export const AccountCreationConstants = keyMirror({
  ACCOUNT_CREATION_UPLOAD_FILE_REQUEST: undefined,
  ACCOUNT_CREATION_UPLOAD_FILE_SUCCESS: undefined,
  ACCOUNT_CREATION_UPLOAD_FILE_FAILURE: undefined,

  ACCOUNT_CREATION_MANUAL_INPUT_REQUEST: undefined,
  ACCOUNT_CREATION_MANUAL_INPUT_SUCCESS: undefined,
  ACCOUNT_CREATION_MANUAL_INPUT_FAILURE: undefined,
});
