import React from 'react';

function CashInIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      fill="none"
      viewBox="0 0 24 24"
    >
      <path stroke="#080808" strokeWidth="2" d="M4 11a9 9 0 119 9" />
      <path
        stroke="#000"
        strokeWidth="2"
        d="M11 9c0 1 .8 1.68 2 2 1.2.32 2 1 2 2M15 9a2 2 0 10-4 0M11 13a2 2 0 104 0"
      />
      <path fill="#080808" d="M12 5H14V7H12z" />
      <path fill="#080808" d="M12 15H14V17H12z" />
      <path
        stroke="#000"
        strokeLinejoin="round"
        strokeWidth="2"
        d="M2 19l4 4 4-4"
      />
      <path stroke="#000" strokeWidth="2" d="M6 14v9" />
    </svg>
  );
}

export default CashInIcon;
