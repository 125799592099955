export const queryGetUserList = () => ({
  payload: {
    query: `
      query User {
        users{
          email
          fullName
          profile
        }
      }`,
  },
});

export const queryGetRoles = () => ({
  payload: {
    query: `
      query Profile {
        profiles {
          name
        }
      }`,
  },
});
