/**
 * Get current Screen Width and returns the circuit name convention
 * @returns {string}
 */
export const getScreenSize = () => {
  const screenWidth = window.innerWidth;

  let screenSize = 'untilKilo';

  if (screenWidth >= 1440) {
    screenSize = 'tera';
  }
  else if (screenWidth >= 1280 && screenWidth <= 1439) {
    screenSize = 'tera';
  }
  else if (screenWidth >= 1024 && screenWidth <= 1279) {
    screenSize = 'gigaToTera';
  }
  else if (screenWidth >= 960 && screenWidth <= 1023) {
    screenSize = 'giga';
  }
  else if (screenWidth >= 768 && screenWidth <= 959) {
    screenSize = 'megaToGiga';
  }
  else if (screenWidth === 768) {
    screenSize = 'mega';
  }
  else if (screenWidth > 480 && screenWidth <= 767) {
    screenSize = 'kiloToMega';
  }
  else if (screenWidth === 480) {
    screenSize = 'kilo';
  }
  else if (screenWidth <= 479) {
    screenSize = 'untilKilo';
  }

  return screenSize;
};
