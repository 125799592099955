import { all, call, put, takeLatest } from 'redux-saga/effects';

import { client, getRequestOptions } from 'core/modules/resources';
import { parseResponseError } from 'core/modules/client';

import { queryGetCards } from '../graphql/queries';
import { cardConstants } from '../redux/constants';
import {
  mutationCancelCard,
  mutationIssueCard,
  mutationTemporaryBlockCard,
  mutationUpdateCardContactless,
} from '../graphql/mutations';

export function* cancelCard({ payload }) {
  try {
    const { data } = yield call(client, {
      ...yield getRequestOptions(),
      ...yield mutationCancelCard(payload.shareableId, payload.reason),
      method: 'POST',
      operationName: 'cancelCard',
    });

    const successful = data?.updateCardState?.successful;

    if (!successful) {
      const [error] = data?.updateCardState?.messages;
      throw new Error(error.message);
    }

    yield put({
      type: cardConstants.CARDS_CANCEL_CARD_SUCCESS,
      payload: {
        shareableId: payload.shareableId,
      },
    });
  }
  catch (error) {
    yield put({
      type: cardConstants.CARDS_CANCEL_CARD_FAILURE,
      payload: {
        message: error.message,
        shareableId: payload.shareableId,
        reason: payload.reason,
      },
    });
  }
}

export function* getCards({ payload }) {
  try {
    const { data, errors } = yield call(client, {
      ...yield getRequestOptions(),
      ...yield queryGetCards(payload.id),
      method: 'POST',
      operationName: 'getCards',
    });

    if (!data?.cards) {
      throw new Error(errors[0].message);
    }

    yield put({
      type: cardConstants.CARDS_GET_ALL_SUCCESS,
      payload: {
        data: data?.cards,
      },
    });
  }
  catch ({ message, status }) {
    const error = parseResponseError(message, status);

    yield put({
      type: cardConstants.CARDS_GET_ALL_FAILURE,
      payload: { message: error },
    });
  }
}

export function* issueCard({ payload }) {
  try {
    const { data } = yield call(client, {
      ...yield getRequestOptions(),
      ...yield mutationIssueCard(payload.sumupMerchantCode, payload.customAddress),
      method: 'POST',
      operationName: 'issueCard',
    });

    const successful = data?.createShipment?.successful;

    if (!successful) {
      const [error] = data?.createShipment?.messages;
      throw new Error(error.message);
    }

    yield put({
      type: cardConstants.CARDS_ISSUE_CARD_SUCCESS,
    });
  }
  catch (error) {
    yield put({
      type: cardConstants.CARDS_ISSUE_CARD_FAILURE,
      payload: { message: error.message },
    });
  }
}

export function* temporaryBlockCard({ payload }) {
  try {
    const { shareableId, cardState } = payload;
    const { data } = yield call(client, {
      ...yield getRequestOptions(),
      ...yield mutationTemporaryBlockCard({
        shareableId,
        cardState,
      }),
      method: 'POST',
      operationName: 'temporaryBlock',
    });

    const successful = data?.updateCardState?.successful;

    if (!successful) {
      const [error] = data?.updateCardState?.messages;
      throw new Error(error.message);
    }

    yield put({
      type: cardConstants.CARDS_TEMPORARY_BLOCK_SUCCESS,
      payload: {
        shareableId: payload.shareableId,
      },
    });
  }
  catch (error) {
    yield put({
      type: cardConstants.CARDS_TEMPORARY_BLOCK_FAILURE,
      payload: {
        message: error.message,
        shareableId: payload.shareableId,
      },
    });
  }
}

export function* updateCardContactless({ payload }) {
  try {
    const { shareableId, contactlessEnabled } = payload;
    const { data } = yield call(client, {
      ...yield getRequestOptions(),
      ...yield mutationUpdateCardContactless({
        shareableId,
        contactlessEnabled,
      }),
      method: 'POST',
      operationName: 'contactlessEnabled',
    });

    const successful = data?.updateCardContactlessEnabled?.successful;

    if (!successful) {
      const [error] = data?.updateCardContactlessEnabled?.messages;
      throw new Error(error.message);
    }

    yield put({
      type: cardConstants.CARDS_UPDATE_CONTACTLESS_SUCCESS,
      payload: {
        shareableId: payload.shareableId,
      },
    });
  }
  catch (error) {
    yield put({
      type: cardConstants.CARDS_UPDATE_CONTACTLESS_FAILURE,
      payload: {
        message: error.message,
        shareableId: payload.shareableId,
      },
    });
  }
}

export default function* root() {
  yield all([
    takeLatest(cardConstants.CARDS_CANCEL_CARD_REQUEST, cancelCard),
    takeLatest(cardConstants.CARDS_GET_ALL_REQUEST, getCards),
    takeLatest(cardConstants.CARDS_ISSUE_CARD_REQUEST, issueCard),
    takeLatest(cardConstants.CARDS_TEMPORARY_BLOCK_REQUEST, temporaryBlockCard),
    takeLatest(cardConstants.CARDS_UPDATE_CONTACTLESS_REQUEST, updateCardContactless),
  ]);
};

