export const queryIrsAvailableDates = () => ({
  payload: {
    query: `
      query Irs {
        availableDates
      }`,
  },
});

export const queryIrsDownloadReports = (month, year) => ({
  payload: {
    query: `
      query Irs($month: String!, $year: String!) {
        downloadIrsReports(month: $month, year: $year) {
          fileContent
          filename
        }
      }`,
    variables: { month, year },
  },
});