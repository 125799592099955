import React from 'react';

function CardIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="14"
      fill="none"
      viewBox="0 0 20 14"
    >
      <path
        fill="#323E49"
        fillRule="evenodd"
        d="M3 0a3 3 0 00-3 3v8c0 1.66 1.353 3 3.006 3h13.99A3.004 3.004 0 0020 11V3a3 3 0 00-3-3H3zM2 3a1 1 0 011-1h14a1 1 0 011 1v1H2V3zm0 3v5c0 .548.45 1 1.006 1h13.99C17.55 12 18 11.55 18 11V6H2z"
        clipRule="evenodd"
      />
    </svg>
  );
}

export default CardIcon;
