import React from 'react';

function CashAtmIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      fill="none"
      viewBox="0 0 24 24"
    >
      <path
        stroke="#080808"
        strokeLinejoin="round"
        strokeWidth="2"
        d="M22 9V6a1 1 0 00-1-1H3a1 1 0 00-1 1v3"
      />
      <path
        stroke="#080808"
        strokeLinejoin="round"
        strokeWidth="2"
        d="M6 19h12V5H6v14z"
      />
      <path
        stroke="#080808"
        strokeWidth="2"
        d="M17 15a3 3 0 00-3 3M10 18a3 3 0 00-3-3"
      />
      <circle
        cx="12"
        cy="9"
        r="2"
        stroke="#080808"
        strokeWidth="2"
        transform="rotate(90 12 9)"
      />
    </svg>
  );
}

export default CashAtmIcon;
