import { useState } from 'react';

const useDrawer = (initialMode = false) => {
  const [drawerOpen, setDrawerOpen] = useState(initialMode);

  const toggle = () => setDrawerOpen(!drawerOpen);

  return [drawerOpen, setDrawerOpen, toggle];
};

export default useDrawer;
