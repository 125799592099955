import { handleActions } from 'redux-actions';
import produce from 'immer';

import { STATUS } from 'core/constants';
import { FinanceConstants } from '../constants';

export const financeState = {
  irs: {
    availableDates: {
      fetch: [],
      message: '',
      status: STATUS.IDLE,
    },
    downloadReport: {
      fileContent: '',
      filename: '',
      message: '',
      status: STATUS.IDLE,
    },
  },
};

export default {
  finance: handleActions({
    [FinanceConstants.FINANCE_IRS_DATES_REQUEST]: state => produce(state, draft => {
      draft.irs.availableDates.status = STATUS.RUNNING;
    }),
    [FinanceConstants.FINANCE_IRS_DATES_SUCCESS]: (state, { payload: { data } }) => produce(state, draft => {
      draft.irs.availableDates.fetch = data;
      draft.irs.availableDates.status = STATUS.SUCCESS;
    }),
    [FinanceConstants.FINANCE_IRS_DATES_FAILURE]: (state, { payload: { message } }) => produce(state, draft => {
      draft.irs.availableDates = {
        message,
        status: STATUS.ERROR,
      };
    }),
    [FinanceConstants.FINANCE_IRS_DOWNLOAD_REPORT_REQUEST]: (state) => produce(state, draft => {
      draft.irs.downloadReport.status = STATUS.RUNNING;
    }),
    [FinanceConstants.FINANCE_IRS_DOWNLOAD_REPORT_SUCCESS]: (state, { payload: { fileContent, filename }}) => produce(state, draft => {
      draft.irs.downloadReport.fileContent = fileContent;
      draft.irs.downloadReport.filename = filename;
      draft.irs.downloadReport.status = STATUS.SUCCESS;
    }),
    [FinanceConstants.FINANCE_IRS_DOWNLOAD_REPORT_FAILURE]: (state, { payload: { message } }) => produce(state, draft => {
      draft.irs.downloadReport.message = message;
      draft.irs.downloadReport.status = STATUS.ERROR;
    }),
  }, financeState),
};
