import styled from '@emotion/styled';
import { css } from '@emotion/react';

export const ProfileStyled = {
  ProfileWrapper: styled('div')(({ expanded }) => css`
    align-items: ${expanded ? 'flex-start' : 'center'};
    background-color: #0F131A;
    display: ${expanded ? 'block' : 'flex'};
    height: 80px;
    width: ${expanded ? '285px' : '70px'};
  `),
  UserEmail: styled('p')(({ theme }) => css`
    color: #9DA7B1;
    transition: ${theme.transitions.default};
    font-size:12px;
`),
  UserGroup: styled('div')(({ theme }) => css`
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: ${theme.spacings.mega};
  `),
  UserDetails: styled('div')(({ theme }) => css`
    display: block;
    flex-direction: column;
    padding-left: ${theme.spacings.mega};
`),
  UserName: styled('p')(({ theme }) => css`
    color: #FFF;
    transition: ${theme.transitions.default};
    font-size:16px;
  `),
  UserThumb: styled('div')(({ theme, image }) => css`
    background-color: ${theme.colors.n700};
    background-image: url(${image});
    background-size: cover;
    border-radius:${theme.iconSizes.giga};
    height: ${theme.spacings.peta};
    width: ${theme.spacings.peta};
  `),
  UserNoThumb: styled('div')(({ theme }) => css`
    align-items: center;
    background-color: ${theme.colors.p500};
    border-radius:${theme.iconSizes.giga};
    color: white;
    display: flex;
    height: ${theme.spacings.peta};
    justify-content: center;
    width: ${theme.spacings.peta};
  `),
};
