export const queryGetCards = (accountId) => ({
  payload: {
    query: `
      query($accountId: String!) {
        cards(accountId: $accountId) {
          cards {
            id
            accountId
            activatedAt
            cardType
            contactlessState
            expiryDate
            issuedAt
            paymentMethod
            printedName
            provider
            providerId
            shareableId
            state
            globalShipmentId
            truncatedNumber
            contactlessEnabled
            temporaryBlockEnabled
            cancelCardEnabled
          }
          issueNewCardEnabled
        }
      }`,
    variables: { accountId },
  },
});
