export const mutationUploadAccountCreationFile = (payload) => ({
  payload: {
    query: `
      mutation UploadAccountCreationFile($uploadAccountCreationFileInput: uploadAccountCreationFileInput!){
        uploadAccountCreationFile(input: $uploadAccountCreationFileInput) {
          successful
          messages{
            message
          }
        }
      }`,
    variables: {
      uploadAccountCreationFileInput: {
        fileContent: payload.fileContent,
        fileName: payload.fileName,
      },
    },
  },
});
