export const mutationIssueCard = (merchantCode, address = null) => ({
  payload: {
    query: `
      mutation ($input: CreateShipmentInput!) {
        createShipment(input: $input) {
          successful
          messages {
            message
          }
          result{
            merchantCode
          }
        }
      }`,
    variables: {
      input: { merchantCode, ...(address && { address }) },
    },
  },
});

export const mutationCancelCard = (shareableId, reason) => ({
  payload: {
    query: `
      mutation(
        $shareableId: String!,
        $state: CardState!,
        $reason: CardCancellationReason!
      ) {
        updateCardState(input: {
          shareableId: $shareableId,
          state: $state,
          reason: $reason
        }) {
          successful
          result {
            card {
              accountId
              id
              state
            }
          }
          messages{
            message
          }
        }
      }`,
    variables: {
      shareableId,
      reason,
      'state': 'CANCELLED',
    },
  },
});

export const mutationTemporaryBlockCard = ({ shareableId, cardState }) => ({
  payload: {
    query: `
      mutation($shareableId: String!, $state: CardState!) {
        updateCardState(input: {
          shareableId: $shareableId,
          state: $state
        }) {
          successful
          result {
            card {
              accountId
              id
              state
            }
          }
          messages{
            message
          }
        }
      }`,
    variables: {
      shareableId,
      state: cardState,
    },
  },
});

export const mutationUpdateCardContactless = ({ shareableId, contactlessEnabled }) => ({
  payload: {
    query: `
      mutation($shareableId: String!, $contactlessEnabled: Boolean!) {
        updateCardContactlessEnabled(input: {
          shareableId: $shareableId,
          contactlessEnabled: $contactlessEnabled
        }) {
          successful
          result {
            card {
              shareableId
              contactlessEnabled
            }
          }
          messages{
            message
            code
          }
        }
      }`,
    variables: {
      shareableId,
      contactlessEnabled,
    },
  },
});
