import { createInstance } from '@optimizely/react-sdk';
import { v4 as uuidv4 } from 'uuid';
import Cookie from 'js-cookie';

import { getEnv } from 'core/modules/helpers';
import config from 'config';

const OPTIMIZELY_USER_ID = 'optimizely_experiment_user';
const ONE_YEAR = 365;

export const optimizelyClient = createInstance({
  sdkKey: config.optimizely[getEnv()],
});

export function getOptimizelyUser(user) {
  const currentUserId = Cookie.get(OPTIMIZELY_USER_ID);
  const { pathname } = window.location;

  const newUser = {
    pathname,
    user,
  };

  const attributes = {
    isLogged: !!user?.email,
  };

  if (user?.email) {
    attributes.userEmail = user?.email;
  }

  if (user?.role) {
    attributes.userRole = user?.role;
  }

  if (currentUserId) {
    return { id: currentUserId, ...newUser, attributes };
  }

  const newUserId = uuidv4();

  Cookie.set(OPTIMIZELY_USER_ID, newUserId, {
    expires: ONE_YEAR,
    domain: `.api.${getEnv()}.sumup-bank.info`,
  });

  return { id: newUserId, ...user, attributes };
}
