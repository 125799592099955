import { datadogLogs } from '@datadog/browser-logs';
import { all, call, put, takeLatest } from 'redux-saga/effects';

import { parseResponseError } from 'core/modules/client';
import { client, getRequestOptions } from 'core/modules/resources';

import { TransactionConstants } from '../constants';
import { mutationGetReceipt, mutationRefundTransaction } from '../graphql/mutations';


export function* getReceipt({ payload }) {
  try {
    const { data } = yield call(client, {
      ...yield getRequestOptions(),
      ...yield mutationGetReceipt(payload),
      method: 'POST',
      operationName: 'getReceipt',
    });

    const successful = data?.getManualReceipt?.successful;

    if (!successful) {
      const [error] = data?.getManualReceipt?.messages;
      throw new Error(error.message);
    }

    const { fileContent, filename } = data?.getManualReceipt?.result;

    yield put({
      type: TransactionConstants.TRANSACTION_GET_RECEIPT_SUCCESS,
      payload: {
        fileContent,
        filename,
      },
    });
  }
  catch ({ message, status }) {
    const error = parseResponseError(message, status);

    datadogLogs.logger.info(message, { name: 'getReceipt', status });

    yield put({
      type: TransactionConstants.TRANSACTION_GET_RECEIPT_FAILURE,
      payload: { message: error, status },
    });
  }
}

export function* refundTransaction({ payload }) {
  try {
    const { merchantCode, transferId } = payload;
    const { data } = yield call(client, {
      ...yield getRequestOptions(),
      ...yield mutationRefundTransaction({
        merchantCode,
        transferId,
      }),
      method: 'POST',
      operationName: 'refundTransaction',
    });

    const successful = data?.refundTransaction?.successful;

    if (!successful) {
      const [error] = data?.refundTransaction?.messages;
      throw new Error(error.message);
    }

    yield put({
      type: TransactionConstants.REFUND_TRANSACTION_SUCCESS,
      payload: {
        data,
      },
    });
  }
  catch (error) {
    yield put({
      type: TransactionConstants.REFUND_TRANSACTION_FAILURE,
      payload: {
        message: error,
      },
    });
  }
}


export default function* root() {
  yield all([
    takeLatest(TransactionConstants.TRANSACTION_GET_RECEIPT_REQUEST, getReceipt),
    takeLatest(TransactionConstants.REFUND_TRANSACTION_REQUEST, refundTransaction),
  ]);
};
