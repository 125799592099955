import React, { CSSProperties, memo } from 'react';
import PropTypes from 'prop-types';

import { ReactComponent as SumUpIcon } from 'assets/icons/sumup.svg';

import { TRANSACTIONS_DICTIONARY } from 'core/constants';

import {
  FiArrowDown,
  FiArrowDownRight,
  FiClock,
  FiDollarSign,
  FiHash,
  FiInfo,
  FiKey,
  FiMapPin,
  FiMessageSquare,
  FiRepeat,
  FiRotateCcw,
  FiTag,
  FiXCircle,
  FiLock,
  FiFile,
} from 'react-icons/fi';

import AtmWithdraw from '../TransactionIcons/AtmWithdrawIcon';
import Barcode from '../TransactionIcons/BarcodeIcon';
import Card from '../TransactionIcons/CardIcon';
import Cash from '../TransactionIcons/CashIcon';
import CashAtm from '../TransactionIcons/CashAtmIcon';
import CashIn from '../TransactionIcons/CashInIcon';
import CashOut from '../TransactionIcons/CashOutIcon';
import MobileTopup from '../TransactionIcons/MobileTopupIcon';
import PixIcon from '../TransactionIcons/PixIcon';
import Transaction from '../TransactionIcons/TransactionIcon';
import TransferIn from '../TransactionIcons/TransferInIcon';
import TransferOut from '../TransactionIcons/TransferOutIcon';

import { IconsStyled } from './Icons.styles';

const { Icon } = IconsStyled;

const TRANSACTION_ICONS = {
  [TRANSACTIONS_DICTIONARY.AMOUNT]: FiDollarSign,
  [TRANSACTIONS_DICTIONARY.ATM_WITHDRAW]: AtmWithdraw,
  [TRANSACTIONS_DICTIONARY.BALANCE_INTEREST]: CashIn,
  [TRANSACTIONS_DICTIONARY.BILL_PAYMENT]: Barcode,
  [TRANSACTIONS_DICTIONARY.BOLETO]: Barcode,
  [TRANSACTIONS_DICTIONARY.CANCELLATION_ID]: FiXCircle,
  [TRANSACTIONS_DICTIONARY.CASH]: Cash,
  [TRANSACTIONS_DICTIONARY.CASH_IN]: CashIn,
  [TRANSACTIONS_DICTIONARY.CASH_OUT]: CashOut,
  [TRANSACTIONS_DICTIONARY.CIP_CASH_IN]: CashIn,
  [TRANSACTIONS_DICTIONARY.CREDIT_CARD_PURCHASE]: Card,
  [TRANSACTIONS_DICTIONARY.DEBIT_CARD_PURCHASE]: Card,
  [TRANSACTIONS_DICTIONARY.DIRECTION]: FiRepeat,
  [TRANSACTIONS_DICTIONARY.ID]: FiKey,
  [TRANSACTIONS_DICTIONARY.IS_REFUND]: FiRotateCcw,
  [TRANSACTIONS_DICTIONARY.MOBILE_TOP_UP]: MobileTopup,
  [TRANSACTIONS_DICTIONARY.PAYMENT]: FiArrowDown,
  [TRANSACTIONS_DICTIONARY.PAYMENT_FAILED]: FiXCircle,
  [TRANSACTIONS_DICTIONARY.PAYOUT]: CashIn,
  [TRANSACTIONS_DICTIONARY.PIX_OUT]: PixIcon,
  [TRANSACTIONS_DICTIONARY.PIX_IN]: PixIcon,
  [TRANSACTIONS_DICTIONARY.PIX_INTERNAL_TRANSFER]: PixIcon,
  [TRANSACTIONS_DICTIONARY.PIX_BLOCK]: PixIcon,
  [TRANSACTIONS_DICTIONARY.POS_NAME]: FiMapPin,
  [TRANSACTIONS_DICTIONARY.REAL_TIME_PAYOUT]: FiArrowDownRight,
  [TRANSACTIONS_DICTIONARY.RECEIPT]: FiFile,
  [TRANSACTIONS_DICTIONARY.SHORT_DESCRIPTION]: FiMessageSquare,
  [TRANSACTIONS_DICTIONARY.SUMUP_SALE]: SumUpIcon,
  [TRANSACTIONS_DICTIONARY.SUMUP_PAYMENTS_TRANSFER]: Transaction,
  [TRANSACTIONS_DICTIONARY.TED_OUT]: Transaction,
  [TRANSACTIONS_DICTIONARY.TED_IN]: Transaction,
  [TRANSACTIONS_DICTIONARY.BANK_EXTERNAL_TRANSFER]: Transaction,
  [TRANSACTIONS_DICTIONARY.BANK_EXTERNAL_TRANSFER_IN]: Transaction,
  [TRANSACTIONS_DICTIONARY.BANK_EXTERNAL_TRANSFER_OUT]: Transaction,
  [TRANSACTIONS_DICTIONARY.TED_OUT_COST]: AtmWithdraw,
  [TRANSACTIONS_DICTIONARY.TRANSFER_ID]: FiTag,
  [TRANSACTIONS_DICTIONARY.TRANSFER_IN]: TransferIn,
  [TRANSACTIONS_DICTIONARY.TRANSFER_OUT]: TransferOut,
  [TRANSACTIONS_DICTIONARY.TYPE]: FiInfo,
  [TRANSACTIONS_DICTIONARY.UTC_DATE_TIME]: FiClock,
  [TRANSACTIONS_DICTIONARY.WITHDRAW]: CashAtm,
  [TRANSACTIONS_DICTIONARY.END_TO_END_ID]: FiLock,
  [TRANSACTIONS_DICTIONARY.SECURED_CREDIT_CARD_LIMIT]: Card,
  [TRANSACTIONS_DICTIONARY.CARD_STATEMENT_PAYMENT]: Card,
  [TRANSACTIONS_DICTIONARY.PROVISIONAL_CREDIT_DEBIT_CARD_PURCHASE]: Card,
  [TRANSACTIONS_DICTIONARY.PROVISIONAL_CREDIT_DEBIT_WITHDRAW]: Card,
  [TRANSACTIONS_DICTIONARY.PROVISIONAL_CREDIT_DEBIT_ATM_WITHDRAW_FEE]: Card,
  [TRANSACTIONS_DICTIONARY.OPERATIONAL_LOSS_DEBIT]: Card,
};

const TransactionIcon = ({ property, style = {} }) => {
  if (
    TRANSACTION_ICONS[property] &&
    typeof TRANSACTION_ICONS[property] === 'function'
  ) {
    return (
      <Icon property={property} data-testid="transaction-icon" style={style}>
        {TRANSACTION_ICONS[property]()}
      </Icon>
    );
  }
  else if (property === 'meta') {
    return (
      <Icon property={property} data-testid="transaction-icon">
        <FiHash />
      </Icon>
    );
  }

  return null;
};

TransactionIcon.propTypes = {
  property: PropTypes.any.isRequired,
  style: CSSProperties,
};

export default memo(TransactionIcon);
