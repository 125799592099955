import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { injectIntl } from 'react-intl';

import { ProfileStyled } from './Profile.styles';

import UserInfo from './UserInfo';

const {
  ProfileWrapper,
} = ProfileStyled;

const Profile = ({ expanded }) => {
  const user = useSelector(state => state.auth.user);

  return (
    <ProfileWrapper
      data-testid="profile"
      expanded={expanded}
    >
      <UserInfo
        email={user.email}
        name={user.name}
        thumb={user.thumb}
        expanded={expanded}
      />
    </ProfileWrapper>
  );
};

Profile.propTypes = {
  expanded: PropTypes.bool,
};

UserInfo.propTypes = {
  email: PropTypes.string,
  handleClick: PropTypes.func,
  name: PropTypes.string,
  thumb: PropTypes.string,
};

UserInfo.defaultProps = {
  email: '',
  handleClick: () => {},
  name: '',
  thumb: '',
};

export default injectIntl(Profile);
