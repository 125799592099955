import styled from '@emotion/styled';
import { css } from '@emotion/react';

export const LoaderWrapper = styled('main')(() => css`
    align-content: center;
    align-items: center;
    display: flex;
    flex-direction: row;
    justify-content: center;
    min-height: 100vh;
    width: 100%;
`);