import React from 'react';

function PixIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      fill="none"
      viewBox="0 0 20 20"
    >
      <path
        fill="#000"
        d="M15.357 4.69c-.786 0-1.523.305-2.077.859l-3.012 3.012a.558.558 0 01-.788 0l-3-3a2.92 2.92 0 00-2.077-.86h-.361L7.851.89a3.038 3.038 0 014.296 0l3.8 3.8h-.59zM4.403 15.3a2.92 2.92 0 002.077-.86l3-3a.569.569 0 01.788 0l3.012 3.011a2.92 2.92 0 002.077.86h.592l-3.802 3.8a3.038 3.038 0 01-4.296 0l-3.809-3.812h.36z"
      />
      <path
        fill="#000"
        d="M3.158 5.582L.89 7.851a3.038 3.038 0 000 4.296l2.268 2.27h1.245c.54 0 1.07-.22 1.453-.603l2.999-2.998c.543-.543 1.493-.543 2.037 0l3.01 3.01a2.07 2.07 0 001.453.603h1.473l2.282-2.282a3.038 3.038 0 000-4.296l-2.28-2.28h-1.473c-.54 0-1.07.22-1.454.604l-3.011 3.011c-.28.28-.648.421-1.018.421-.367 0-.737-.14-1.017-.42l-3-3.001a2.07 2.07 0 00-1.454-.604H3.158z"
      />
    </svg>
  );
}

export default PixIcon;
