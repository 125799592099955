import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { useHistory, useLocation } from 'react-router-dom';



import { ResetStyled } from './Reset.styles';

const {
  ResetButton,
  ResetIcon,
  ResetWrapper,
} = ResetStyled;

const Reset = ({ expanded }) => {
  const history = useHistory();
  const location = useLocation();

  const handleReset = () => {
    history.push({ ...location, search: '?reset' });
    history.go(0);
  };

  return(
    <ResetWrapper expanded={expanded}>
      <ResetButton
        expanded={expanded}
        data-testid="reset"
        onClick={() => handleReset()}
        variant="primary"
      >
        <ResetIcon expanded={expanded ? 1 : 0} />
        {expanded && <FormattedMessage
          id="account.reset"
          defaultMessage="Clear cache"
        />}
      </ResetButton>
    </ResetWrapper>
  );
};

Reset.propTypes = {
  expanded: PropTypes.bool,
};

export default (Reset);
