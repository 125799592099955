import { all, call, put, takeLatest } from 'redux-saga/effects';

import { client, getRequestOptions } from 'core/modules/resources';
import { parseIrsReport } from 'core/modules/parsers';

import { FinanceConstants } from '../constants';

import { queryIrsAvailableDates, queryIrsDownloadReports } from '../graphql/queries';

export function* getIrsAvailableDates() {
  try {
    const { data, errors } = yield call(client, {
      ...yield getRequestOptions(),
      ...yield queryIrsAvailableDates(),
      method: 'POST',
      operationName: 'getIrsAvailableDates',
    });

    if (!data?.availableDates) {
      throw new Error(errors[0].message);
    }

    yield put({
      type: FinanceConstants.FINANCE_IRS_DATES_SUCCESS,
      payload: {
        data: parseIrsReport(data?.availableDates),
      },
    });
  }
  catch (error) {
    yield put({
      type: FinanceConstants.FINANCE_IRS_DATES_FAILURE,
      payload: {
        message: error.message,
      },
    });
  }
}

export function* getIrsDownload({ payload }) {
  try {
    const { data, errors } = yield call(client, {
      ...yield getRequestOptions(),
      ...yield queryIrsDownloadReports(payload.month, payload.year),
      method: 'POST',
      operationName: 'getIrsDownloadReport',
    });

    if (!data?.downloadIrsReports) {
      throw new Error(errors[0].message);
    }

    const { fileContent, filename } = data?.downloadIrsReports;

    yield put({
      type: FinanceConstants.FINANCE_IRS_DOWNLOAD_REPORT_SUCCESS,
      payload: {
        fileContent,
        filename,
      },
    });
  }
  catch (error) {
    yield put({
      type: FinanceConstants.FINANCE_IRS_DOWNLOAD_REPORT_FAILURE,
      payload: {
        message: error.message,
      },
    });
  }
}

export default function* root() {
  yield all([
    takeLatest(FinanceConstants.FINANCE_IRS_DATES_REQUEST, getIrsAvailableDates),
    takeLatest(FinanceConstants.FINANCE_IRS_DOWNLOAD_REPORT_REQUEST, getIrsDownload),
  ]);
};
