export const mutationUploadIntentFile = (payload) => ({
  payload: {
    query: `
      mutation UploadIntentFile($uploadIntentFileInput: uploadIntentFileInput!){
        uploadIntentFile(input: $uploadIntentFileInput) {
          successful
          messages{
            message
          }
        }
      }`,
    variables: {
      'uploadIntentFileInput' : {
        'fileContent': payload.fileContent,
        'fileName': payload.fileName,
      },
    },
  },
});