import React from 'react';

function BarcodeIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      fill="none"
      viewBox="0 0 20 20"
    >
      <path
        fill="#323E49"
        fillRule="evenodd"
        d="M4 16V4h2v12H4z"
        clipRule="evenodd"
      />
      <path
        fill="#323E49"
        fillRule="evenodd"
        d="M0 2a2 2 0 012-2h4v2H2v4H0V2zM18 2h-4V0h4a2 2 0 012 2v4h-2V2zM18 18v-4h2v4a2 2 0 01-2 2h-4v-2h4zM2 18h4v2H2a2 2 0 01-2-2v-4h2v4zM7 14V4h2v10H7zM10 14V4h2v10h-2z"
        clipRule="evenodd"
      />
      <path
        fill="#323E49"
        fillRule="evenodd"
        d="M14 16V4h2v12h-2z"
        clipRule="evenodd"
      />
    </svg>
  );
}

export default BarcodeIcon;
