import { keyMirror } from 'core/modules/helpers';

export const UserManagementConstants  = keyMirror({
  USER_MANAGEMENT_ADD_USER_REQUEST: undefined,
  USER_MANAGEMENT_ADD_USER_SUCCESS: undefined,
  USER_MANAGEMENT_ADD_USER_FAILURE: undefined,
  USER_MANAGEMENT_DELETE_USER_REQUEST: undefined,
  USER_MANAGEMENT_DELETE_USER_SUCCESS: undefined,
  USER_MANAGEMENT_DELETE_USER_FAILURE: undefined,
  USER_MANAGEMENT_GET_LIST_REQUEST: undefined,
  USER_MANAGEMENT_GET_LIST_SUCCESS: undefined,
  USER_MANAGEMENT_GET_LIST_FAILURE: undefined,
  USER_MANAGEMENT_GET_ROLES_REQUEST: undefined,
  USER_MANAGEMENT_GET_ROLES_SUCCESS: undefined,
  USER_MANAGEMENT_GET_ROLES_FAILURE: undefined,
  USER_MANAGEMENT_UPDATE_USER_REQUEST: undefined,
  USER_MANAGEMENT_UPDATE_USER_SUCCESS: undefined,
  USER_MANAGEMENT_UPDATE_USER_FAILURE: undefined,
});
