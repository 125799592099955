import { select } from 'redux-saga/effects';
import { v4 as uuidv4 } from 'uuid';

import { request } from 'core/modules/client';

import config from 'config';

export function* getRequestOptions() {
  const { code } = yield select(state => state.auth.token);

  return {
    token: code,
  };
}

export async function client({ token, headers, method, payload, type, operationName }) {
  return request({
    url: `${config.apiBFB }?${ operationName}`,
    headers: {
      Authorization: `Bearer ${token}`,
      'Content-Type': 'application/json',
      'x-app-origin': 'bankoffice',
      'x-request-id': uuidv4(),
      ...headers,
    },
    method,
    payload,
    type: type || 'JSON',
  });
}


export async function clientCountries(country) {
  return fetch(`${config.apiCountries }/${country}`, {
    method: 'GET',
    type: 'JSON',
  });
}

