import account, { accountState } from 'domains/accounts/reducer';
import finance, { financeState } from 'domains/finance/reducer';
import cards, { cardsState } from 'domains/cards/reducer';
import pix, { pixState } from 'domains/pix/reducer';
import transaction, { transactionState } from 'domains/transactions/reducer';
import userManagement, { userManagementState } from 'domains/userManagement/reducer';
import accountCreation, { accountCreationState } from 'domains/accountCreation/reducer';
import payoutChange, { payoutChangeState } from 'domains/payoutChange/reducer';
import verificationManagement, { verificationManagementState } from 'domains/verificationManagement/reducer';
import app, { appState } from './app';
import auth, { authState } from './auth';
import search, { searchState } from './search';

export const initialState = {
  account: accountState,
  app: appState,
  auth: authState,
  cards: cardsState,
  finance: financeState,
  pix: pixState,
  search: searchState,
  transaction: transactionState,
  userManagement: userManagementState,
  accountCreation: accountCreationState,
  verificationManagement: verificationManagementState,
  payoutChange: payoutChangeState,
};

export default {
  ...account,
  ...app,
  ...auth,
  ...cards,
  ...finance,
  ...pix,
  ...search,
  ...transaction,
  ...userManagement,
  ...accountCreation,
  ...verificationManagement,
  ...payoutChange,
};
