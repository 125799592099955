import * as Sentry from '@sentry/browser';

/**
 *
 * @param {string} message
 * @param {object} options
 */
export const reportBug = (message, options = {}) => {
  const { level = 'warning', ...extra } = options;

  Sentry.withScope(scope => {
    if (Object.keys(extra).length) {
      Object.keys(extra).forEach(d => {
        scope.setExtra(d, extra[d]);
      });
    }
    scope.setLevel(level);
    Sentry.captureMessage(message);
  });
};
