import React from 'react';
import PropTypes from 'prop-types';
import { IntlProvider } from 'react-intl';

const Intl = ({ children, locale, messages }) => (
  <IntlProvider
    data-testid="intl"
    key={`${locale}`}
    locale={locale}
    messages={messages}
    textComponent="span"
  >
    {children}
  </IntlProvider>
);

Intl.propTypes = {
  children: PropTypes.node.isRequired,
  locale: PropTypes.string.isRequired,
  messages: PropTypes.object.isRequired,
};

export default Intl;
