/* eslint-disable valid-jsdoc */
/**
 * Helper functions to get data from the store.
 * @module State
 */
import { store } from 'core/store';

export const { dispatch } = store;

/**
 * Get app from the store
 *
 * @param {string} path
 *
 * @returns {object}
 */
export function getApp(path) {
  const { app } = store.getState();

  return path ? app[path] : app;
}

/**
 * Get User
 *
 * @returns {Object}
 */
export function getUser() {
  const { auth } = store.getState();

  return {
    ...auth.user,
    ...auth.credentials,
    isLogged: auth.isLogged,
  };
}

/**
 * Get accounts
 *
 */
export function getAccounts() {
  const { accounts } = store.getState();

  return {
    ...accounts.fetch.data,
  };
}
