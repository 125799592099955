import { keyMirror } from 'core/modules/helpers';

export const PixConstants = keyMirror({
  ANSWER_PIX_INFRACTION_FAILURE: undefined,
  ANSWER_PIX_INFRACTION_REQUEST: undefined,
  ANSWER_PIX_INFRACTION_SUCCESS: undefined,
  CHECK_PIX_TRANSFER_FAILURE: undefined,
  CHECK_PIX_TRANSFER_REQUEST: undefined,
  CHECK_PIX_TRANSFER_SUCCESS: undefined,
  GET_PIX_INFRACTION_FAILURE: undefined,
  GET_PIX_INFRACTION_REQUEST: undefined,
  GET_PIX_INFRACTION_SUCCESS: undefined,
  GET_PIX_REFUND_FAILURE: undefined,
  GET_PIX_REFUND_REQUEST: undefined,
  GET_PIX_REFUND_SUCCESS: undefined,
  GET_PIX_TRANSFER_FAILURE: undefined,
  GET_PIX_TRANSFER_REQUEST: undefined,
  GET_PIX_TRANSFER_SUCCESS: undefined,
  LIST_INFRACTIONS_FAILURE: undefined,
  LIST_INFRACTIONS_REQUEST: undefined,
  LIST_INFRACTIONS_SUCCESS: undefined,
  LIST_REFUNDS_FAILURE: undefined,
  LIST_REFUNDS_REQUEST: undefined,
  LIST_REFUNDS_SUCCESS: undefined,
  REPORT_INFRACTION_FAILURE: undefined,
  REPORT_INFRACTION_REQUEST: undefined,
  REPORT_INFRACTION_SUCCESS: undefined,
  LIST_PIX_ADDRESS_KEYS_FAILURE: undefined,
  LIST_PIX_ADDRESS_KEYS_REQUEST: undefined,
  LIST_PIX_ADDRESS_KEYS_SUCCESS: undefined,
  LIST_PIX_CLAIMS_FAILURE: undefined,
  LIST_PIX_CLAIMS_REQUEST: undefined,
  LIST_PIX_CLAIMS_SUCCESS: undefined,
  DELETE_PIX_ADDRESS_KEY_FAILURE: undefined,
  DELETE_PIX_ADDRESS_KEY_REQUEST: undefined,
  DELETE_PIX_ADDRESS_KEY_SUCCESS: undefined,
  INCLUDE_FRAUD_MARK_FAILURE: undefined,
  INCLUDE_FRAUD_MARK_REQUEST: undefined,
  INCLUDE_FRAUD_MARK_SUCCESS: undefined,
  LIST_FRAUD_MARK_FAILURE: undefined,
  LIST_FRAUD_MARK_REQUEST: undefined,
  LIST_FRAUD_MARK_SUCCESS: undefined,
});

export const SUMUP_ISPB = '37241230';
export const PIX_CURRENCY = 'BRL';

export const ISPB_DIRECTION = {
  RECEIVER: 'receiver',
  REPORTER: 'reporter',
};

export const InfractionStatus = {
  ACCEPTED: 'ACCEPTED',
  CANCELLED: 'CANCELLED',
  OPEN: 'OPEN',
  RECEIVED: 'RECEIVED',
  REJECTED: 'REJECTED',
};

export const INFRACTIONS = {
  ACCEPTED: 'accepted',
  ANSWER_SELECT: {
    ACCEPTED: 'ACCEPTED',
    REJECTED: 'REJECTED',
  },
  CANCELLED: 'cancelled',
  MUST_ANSWER_IN_DAYS: 7,
  OPEN: 'received',
  RECEIVED: 'received',
  REJECTED: 'rejected',
  REPORTED: 'reported',
};

export const INFRACTION_REASON = {
  FRAUD: 'FRAUD',
  REFUND_REQUEST: 'REFUND_REQUEST',
  CANCEL_REFUND_REQUEST: 'CANCEL_REFUND_REQUEST',
};

export const ADDRESS_KEY_TYPE = {
  CPF: 'CPF',
  CNPJ: 'CNPJ',
  PHONE: 'Phone',
  EMAIL: 'E-mail',
  EVP: 'Random key',
};

export const ADDRESS_KEYS_MAX_NUMBER = 5;
