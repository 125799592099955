import { keyMirror } from 'core/modules/helpers';

export const PayoutChangeConstants = keyMirror({
  PAYOUT_CHANGE_UPLOAD_FILE_REQUEST: undefined,
  PAYOUT_CHANGE_UPLOAD_FILE_SUCCESS: undefined,
  PAYOUT_CHANGE_UPLOAD_FILE_FAILURE: undefined,

  PAYOUT_CHANGE_MANUAL_INPUT_REQUEST: undefined,
  PAYOUT_CHANGE_MANUAL_INPUT_SUCCESS: undefined,
  PAYOUT_CHANGE_MANUAL_INPUT_FAILURE: undefined,
});
