import { handleActions } from 'redux-actions';
import produce from 'immer';
import { STATUS } from 'core/constants';
import { AccountCreationConstants } from '../constants';

export const accountCreationState = {
  uploadFile: {
    data: {},
    message: '',
    status: STATUS.IDLE,
  },
  manualInput: {
    data: {},
    message: '',
    status: STATUS.IDLE,
  },
};

export default {
  accountCreation: handleActions({
    [AccountCreationConstants.ACCOUNT_CREATION_UPLOAD_FILE_REQUEST]: (state) => produce(state, draft => {
      draft.uploadFile.status = STATUS.RUNNING;
    }),
    [AccountCreationConstants.ACCOUNT_CREATION_UPLOAD_FILE_SUCCESS]: (state) => produce(state, draft => {
      draft.uploadFile.status = STATUS.SUCCESS;
    }),
    [AccountCreationConstants.ACCOUNT_CREATION_UPLOAD_FILE_FAILURE]: (state) => produce(state, draft => {
      draft.uploadFile = {
        data: null,
        status: STATUS.ERROR,
      };
    }),
    [AccountCreationConstants.ACCOUNT_CREATION_MANUAL_INPUT_REQUEST]: (state) => produce(state, draft => {
      draft.manualInput.status = STATUS.RUNNING;
    }),
    [AccountCreationConstants.ACCOUNT_CREATION_MANUAL_INPUT_SUCCESS]: (state) => produce(state, draft => {
      draft.manualInput.status = STATUS.SUCCESS;
    }),
    [AccountCreationConstants.ACCOUNT_CREATION_MANUAL_INPUT_FAILURE]: (state) => produce(state, draft => {
      draft.manualInput = {
        data: null,
        status: STATUS.ERROR,
      };
    }),
  }, accountCreationState),
};
