import { datadogLogs } from '@datadog/browser-logs';

import config from 'config';
import { getEnv } from './helpers';

export const datadog = () => {
  const env = getEnv();

  datadogLogs.init(config.datadog);

  // global context
  datadogLogs.addLoggerGlobalContext('app', 'BANKOFFICE');
  datadogLogs.addLoggerGlobalContext('env', env);
  datadogLogs.addLoggerGlobalContext('host', window.location.host);
  datadogLogs.addLoggerGlobalContext('service', 'BANK-BFB');

  window.datadogLogs = datadogLogs;
};
