import config from 'config';
import qs from 'qs';
import { reportBug } from 'core/modules/sentry';
import { saveAs } from 'file-saver';

import { parseErrorObject } from 'core/modules/helpers';

export function parseResponseError(error = '', status = 0) {
  if (status === 400) {
    return 'Problemas com o servidor. Tente novamente mais tarde.';
  }

  if (!error || error.includes('ajax error')) {
    return 'Algum erro ocorreu. Por favor, verifique sua conexão ou tente novamente.';
  }

  return error;
}

export function parseStatus(status) {
  /* eslint-disable quote-props */
  const statuses = {
    '100': 'Continue',
    '101': 'Switching Protocols',
    '200': 'OK',
    '201': 'Created',
    '202': 'Accepted',
    '203': 'Non-Authoritative Information',
    '204': 'No Content',
    '205': 'Reset Content',
    '206': 'Partial Content',
    '300': 'Multiple Choices',
    '302': 'Found',
    '303': 'See Other',
    '304': 'Not Modified',
    '305': 'Use Proxy',
    '400': 'Bad Request',
    '401': 'Unauthorized',
    '402': 'Payment Required',
    '403': 'Forbidden',
    '404': 'Not Found',
    '405': 'Method Not Allowed',
    '406': 'Not Acceptable',
    '407': 'Proxy Authentication Required',
    '408': 'Request Timeout',
    '409': 'Conflict',
    '410': 'Gone',
    '411': 'Length Required',
    '412': 'Precondition Failed',
    '413': 'Request Entity Too Large',
    '414': 'Request-URI Too Long',
    '415': 'Unsupported Media Type',
    '416': 'Requested Range Not Satisfiable',
    '417': 'Expectation Failed',
    '500': 'Internal Server Error',
    '501': 'Not Implemented',
    '502': 'Bad Gateway',
    '503': 'Service Unavailable',
    '504': 'Gateway Timeout',
    '505': 'HTTP Version Not Supported',
  };
  /* eslint-enable quote-props */

  return statuses[`${status}`] || '';
}

export function request(action) {
  if (!action) {
    throw new Error('The action parameter is required');
  }

  const contentTypes = {
    JSON: 'application/json',
    urlencoded: 'application/x-www-form-urlencoded',
  };
  const errors = [];

  if (!action.endpoint && !action.url) {
    errors.push('`an url` or `endpoint`');
  }

  if (action.type && !contentTypes[action.type]) {
    errors.push('`a valid type`');
  }

  if (errors.length) {
    throw new Error(`Error! You must pass ${errors.join(', ')}`);
  }

  const type = action.type || 'urlencoded';
  const url = action.url || `${config.aecpApiUrl}${action.endpoint || ''}`;
  const headers = {
    Accept: action.headers.accept || 'application/json' ,
    'Content-Type': contentTypes[type],
    ...action.headers,
  };
  const params = {
    body: undefined,
    headers,
    method: action.method || 'GET',
    mode: action.mode || 'cors',
    credentials: undefined,
  };

  if (!action.url) {
    params.credentials = 'include';
  }

  if (action.payload && type === 'JSON') {
    params.body = JSON.stringify(action.payload);
  }
  else if (params.method !== 'GET' && action.payload) {
    params.body = qs.stringify(action.payload);
  }

  return fetch(url, params)
    .then(async (response) => {
      if (response.status > 299) {
        const data = await response.json();
        const parsedError = parseErrorObject(data);

        const error = new Error(parsedError || parseStatus(response.status));

        error.response = data;
        error.status = response.status;

        throw error;
      }

      const contentType = response.headers.get('content-type');

      if (contentType && contentType.includes('application/json')) {
        return response.json();
      }

      return response.text();
    });
}

export const downloadFile = (content, filename, type) => {
  /* decode the base64 string(atob), create a new array of 8-bit unsigned integers
      with the same length as the decoded string. After that, we iterate the string
      and populate the array with the Unicode value of each character in the string.
  */
  const arr = Uint8Array.from(atob(content), c => c.charCodeAt(0));
  const blob = new Blob([arr.buffer], { type: `application/${type}` });
  saveAs(blob, filename);
};

export const loadScript = (src, onLoad) => {
  const script = document.createElement('script');
  script.src = src;
  script.async = true;
  script.onload = onLoad;
  document.body.appendChild(script);
};

/**
 * Reset App's storage and cache.
 * @param {boolean} hard
 */
export const resetApp = async (hard = false) => {
  try {
    /* istanbul ignore else */
    if (typeof caches !== 'undefined' && typeof caches.keys === 'function') {
      const cacheNames = await caches.keys();
      cacheNames.forEach(name => {
        caches.delete(name);
      });
    }

    if (hard) {
      localStorage.removeItem('persist:bankoffice');

      /* istanbul ignore else */
      if (navigator.serviceWorker && typeof navigator.serviceWorker.getRegistrations === 'function') {
        const registrations = await navigator.serviceWorker.getRegistrations();
        const sw = [...registrations].find(d => d.active && d.active.state === 'activated');

        /* istanbul ignore else */
        if (sw) {
          sw.unregister();
        }
      }
    }

    window.location.assign(hard ? '/' : window.location.pathname);
  }
  catch (error) {
    reportBug('resetApp', { message: error.toString(), level: 'warning' });
  }
};


/**
 * @param {fn} fn
 * @param {number} retriesLeft
 * @param {number} interval
 * @returns {any}
 */
export function retry(fn, retriesLeft = 5, interval = 1000) {
  return new Promise((resolve, reject) => {
    fn()
      .then(resolve)
      .catch((error) => {
        setTimeout(() => {
          if (retriesLeft === 1) {
            reject(error);
            return;
          }

          retry(fn, retriesLeft - 1, interval).then(resolve, reject);
        }, interval);
      });
  });
};

/**
 * @param {fn} fn
 * @returns {any}
 */
export function reloadScreenOnChunkFail(fn) {
  return new Promise((resolve) => {
    fn()
      .then(resolve)
      .catch(error => {
        if (error.toString().startsWith('ChunkLoadError'))
          window.location.reload(true);
      });
  });
}