import { handleActions } from 'redux-actions';
import produce from 'immer';

import { STATUS } from 'core/constants';
import { TransactionConstants } from '../constants';

export const transactionState = {
  receipt: {
    fileContent: '',
    filename: '',
    message: '',
    status: STATUS.IDLE,
  },
  refund: {
    message: '',
    status: STATUS.IDLE,
    data: {},
  },
};

export default {
  transaction: handleActions({
    [TransactionConstants.TRANSACTION_GET_RECEIPT_REQUEST]: (state) => produce(state, draft => {
      draft.receipt.status = STATUS.RUNNING;
    }),
    [TransactionConstants.TRANSACTION_GET_RECEIPT_SUCCESS]: (state, { payload: { fileContent, filename } }) => produce(state, draft => {
      draft.receipt.fileContent = fileContent;
      draft.receipt.filename = filename;
      draft.receipt.status = STATUS.SUCCESS;
    }),
    [TransactionConstants.TRANSACTION_GET_RECEIPT_FAILURE]: (state, { payload: { message } }) => produce(state, draft => {
      draft.receipt.message = message;
      draft.receipt.status = STATUS.ERROR;
    }),
    [TransactionConstants.REFUND_TRANSACTION_REQUEST]:
      (state) => produce(state, draft => {
        draft.refund.status = STATUS.RUNNING;
      }),
    [TransactionConstants.REFUND_TRANSACTION_FAILURE]:
      (state, { payload: { message } }) => produce(state, draft => {
        draft.refund = {
          data: message,
          state: '',
          status: STATUS.ERROR,
        };
      }),
    [TransactionConstants.REFUND_TRANSACTION_SUCCESS]:
      (state, { payload: { data } }) => produce(state, draft => {
        draft.refund.data = data;
        draft.refund.status = STATUS.SUCCESS;
      }),
  }, transactionState),
};
