/**
 * Returns a new access token to current logged user
 * @returns {Promise<{expiresAt: {number}, refreshToken: {string}}>}
 */
export async function getGoogleAuthRefreshToken() {
  const googleAuth = window.gapi.auth2.getAuthInstance();
  const currentUser = googleAuth.currentUser.get();

  const googleUser = await currentUser.reloadAuthResponse();

  const { access_token, expires_at } = googleUser;

  return {
    refreshToken: access_token,
    expiresAt: expires_at,
  };
}

