import { ReactComponent as CardPaymentIcon } from 'assets/icons/card-payment.svg';
import { ReactComponent as Google } from 'assets/icons/google.svg';
import { ReactComponent as SumUp } from 'assets/icons/sumup.svg';
import { ReactComponent as PixSidebarIcon } from 'assets/icons/pix-sidebar-icon.svg';

import BankIcons from './BankIcons';
import CardFlag from './CardFlag';
import TransactionIcon from './TransactionIcons';

const Icons = {
  BankIcons,
  CardPaymentIcon,
  CardFlag,
  Google,
  SumUp,
  TransactionIcon,
  PixSidebarIcon,
};

export default Icons;
