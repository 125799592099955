import React, { lazy, Suspense } from 'react';
import {
  BrowserRouter as Router,
  Redirect,
  Route,
  Switch,
} from 'react-router-dom';
import { useSelector } from 'react-redux';

import { RoutesConstants } from 'core/constants';

import Loader from 'core/components/Loader';

import { reloadScreenOnChunkFail } from 'core/modules/client';

import { LoaderWrapper } from './Routes.styles';

const Dashboard = lazy(() => reloadScreenOnChunkFail(() => import('core/containers/Dashboard' /* webpackChunkName: 'dashboard' */)));

const publicRoutes = [
  {
    path: RoutesConstants.PUBLIC.LANDING,
    exact: true,
    component: lazy(() => reloadScreenOnChunkFail(() =>import('core/pages/SignIn' /* webpackChunkName: 'signin' */))),
  },
  {
    path: RoutesConstants.PUBLIC.SIGN_IN,
    component: lazy(() => reloadScreenOnChunkFail(() => import('core/pages/SignIn' /* webpackChunkName: 'signin' */))),
  },
  {
    path: RoutesConstants.PUBLIC.DEMO,
    component: lazy(() => reloadScreenOnChunkFail(() => import('core/pages/Demo' /* webpackChunkName: 'Demo' */))),
  },
];

// eslint-disable-next-line react/prop-types
function PrivateRoute({ children, ...rest }) {
  const { isLogged } = useSelector(state => state.auth);

  if (isLogged) {
    return (<Route {...rest}>{children}</Route>);
  }

  return (<Redirect to="/signin" />);
}

export default function Routes() {
  return (
    <Suspense fallback={<LoaderWrapper><Loader /></LoaderWrapper>}>
      <Router>
        <Switch>
          {publicRoutes.map((route, index) => (
            <Route key={index} path={route.path} exact={route.exact}>
              <route.component />
            </Route>
          ))}

          <PrivateRoute path="/dashboard">
            <Dashboard />
          </PrivateRoute>
        </Switch>
      </Router>
    </Suspense>
  );
}
