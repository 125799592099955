import styled from '@emotion/styled';
import { css } from '@emotion/react';
import { Refresh } from '@sumup/icons';
import { Button } from '@sumup/circuit-ui';

export const ResetStyled = {
  ResetWrapper: styled('li')(() => css`
    bottom: 76px;
    position: absolute;
    width: 100%;
  `),
  ResetButton: styled(Button)(({ expanded, theme }) => css`
    align-items: center;
    background-color: ${expanded ? theme.colors.b500: 'transparent'};
    border-radius: ${theme.borderRadius.byte};
    border: 0;
    color: ${theme.colors.white};
    cursor: pointer;
    display: flex;
    font-size: ${theme.iconSizes.kilo};
    height: 40px;
    justify-content: center;
    margin: ${theme.spacings.mega};
    outline: none;
    padding: 4px 10px;
    transition: ${theme.transitions.default};
    width: calc(100% - (2 * 16px));

    :hover {
      background: none;
    }
  `),
  ResetIcon: styled(Refresh)(({theme, expanded }) => css`
    font-size: ${theme.iconSizes.kilo};
    margin-right: ${expanded ? theme.spacings.mega : 0};
    color: ${theme.colors.white};
    height:20px;
    width: 20px;

    :hover, :active {
      height:20px;
      width: 20px;
      transition: all cubic-bezier(0.4, 0.0, 0.2, 1) 0.3s;
      }
  `),
};
