import React from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { useIntl } from 'react-intl';
import { useFeature } from '@optimizely/react-sdk';

import { authLogout } from 'core/actions/auth';
import { revokeToken } from 'core/modules/googleAuth';

import { LogoutStyled } from './Logout.styles';

const {
  LogoutButton,
  LogoutIcon,
  LogoutWrapper,
} = LogoutStyled;

const Logout = ({ expanded }) => {
  const dispatch = useDispatch();
  const intl = useIntl();
  const [ isGoogleLegacyFlowActive ] = useFeature('google_authentication_legacy');
  const { token } = useSelector(state => state.auth);
  const handleLogout = () => (isGoogleLegacyFlowActive
    ? dispatch(authLogout())
    : revokeToken(token.code, () => dispatch(authLogout())));

  return (
    <LogoutWrapper expanded={expanded}>
      <LogoutButton
        expanded={expanded}
        data-testid="logout"
        onClick={handleLogout}
        type="button"
      >
        <LogoutIcon expanded={expanded ? 1 : 0} />
        {expanded && intl.formatMessage({
          id: 'login.logout',
          defaultMessage: 'Logout',
        })}
      </LogoutButton>
    </LogoutWrapper>
  );
};

Logout.propTypes = {
  expanded: PropTypes.bool,
};

export default Logout;

