import styled from '@emotion/styled';
import { css } from '@emotion/react';

export const Button = styled('button')(({ theme }) => css`
background-color: ${theme.colors.n100};
border-radius: ${theme.borderRadius.byte};
border: ${theme.borderWidth.kilo} solid ${theme.colors.n500};
color: ${theme.colors.n800};
cursor: pointer;
display: flex;
flex-direction: row;
justify-content: center;
margin: 0 auto;
margin-bottom: 100px;
max-width: 432px;
outline: none;
padding: 0;
transition: all ${theme.transitions.default};
width: 100%;
`);

export const ButtonIcon = styled('div')(({ theme }) => css`
margin: ${theme.spacings.kilo};
height: 32px;
svg {
  height: 100%;
}
`);

export const ButtonText = styled('span')(({ theme }) => css`
font-size: ${theme.typography.body.one.fontSize};
font-weight: bold;
margin-left: ${theme.spacings.bit};
align-self: center;
`);
