import { datadogLogs } from '@datadog/browser-logs';

import { authLogout, setAuthToken, showAlert} from 'core/actions';
import { AuthConstants, STATUS } from 'core/constants';
import { requestToken } from 'core/modules/googleAuth';

import TranslateMessage from 'core/components/TranslateMessage';
import { tokenRefresh } from 'core/sagas/auth';

let actionsQueue = [];
let runningActions = [];
let retries = 0;

function resetAuthMiddleware() {
  actionsQueue = [];
  runningActions = [];
  retries = 0;
}

export default function createAuthMiddleware() {
  return store => next => action => {
    const { payload, type = '' } = action;
    const { auth } = store.getState();

    const hasUnauthorized = payload?.message === 'Unauthorized';
    const status = payload ? payload.status : 0;
    const now = Math.round(Date.now());

    if (hasUnauthorized && status !== 401) {
      store.dispatch(showAlert(TranslateMessage('unathorized'), { status: STATUS.WARNING, timeout: 2000 }));
      datadogLogs.logger.info('ShowAlert', { name: 'unathorized', status });
    };

    const userHasValidCredentials =
      auth.isLogged && !!auth.token.code && auth.token.expiresAt < now;
    const hasExpiredClientToken = userHasValidCredentials && status === 401;

    const isAuthenticationRequest = [
      'AUTH_LOGIN_',
      'AUTH_LOGOUT',
      'AUTH_REFRESH_TOKEN',
    ].some(data => type.startsWith(data));

    const isAuthenticationResponse = [
      AuthConstants.AUTH_LOGIN_SUCCESS,
      AuthConstants.AUTH_REFRESH_TOKEN,
      AuthConstants.AUTH_TOKEN_SUCCESS,
    ].includes(type);

    // Keep a record of all async actions until they are finished, unless it's a 401 error that will be called again after authentication
    if (!isAuthenticationRequest) {
      if (type.includes('_REQUEST')) {
        runningActions.push(action);
      }
      else if (status !== 401) {
        runningActions = runningActions.filter(d => !type.includes(d.type.replace('_REQUEST', '')));
      }
    }

    // if this is an authentication response run the queued actions
    if (isAuthenticationResponse) {
      next(action);

      actionsQueue.forEach(queuedAction => store.dispatch(queuedAction));

      if (runningActions.length) {
        runningActions.forEach(runningAction => store.dispatch(runningAction));
      }

      resetAuthMiddleware();

      return;
    }

    // Needs to refresh the token
    if (!isAuthenticationRequest && hasExpiredClientToken) {
      if (type.includes('_REQUEST')) {
        actionsQueue.push(action);
      }

      if (retries < 3) {
        if (hasExpiredClientToken) {
          if (auth.token.legacy) {
            store.dispatch(tokenRefresh());
          }
          else {
            const refreshTokenCallback = (accessToken) =>
              store.dispatch(setAuthToken({ accessToken, isLoginFlow: false }));

            requestToken(refreshTokenCallback);
          }
        }

        retries++;
      }
      else {
        store.dispatch(authLogout());
        resetAuthMiddleware();
      }

      return;
    }

    next(action);
  };
}
