import { createActions } from 'redux-actions';
import { v4 as uuidv4 } from 'uuid';

import { AppConstants } from 'core/constants';

export const {
  hideAlert,
  setLocale,
  setScreenSize,
  showAlert,
  toggleSidebar,
} = createActions(
  {
    [AppConstants.HIDE_ALERT]: (id) => ({ id }),
    [AppConstants.SET_LOCALE]: (locale) => ({ locale }),
    [AppConstants.SET_SCREEN_SIZE]: (screenSize) => ({ screenSize }),
    [AppConstants.SHOW_ALERT]: (message, options = {}) => ({
      icon: options.icon,
      id: options.id || uuidv4(),
      message,
      position: options.position || 'TOP_CENTER',
      status: options.status,
      timeout: options.timeout || 3000,
    }),
    [AppConstants.TOGGLE_SIDEBAR]: (status) => ({ status }),
  }
);
