import React, { memo } from 'react';
import PropTypes from 'prop-types';

import { AiOutlineIdcard, AiOutlineBarcode } from 'react-icons/ai';
import { IoIosTransgender } from 'react-icons/io';
import {
  FiCalendar,
  FiCopy,
  FiKey,
  FiMail,
  FiPhone,
  FiUser,
} from 'react-icons/fi';
import { MdCake } from 'react-icons/md';
import { TiBusinessCard } from 'react-icons/ti';
import { FaMoneyCheckAlt, FaMapSigns } from 'react-icons/fa';

import { IconsStyled } from './Icons.styles';

const { Icon } = IconsStyled;

const BANK_ICONS = {
  insertedAt: FiCalendar,
  fullName: FiUser,
  mothersName: AiOutlineIdcard,
  email: FiMail,
  birthDate: FiCalendar,
  age: MdCake,
  personType: TiBusinessCard,
  nationalId: FiKey,
  phoneNumber: FiPhone,
  gender: IoIosTransgender,
  sumupMerchantCode: AiOutlineBarcode,
  number: FaMoneyCheckAlt,
  street: FaMapSigns,
  addressNumber: FaMapSigns,
  complement: FaMapSigns,
  zipcode: FaMapSigns,
  city: FaMapSigns,
  district: FaMapSigns,
  state: FaMapSigns,
  kind: FaMapSigns,
  country: FaMapSigns,
};

const BankIcon = ({ property }) => {
  if (BANK_ICONS[property] && typeof BANK_ICONS[property] === 'function') {
    return (
      <Icon property={property} data-testid="bank-icon">
        {BANK_ICONS[property]()}
      </Icon>
    );
  }
  else if (property === 'copy') {
    return (
      <Icon property={property} data-testid="bank-icon">
        <FiCopy />
      </Icon>
    );
  }

  return null;
};

BankIcon.propTypes = {
  property: PropTypes.any.isRequired,
};

export default memo(BankIcon);
