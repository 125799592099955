import { handleActions } from 'redux-actions';
import produce from 'immer';

import { STATUS } from 'core/constants';
import { cardConstants } from '../redux/constants';

export const cardsState = {
  cancelCard: {
    message: '',
    shareableId: '',
    reason: '',
    status: STATUS.IDLE,
  },
  cards: {
    data: [],
    message: '',
    status: STATUS.IDLE,
  },
  issueCard: {
    message: '',
    status: STATUS.IDLE,
  },
  temporaryBlock: {
    message: '',
    shareableId: '',
    state: '',
    status: STATUS.IDLE,
  },
  updateContactless: {
    message: '',
    shareableId: '',
    status: STATUS.IDLE,
  },
};

export default {
  cards: handleActions({
    [cardConstants.CARDS_CANCEL_CARD_REQUEST]:
      (state, { payload: { shareableId, reason } }) => produce(state, draft => {
        draft.cancelCard.shareableId = shareableId;
        draft.cancelCard.reason = reason;
        draft.cancelCard.status = STATUS.RUNNING;
      }),
    [cardConstants.CARDS_CANCEL_CARD_SUCCESS]: (state) => produce(state, draft => {
      draft.cancelCard.status = STATUS.SUCCESS;
    }),
    [cardConstants.CARDS_CANCEL_CARD_FAILURE]:
      (state, { payload: { message } }) => produce(state, draft => {
        draft.cancelCard = {
          message,
          status: STATUS.ERROR,
        };
      }),
    [cardConstants.CARDS_GET_ALL_REQUEST]: (state) => produce(state, draft => {
      draft.cards.status = STATUS.RUNNING;
    }),
    [cardConstants.CARDS_GET_ALL_SUCCESS]:
      (state, { payload: { data } }) => produce(state, draft => {
        draft.cards.data = {
          ...data,
          cards: data.cards.map(d => Object.assign(d, {
            blockCard: {
              message: '',
              status: STATUS.IDLE,
            },
            cancelCard: {
              message: '',
              status: STATUS.IDLE,
            },
            unblockCard: {
              message: '',
              status: STATUS.IDLE,
            },
          })),
        };
        draft.cards.status = STATUS.SUCCESS;
      }),
    [cardConstants.CARDS_GET_ALL_FAILURE]:
      (state, { payload: { message } }) => produce(state, draft => {
        draft.cards = {
          data: {},
          message,
          status: STATUS.ERROR,
        };
      }),
    [cardConstants.CARDS_ISSUE_CARD_REQUEST]: (state) => produce(state, draft => {
      draft.issueCard.status = STATUS.RUNNING;
    }),
    [cardConstants.CARDS_ISSUE_CARD_SUCCESS]: state => produce(state, draft => {
      draft.issueCard.status = STATUS.SUCCESS;
    }),
    [cardConstants.CARDS_ISSUE_CARD_FAILURE]:
      (state, { payload: { message } }) => produce(state, draft => {
        draft.issueCard = {
          message,
          status: STATUS.ERROR,
        };
      }),
    [cardConstants.CARDS_TEMPORARY_BLOCK_REQUEST]:
      (state, { payload: { shareableId, cardState } }) => produce(state, draft => {
        draft.temporaryBlock.shareableId = shareableId;
        draft.temporaryBlock.state = cardState;
        draft.temporaryBlock.status = STATUS.RUNNING;
      }),
    [cardConstants.CARDS_TEMPORARY_BLOCK_SUCCESS]:
      (state, { payload: { shareableId } }) => produce(state, draft => {
        draft.temporaryBlock.shareableId = shareableId;
        draft.temporaryBlock.status = STATUS.SUCCESS;
      }),
    [cardConstants.CARDS_TEMPORARY_BLOCK_FAILURE]:
      (state, { payload: { message, shareableId } }) => produce(state, draft => {
        draft.temporaryBlock = {
          message,
          shareableId,
          state: '',
          status: STATUS.ERROR,
        };
      }),
    [cardConstants.CARDS_UPDATE_CONTACTLESS_REQUEST]:
      (state, { payload: { shareableId } }) => produce(state, draft => {
        draft.updateContactless.shareableId = shareableId;
        draft.updateContactless.status = STATUS.RUNNING;
      }),
    [cardConstants.CARDS_UPDATE_CONTACTLESS_SUCCESS]:
      (state, { payload: { shareableId } }) => produce(state, draft => {
        draft.updateContactless.shareableId = shareableId;
        draft.updateContactless.status = STATUS.SUCCESS;
      }),
    [cardConstants.CARDS_UPDATE_CONTACTLESS_FAILURE]:
      (state, { payload: { message, shareableId } }) => produce(state, draft => {
        draft.updateContactless = {
          message,
          shareableId,
          status: STATUS.ERROR,
        };
      }),
  }, cardsState),
};
