import styled from '@emotion/styled';
import { css } from '@emotion/react';

import { Headline, ButtonGroup } from '@sumup/circuit-ui';

import { ReactComponent as BugBugSVG } from 'assets/icons/bug.svg';

export const ErrorBoundaryStyled = {
  ButtonGroup: styled(ButtonGroup)`
    padding: 0;
  `,
  ErrorBoundaryWrapper: styled('div')(({ theme }) => css`
    align-items: center;
    backface-visibility: hidden;
    background-color: ${theme.colors.n100};
    box-sizing: border-box;
    display: flex;
    height: 100%;
    justify-content: center;
    left: 0;
    padding: ${theme.spacings.zetta};
    position: fixed;
    top: 0;
    width: 100%;
    
    > div {
      display: flex;
      flex-wrap: nowrap;
      padding: 0;
    }
  `),
  ErrorContent: styled('div')(({ theme }) => css`
    display: flex;
    flex-direction: column;
    flex: 1 0 auto;
    justify-content: center;
    position: relative;
    width: 50%;
    
    p {
      color: ${theme.colors.n700};
      font-size: ${theme.iconSizes.mega};
    }
  `),
  BugSVG: styled(BugBugSVG)`
    align-self: center;
    justify-self: center;
    animation: bug-animation 1.2s infinite;
    cursor: pointer;
    height: 200px;
    width: 200px;
    
    @keyframes bug-animation {
      0% { transform: translateX(0) translateY(0) rotate(0); }
      25% { transform: translateX(5px) translateY(5px) rotate(1deg); }
      50% { transform: translateX(0) translateY(0) rotate(0) }
      75% { transform: translateX(-5px) translateY(5px) rotate(-0.5deg); }
      100% { transform: translateX(0) translateY(0) rotate(0) }
    }
  `,
  BugMessage: styled('div')(({ theme }) => css`
    background-color: ${theme.colors.b100};
    border-radius: ${theme.borderRadius.byte};
    border: ${theme.borderWidth.kilo} solid ${theme.colors.p300};
    color: ${theme.colors.p500};
    left: 50%;
    padding: ${theme.spacings.byte};
    position: absolute;
    text-align: center;
    top: 0;
    transform: translate(-50%, -50%);
    
    strong {
      color: ${theme.colors.p900};
    }
    
    :after,
    :before {
      top: 100%;
      left: 50%;
      border: solid transparent;
      content: " ";
      height: 0;
      width: 0;
      position: absolute;
      pointer-events: none;
    }
    :after {
      border-color: transparent;
      border-top-color: ${theme.colors.p100};
      border-width: 5px;
      margin-left: -5px;
    }
    :before {
      border-color: transparent;
      border-top-color: ${theme.colors.p300};
      border-width: 6px;
      margin-left: -6px;
    }
  `),
  Headline: styled(Headline)(({ theme }) => css`
    align-items: center;
    color: ${theme.colors.n900};
    display: flex;
    font-weight: 600;
    margin-bottom: ${theme.spacings.mega};
  `),
  LogoWrapper: styled('div')(({ theme }) => css`
    display: flex;
    height: 40px;
    left: ${theme.spacings.zetta};
    position: fixed;
    text-align: left;
    top: ${theme.spacings.zetta};
    
    div {
      height: 100%;
    }
  `),
};
