export const mutationUploadPayoutChangeFile = (payload) => ({
  payload: {
    query: `
      mutation UploadChangePayoutFile($uploadChangePayoutFileInput: uploadChangePayoutFileInput!){
        uploadChangePayoutFile(input: $uploadChangePayoutFileInput) {
          successful
          messages{
            message
          }
        }
      }`,
    variables: {
      uploadChangePayoutFileInput: {
        fileContent: payload.fileContent,
        fileName: payload.fileName,
      },
    },
  },
});
