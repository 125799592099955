import * as Sentry from '@sentry/browser';
import { reportBug } from 'core/modules/sentry';
import { flattenDeep } from 'lodash';
import { getUser } from './state';

/**
 * @param {(string | string[])} value
 * @returns {boolean} true
 */
export function checkPermission(value) {
  Sentry.addBreadcrumb({
    category: 'ui',
    message: `Checked permissions: "${value}"`,
    level: 'info',
  });

  if (value === undefined) {
    reportBug('checkPermission', { message: value, level: 'error' });
  }

  const valueArray = flattenDeep([value]);
  return getUser().allowedPermissions &&
    getUser().allowedPermissions.some(p => valueArray.includes(p));
}
