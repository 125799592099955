import React from 'react';

function TransferOutIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="15"
      height="15"
      fill="none"
      viewBox="0 0 15 15"
    >
      <path
        stroke="#080808"
        strokeLinejoin="round"
        strokeWidth="2"
        d="M4.467 1H14m0 0v9.533M14 1L1 14"
      />
    </svg>
  );
}

export default TransferOutIcon;