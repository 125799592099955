import React, { createContext, useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { loadGoogleAuthClient } from 'core/modules/googleAuth';
import { useFeature } from '@optimizely/react-sdk';

const GoogleAuthContext = createContext({});

export const GoogleAuthProvider = ({ children }) => {
  const [scriptLoadedSuccessfully, setScriptLoadedSuccessfully] = useState(false);
  const [ isGoogleLegacyFlowActive ] = useFeature('google_authentication_legacy');

  useEffect(() => {
    loadGoogleAuthClient(isGoogleLegacyFlowActive)
      .then(() => setScriptLoadedSuccessfully(true));
  }, [isGoogleLegacyFlowActive]);

  const contextValue = useMemo(() => ({
    authScriptLoaded: scriptLoadedSuccessfully,
    legacyFlow: isGoogleLegacyFlowActive,
  }), [scriptLoadedSuccessfully, isGoogleLegacyFlowActive]);

  return (
    <GoogleAuthContext.Provider value={contextValue}>
      {children}
    </GoogleAuthContext.Provider>
  );
};

GoogleAuthProvider.propTypes = ({
  children: PropTypes.node,
});

export default GoogleAuthContext;
