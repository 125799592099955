export const mutationAnswerInfraction = (
  dictInfractionId,
  status,
  analysisDetails
) => ({
  payload: {
    query: `
    mutation ($input: AnswerPixInfractionInput!){
      answerPixInfraction(input: $input) {
          successful
          messages {
              code
              message
          }
      }
  }`,
    variables: {
      input: {
        dictInfractionId,
        status,
        analysisDetails,
      },
    },
  },
});

export const mutationReportInfraction = (variables) => ({
  payload: {
    query: `
    mutation Infraction($input: ReportInfractionInput!) {
      reportInfraction(input: $input) {
        successful
        infraction {
          dictInfractionId
          endToEndId
          reporterEmail
        }
        messages {
          field
          message
          code
        }
      }
    }
    `,
    variables,
  },
});

export const mutationDeletePixAddressKey = (accountId, key) => ({
  payload: {
    query: `
    mutation AddressKey($input: DeletePixAddressKeyInput!) {
      deletePixAddressKey(input: $input) {
          successful
          messages {
              message
              code
          }
      }
  }
    `,
    variables: {
      input: {
        accountId,
        key,
      },
    },
  },
});


export const mutationCreateFraudMark = (nationalId, fraudType, personType) => ({
  payload: {
    query: `
    mutation FraudMark($input: IncludeFraudMarkInput!) {
      includeFraudMark(input: $input) {
          successful
          messages {
              message
              code
          }
          fraudMark{
            correlationId,
            creationDate,
            details,
            dictFraudMarkDatetime,
            fraudMarkId,
            fraudMarkSituation,
            fraudType,
            key,
            nationalId,
          }
      }
  }
    `,
    variables: {
      input: {
        nationalId,
        fraudType,
        personType,
      },
    },
  },
});

