export const mutationAddUser = (payload) => ({
  payload: {
    query: `
      mutation addUser($addUserInput: AddUserInput!){
        addUser(input: $addUserInput) {
          successful
          messages{
            message
          }
          result{
            email
            fullName
            profile
          }
        }
      }`,
    variables: {
      'addUserInput' : {
        'email': payload.email,
        'fullName': payload.fullName,
        'profile': payload.role,
      },
    },
  },
});

export const mutationDeleteUser = (email) => ({
  payload: {
    query: `
      mutation DeleteUser($deleteUserInput: DeleteUserInput!){
        deleteUser(input: $deleteUserInput) {
          successful
          messages{
            code
            message
          }
          result{
            email
            fullName
            profile
          }
        }
      }`,
    variables: {
      'deleteUserInput': {
        email,
      },
    },
  },
});

export const mutationUpdateUser = (payload) => ({
  payload: {
    query: `
      mutation UpdateUser($updateUserInput: UpdateUserInput!){
        updateUser(input: $updateUserInput) {
          successful
          messages{
            code
            message
          }
          result{
            email
            fullName
            profile
          }
        }
      }`,
    variables: {
      'updateUserInput': {
        'email': payload.email,
        'fullName': payload.fullName,
        'profile': payload.role,
      },
    },
  },
});
