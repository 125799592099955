import config from 'config';
import { loadScript } from './client';

let tokenClient = null;
let gisOauth = null;
let callback = (token) => token;

export const loadGoogleAuthClient = (legacy) =>
  new Promise((resolve, reject) => {
    if (legacy) {
      loadScript(
        config.googleAuth2.service,
        () => {
          try {
            const googleAuthApi = window.gapi;
            googleAuthApi.load('auth2', () => {
              googleAuthApi.auth2.init({ client_id: config.googleAuth2.clientId });
              resolve();
            });
          }
 catch (error) {
            reject(error);
          }
        }
      );
    }
    else {
      loadScript(
        config.googleSignIn.service,
        () => {
          try {
            gisOauth = window?.google?.accounts?.oauth2;
            tokenClient = gisOauth.initTokenClient({
              client_id: config.googleSignIn.clientId,
              callback: (tokenResponse) => callback(tokenResponse),
              scope: 'openid profile email',
              flow: 'implicit',
            });
            resolve();
          }
          catch (error) {
            reject(error);
          }
        }
      );
    }
  });


export const requestToken = (callbackFn) => {
  if (!tokenClient) {
    throw new Error('token client not ready');
  }
  callback = callbackFn;
  tokenClient.requestAccessToken();
};

export const revokeToken = (token, callbackFn) => {
  if(!gisOauth) {
    throw new Error('google auth client is not ready');
  }

  gisOauth.revoke(token, callbackFn);
};
