import { handleActions } from 'redux-actions';
import produce from 'immer';

import { STATUS, AppConstants } from 'core/constants';

import { getScreenSize } from 'core/modules/dom';
import { getEnv } from 'core/modules/helpers';

import packageJson from '../../../package.json';

export const appState = {
  alerts: [],
  environment: getEnv(),
  i18n: {
    country: 'BR',
    currency: 'BRL',
    locale: {
      label: 'English',
      value: 'en',
    },
  },
  sidebar: {
    expanded: true,
  },
  screenSize: getScreenSize(),
  status: STATUS.IDLE,
  version: packageJson.version,
};

export default {
  app: handleActions({
    [AppConstants.HIDE_ALERT]: (state, { payload }) => produce(state, draft => {
      draft.alerts = draft.alerts.filter(alert => payload.id !== alert.id);
    }),
    [AppConstants.SET_LOCALE]: (state, { payload }) => produce(state, draft => {
      draft.i18n.locale = payload.locale;
    }),
    [AppConstants.SET_SCREEN_SIZE]: (state, { payload }) => produce(state, draft => {
      draft.screenSize = payload.screenSize;
    }),
    [AppConstants.SHOW_ALERT]: (state, { payload }) => produce(state, draft => {
      draft.alerts.push({...payload});
    }),
    [AppConstants.TOGGLE_SIDEBAR]: (state, { payload: { status } }) => produce(state, draft => {
      draft.sidebar.expanded = status;
    }),
  }, appState),
};


