import React from 'react';

function TransferInIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="15"
      height="15"
      fill="none"
      viewBox="0 0 15 15"
    >
      <path
        stroke="#080808"
        strokeLinejoin="round"
        strokeWidth="2"
        d="M14 4.467V14m0 0H4.467M14 14L1 1"
      />
    </svg>
  );
}

export default TransferInIcon;
