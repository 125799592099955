import { keyMirror } from 'core/modules/helpers';

export const TransactionConstants = keyMirror({
  TRANSACTION_GET_RECEIPT_REQUEST: undefined,
  TRANSACTION_GET_RECEIPT_SUCCESS: undefined,
  TRANSACTION_GET_RECEIPT_FAILURE: undefined,
  REFUND_TRANSACTION_REQUEST: undefined,
  REFUND_TRANSACTION_SUCCESS: undefined,
  REFUND_TRANSACTION_FAILURE: undefined,
});
