import { handleActions } from 'redux-actions';
import produce from 'immer';
import { STATUS } from 'core/constants';
import { VerificationManagementConstants } from '../constants';

export const verificationManagementState = {
  uploadFile: {
    data: {},
    message: '',
    status: STATUS.IDLE,
  },
};

export default {
  verificationManagement: handleActions({
    [VerificationManagementConstants.VERIFICATION_MANAGEMENT_UPLOAD_FILE_REQUEST]: (state) => produce(state, draft => {
      draft.uploadFile.status = STATUS.RUNNING;
    }),
    [VerificationManagementConstants.VERIFICATION_MANAGEMENT_UPLOAD_FILE_SUCCESS]: (state) => produce(state, draft => {
      draft.uploadFile.status = STATUS.SUCCESS;
    }),
    [VerificationManagementConstants.VERIFICATION_MANAGEMENT_UPLOAD_FILE_FAILURE]: (state) => produce(state, draft => {
      draft.uploadFile = {
        data: null,
        status: STATUS.ERROR,
      };
    }),
  }, verificationManagementState),
};
