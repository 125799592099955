import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import * as Sentry from '@sentry/browser';

import { Button } from '@sumup/circuit-ui';
import { FormattedMessage } from 'react-intl';

import { authLogout as authLogoutAction } from 'core/actions';
import { reportBug } from 'core/modules/sentry';

import Layout from 'core/containers/Layout';
import Logo from 'core/components/Logo';

import { contributors } from '../../../../package.json';

import { ErrorBoundaryStyled } from './ErrorBoundary.styles';

const { Container } = Layout;

const {
  BugMessage,
  BugSVG,
  ButtonGroup,
  ErrorBoundaryWrapper,
  ErrorContent,
  Headline,
  LogoWrapper,
} = ErrorBoundaryStyled;

class ErrorBoundary extends Component {
  constructor(props) {
    super(props);
    this.state = {
      eventId: null,
      hasError: false,
      showBugMessage: false,
    };
  }

  static getDerivedStateFromError() {
    return { hasError: true };
  }

  static propTypes = {
    authLogout: PropTypes.func.isRequired,
    children: PropTypes.node,
    user: PropTypes.object,
  };

  componentDidCatch(error, errorInfo) {
    this.setState({ eventId: error });

    reportBug(`ErrorBoundary: ${error.toString()}`, { error, errorInfo, level: 'error' });
  }

  handleLogout() {
    const { authLogout } = this.props;

    authLogout();

    this.setState({
      eventId: null,
      hasError: false,
    });
  }

  handleReportBug() {
    const { eventId } = this.state;
    const { user } = this.props;

    Sentry.showReportDialog({
      eventId,
      user: {
        name: user.name,
        email: user.email,
      },
    });
  }

  render() {
    const { hasError, showBugMessage } = this.state;
    const { children } = this.props;
    const randonContribuitor = contributors[Math.floor(Math.random() * contributors.length)];

    if (hasError) {
      return (
        <ErrorBoundaryWrapper>
          <LogoWrapper>
            <Logo />
          </LogoWrapper>

          <Container>
            <ErrorContent>
              <Headline size="one">
                <FormattedMessage
                  id="error.title"
                  defaultMessage="We're fixing it"
                />
              </Headline>

              <p><FormattedMessage
                id="error.subtitle"
                defaultMessage="This page is having some technical hiccups. Let us know about this issue so we can resolve it quickly."
              />
              </p>

              <ButtonGroup align="left">
                <Button variant="primary" onClick={() => this.handleReportBug()}>
                  <FormattedMessage
                    id="error.buttonReportBug"
                    defaultMessage="Report Bug"
                  />
                </Button>

                <Button variant="secondary" onClick={() => this.handleLogout()}>
                  <FormattedMessage
                    id="error.buttonLogout"
                    defaultMessage="Logout"
                  />
                </Button>
              </ButtonGroup>
            </ErrorContent>

            <ErrorContent>
              {showBugMessage && (
                <BugMessage>
                  <FormattedMessage
                    id="error.bugMessage"
                    defaultMessage="It wasn't my fault, but maybe {name} can help you!"
                    values={{
                      name: <strong>{randonContribuitor.name}</strong>}}
                  />
                </BugMessage>
              )}

              <BugSVG onClick={() => this.setState({ showBugMessage: !showBugMessage })} />
            </ErrorContent>
          </Container>
        </ErrorBoundaryWrapper>
      );
    }

    return children;
  }
}

const mapStateToProps = state => ({
  user: state.auth.user,
});

const mapDispatchToProps = dispatch => ({
  authLogout: () => dispatch(authLogoutAction()),
});

export default connect(mapStateToProps, mapDispatchToProps)(ErrorBoundary);
