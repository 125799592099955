import { handleActions } from 'redux-actions';
import produce from 'immer';

import { STATUS } from 'core/constants';
import { UserManagementConstants } from '../constants';

export const userManagementState = {
  allRoles: {
    data: {},
    message: '',
    status: STATUS.IDLE,
  },
  addUser: {
    data: {},
    message: '',
    status: STATUS.IDLE,
  },
  deleteUser: {
    message: '',
    status: STATUS.IDLE,
  },
  updateUser: {
    message: '',
    status: STATUS.IDLE,
  },
  userList: {
    data: {},
    message: '',
    status: STATUS.IDLE,
  },
};

export default {
  userManagement: handleActions({
    [UserManagementConstants.USER_MANAGEMENT_ADD_USER_REQUEST]: (state) => produce(state, draft => {
      draft.addUser.status = STATUS.RUNNING;
    }),
    [UserManagementConstants.USER_MANAGEMENT_ADD_USER_SUCCESS]: (state, { payload: { data } }) => produce(state, draft => {
      draft.addUser.data = data;
      draft.addUser.status = STATUS.SUCCESS;
    }),
    [UserManagementConstants.USER_MANAGEMENT_ADD_USER_FAILURE]: (state, { payload: { message } }) => produce(state, draft => {
      draft.addUser = {
        data: null,
        message,
        status: STATUS.ERROR,
      };
    }),
    [UserManagementConstants.USER_MANAGEMENT_DELETE_USER_REQUEST]: (state) => produce(state, draft => {
      draft.deleteUser.status = STATUS.RUNNING;
    }),
    [UserManagementConstants.USER_MANAGEMENT_DELETE_USER_SUCCESS]: (state) => produce(state, draft => {
      draft.deleteUser.status = STATUS.SUCCESS;
    }),
    [UserManagementConstants.USER_MANAGEMENT_DELETE_USER_FAILURE]: (state, { payload: { message } }) => produce(state, draft => {
      draft.deleteUser = {
        message,
        status: STATUS.ERROR,
      };
    }),
    [UserManagementConstants.USER_MANAGEMENT_GET_LIST_REQUEST]: (state) => produce(state, draft => {
      draft.userList.status = STATUS.RUNNING;
    }),
    [UserManagementConstants.USER_MANAGEMENT_GET_LIST_SUCCESS]: (state, { payload: { data } }) => produce(state, draft => {
      draft.userList.data = data;
      draft.userList.status = STATUS.SUCCESS;
    }),
    [UserManagementConstants.USER_MANAGEMENT_GET_LIST_FAILURE]: (state, { payload: { message } }) => produce(state, draft => {
      draft.userList = {
        data: null,
        message,
        status: STATUS.ERROR,
      };
    }),
    [UserManagementConstants.USER_MANAGEMENT_GET_ROLES_REQUEST]: (state) => produce(state, draft => {
      draft.allRoles.status = STATUS.RUNNING;
    }),
    [UserManagementConstants.USER_MANAGEMENT_GET_ROLES_SUCCESS]: (state, { payload: { data } }) => produce(state, draft => {
      draft.allRoles.data = data;
      draft.allRoles.status = STATUS.SUCCESS;
    }),
    [UserManagementConstants.USER_MANAGEMENT_GET_ROLES_FAILURE]: (state, { payload: { message } }) => produce(state, draft => {
      draft.allRoles = {
        data: null,
        message,
        status: STATUS.ERROR,
      };
    }),
    [UserManagementConstants.USER_MANAGEMENT_UPDATE_USER_REQUEST]: (state) => produce(state, draft => {
      draft.updateUser.status = STATUS.RUNNING;
    }),
    [UserManagementConstants.USER_MANAGEMENT_UPDATE_USER_SUCCESS]: (state) => produce(state, draft => {
      draft.updateUser.status = STATUS.SUCCESS;
    }),
    [UserManagementConstants.USER_MANAGEMENT_UPDATE_USER_FAILURE]: (state, { payload: { message } }) => produce(state, draft => {
      draft.updateUser = {
        message,
        status: STATUS.ERROR,
      };
    }),
  }, userManagementState),
};
