export const mutationCreateStatement = (payload) => ({
  payload: {
    query: `
      mutation Transactions($createManualStatementInput: createManualStatementInput!) {
        createManualStatement(input: $createManualStatementInput) {
          successful
          messages{
            message
            code
          }
          result{
            fileContent
            filename
          }
        }
      }`,
    variables: {
      createManualStatementInput: {
        endDate: payload.endDate,
        nationalId: payload.nationalId,
        startDate: payload.startDate,
      },
    },
  },
});

export const mutationCloseLedgerAccount = (accountNumber, reason) => ({
  payload: {
    query: `
      mutation CloseLedgerAccount($closeLedgerAccountInput: CloseLedgerAccountInput!) {
        closeLedgerAccount(input: $closeLedgerAccountInput) {
          successful
          messages{
            message
            code
          }
          result{
            accountNumber
            reason
          }
        }
      }`,
    variables: {
      closeLedgerAccountInput: {
        accountNumber,
        reason,
      },
    },
  },
});

export const mutationReopenLedgerAccount = (nationalId) => ({
  payload: {
    query: `
      mutation ReopenLedgerAccount($reopenLedgerAccountInput: ReopenLedgerAccountInput!) {
        reopenLedgerAccount(input: $reopenLedgerAccountInput) {
          successful
          messages{
            message
            code
          }
          result{
            nationalId
            state
          }
        }
      }`,
    variables: {
      reopenLedgerAccountInput: {
        nationalId,
      },
    },
  },
});

export const mutationEarningReport = (MID, year) => ({
  payload: {
    query: `
    mutation CreateEarningReport($createEarningReportInput: CreateEarningReportInput!){
      createEarningReport(input: $createEarningReportInput) {
        successful
        messages {
          code
          message
        }
        result {
          identifier
        }
      }
    }`,
    variables: {
      'createEarningReportInput': {
        identifier: MID,
        year,
      },
    },
  },
});

export const makeAccountSuspended = (nationalId) => ({
  payload: {
    query: `
    mutation MakeAccountSuspended($makeAccountSuspendedInput: MakeAccountSuspendedInput!) {
      makeAccountSuspended(input: $makeAccountSuspendedInput) {
        successful
        messages{
          message
          code
        }
        result{
          nationalId
          state
        }
        }
      }`,
    variables: {
      'updateLedgerAccountStateInput': { nationalId },
    },
  },
});

export const mutationMakeAccountUnlimited = (nationalId) => ({
  payload: {
    query: `
    mutation MakeAccountUnlimited($makeAccountUnlimitedInput: MakeAccountUnlimitedInput!) {
      makeAccountUnlimited(input: $makeAccountUnlimitedInput) {
        successful
        messages{
          message
          code
        }
        result{
          nationalId
          state
        }
        }
      }`,
    variables: {
      'makeAccountUnlimitedInput': { nationalId },
    },
  },
});

export const mutationMakeAccountKycRequired = (accountNumber) => ({
  payload: {
    query: `
        mutation($MakeAccountKycRequiredInput: MakeAccountKycRequiredInput!) {
          makeAccountKycRequired(input: $MakeAccountKycRequiredInput) {
            successful,
            messages {
              message
              code
            }
            result {
              accountNumber
              kycStatus
            }
          }
        }`,
    variables: {
      'MakeAccountKycRequiredInput': { accountNumber },
    },
  },
});

export const mutationManualTED = (merchantCode, bankNumber, branchNumber, accountNumber, useRepresentative) => ({
  payload: {
    query: `
      mutation($merchantCode: String!, $accountNumber: String!, $bankNumber: String!, $branchNumber: String!, $useRepresentative: Boolean!) {
        createManualTed(input: {
          merchantCode: $merchantCode,
          accountNumber: $accountNumber,
          branchNumber: $branchNumber,
          bankNumber: $bankNumber,
          useRepresentative: $useRepresentative,
        }) {
          successful
          messages {
            message
          }
          result{
            transactionId
          }
        }
      }`,
    variables: {
      merchantCode,
      accountNumber,
      bankNumber,
      branchNumber,
      useRepresentative,
    },
  },
});

export const mutationRemoveAccountSuspension = (nationalId, state) => ({
  payload: {
    query: `
      mutation RemoveAccountSuspension($removeAccountSuspensionInput: RemoveAccountSuspensionInput!) {
        removeAccountSuspension(input: $removeAccountSuspensionInput) {
          successful
          messages{
            message
            code
          }
          result {
            nationalId
            state
          }
        }
      }`,
    variables: {
      'removeAccountSuspensionInput': {
        nationalId,
        state,
      },
    },
  },
});

export const mutationMakeAccountSuspended = (nationalId, state, reason) => ({
  payload: {
    query: `
      mutation MakeAccountSuspended($makeAccountSuspendedInput: MakeAccountSuspendedInput!) {
        makeAccountSuspended(input: $makeAccountSuspendedInput) {
          successful
          messages{
            message
            code
          }
          result {
            nationalId
            state
            reason
          }
        }
      }`,
    variables: {
      'makeAccountSuspendedInput': {
        nationalId,
        state,
        reason,
      },
    },
  },
});

export const mutationUpdateAccountData = (accountData) => ({
  payload: {
    query: `
      mutation UpdateAccountData($updateAccountDataInput: UpdateAccountDataInput!) {
        updateAccountData(input: $updateAccountDataInput) {
          successful
          messages{
            message
            code
          }
        }
      }`,
    variables: {
      updateAccountDataInput: accountData,
    },
  },
});

export const mutationUpdateAccountState = (state, nationalId) => ({
  payload: {
    query: `
      mutation UpdateLedgerAccountState($updateLedgerAccountStateInput: UpdateLedgerAccountStateInput!) {
        updateLedgerAccountState(input: $updateLedgerAccountStateInput) {
          successful
          messages{
            message
            code
          }
          result {
            nationalId
            reason
            state
          }
        }
      }`,
    variables: {
      'updateLedgerAccountStateInput': {
        nationalId,
        state,
      },
    },
  },
});

export const mutationUpdateOnboardingDocRequest = (accountNumber, status) => ({
  payload: {
    query: `
      mutation UpdateAccountOnboardingDocumentRequested($updateAccountOnboardingDocumentRequestedInput: UpdateAccountOnboardingDocumentRequestedInput!) {
        updateAccountOnboardingDocumentRequested(input: $updateAccountOnboardingDocumentRequestedInput) {
          successful
          messages{
            message
            code
          }
          result{
            accountNumber
            onboardingDocumentRequested
          }
        }
      }`,
    variables: {
      'updateAccountOnboardingDocumentRequestedInput': {
        accountNumber,
        'onboardingDocumentRequested': status,
      },
    },
  },
});

export const mutationJudicialBlock = (payload) => ({
  payload: {
    query: `
      mutation JudicialOperationTransfer($judicialOperationTransferInput: JudicialOperationTransferInput!){
        judicialOperationTransfer(input: $judicialOperationTransferInput) {
          successful
          messages{
            message
            code
          }
          result{
            status
            transferId
        }
      }
    }`,
    variables: {
      'judicialOperationTransferInput': {
        amount: payload.amount,
        merchantCode: payload.merchantCode,
        operationType: payload.operationType,
      },
    },
  },
});
