import { createActions } from 'redux-actions';

import { AuthConstants } from 'core/constants';

export const {
  authCredentialsRequest: authViewer,
  authLoginRequest: authLogin,
  authLogout,
  authRefreshTokenRequest: authRefreshToken,
  authTokenRequest: setAuthToken,
} = createActions(
  {
    [AuthConstants.AUTH_CREDENTIALS_REQUEST]: () => ({}),
    [AuthConstants.AUTH_LOGIN_REQUEST]: (legacyFlow) => (legacyFlow),
    [AuthConstants.AUTH_LOGOUT]: () => ({}),
    [AuthConstants.AUTH_REFRESH_TOKEN_REQUEST]: () => ({}),
    [AuthConstants.AUTH_TOKEN_REQUEST]:
      ({ accessToken, isLoginFlow }) => ({ accessToken, isLoginFlow }),
  },
);
