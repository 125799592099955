import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';

import { LayoutStyled, DataStyled } from './Layout.styles';

const { ContainerWrapper } = LayoutStyled;

const Container = ({ children }) => {
  const { screenSize } = useSelector(state => state.app);

  return (
    <ContainerWrapper screenSize={screenSize} data-testid="layout-container">
      {children}
    </ContainerWrapper>
  );
};

Container.propTypes = {
  children: PropTypes.node.isRequired,
};

export default {
  Container,
  ...DataStyled,
};

