import { keyMirror } from 'core/modules/helpers';

export const AppConstants = keyMirror({
  DELETE_AUTH_TOKEN: undefined,
  HIDE_ALERT: undefined,
  SET_SCREEN_SIZE: undefined,
  SET_LOCALE: undefined,
  SHOW_ALERT: undefined,
  TOGGLE_MODAL: undefined,
  TOGGLE_SIDEBAR: undefined,
});
