import styled from '@emotion/styled';
import { css } from '@emotion/react';
import { Logout } from '@sumup/icons';

export const LogoutStyled = {
  LogoutWrapper: styled('li')(() => css`
    width:100%;
  `),
  LogoutButton: styled('button')(({ theme }) => css`
    align-items: center;
    background: none;
    border-radius: ${theme.borderRadius.kilo};
    border: 0;
    color: ${theme.colors.white};
    cursor: pointer;
    display: flex;
    font-size: ${theme.iconSizes.kilo};
    height: 40px;
    justify-content: flex-start;
    margin: ${theme.spacings.mega};
    outline: none;
    padding: 4px 10px;
    transition: ${theme.transitions.default};
    width: calc(100% - (2 * 16px));
  `),
  LogoutIcon: styled(Logout)(({ expanded, theme }) => css`
    font-size: ${theme.iconSizes.kilo};
    margin-right: ${expanded ? theme.spacings.mega : 0};
    color: ${theme.colors.white};
    height:22px;
    width: 22px;

    :hover, :active {
      height:22px;
      width: 22px;
      transition: all cubic-bezier(0.4, 0.0, 0.2, 1) 0.3s;
      }
  `),
};
