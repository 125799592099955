import React from 'react';
import { FormattedMessage } from 'react-intl';

const TranslateTokens = {
  birthdate: (
    <FormattedMessage
      id="validate.birthdate"
      defaultMessage="Birthdate must have a valid date"
    />
  ),
  fullname: (
    <FormattedMessage
      id="validate.fullname"
      defaultMessage="Invalid full name"
    />
  ),
  phonenumber: (
    <FormattedMessage
      id="validate.phonenumber"
      defaultMessage="Invalid phone number, follow this pattern: +551199999999"
    />
  ),
  email: (
    <FormattedMessage
      id="validate.email"
      defaultMessage="Invalid email address"
    />
  ),
  unathorized: (
    <FormattedMessage
      id="userManagement.unathorized"
      defaultMessage="Access Denied - Talk with your Supervisor"
    />
  ),
  state: (
    <FormattedMessage
      id="validate.state"
      defaultMessage="State must not have numbers"
    />
  ),
  zipcode: (
    <FormattedMessage
      id="validate.zipcode"
      defaultMessage="Zipcode must have a valid value"
    />
  ),
};

const TranslateMessage = (messageKey) => {
  if (TranslateTokens[messageKey]) {
    return TranslateTokens[messageKey];
  }

  return null;
};

export default TranslateMessage;
