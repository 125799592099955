import { all, call, put, takeLatest } from 'redux-saga/effects';

import { client, getRequestOptions } from 'core/modules/resources';

import { UserManagementConstants } from '../constants';

import {
  mutationAddUser,
  mutationDeleteUser,
  mutationUpdateUser,
} from '../graphql/mutations';
import { queryGetUserList, queryGetRoles } from '../graphql/queries';

export function* addUser({ payload }) {
  try {
    const { data } = yield call(client, {
      ...yield getRequestOptions(),
      ...yield mutationAddUser(payload),
      method: 'POST',
      operationName: 'addUser',
    });

    const successful = data?.addUser?.successful;

    if (!successful) {
      const [ error ] = data?.addUser?.messages;
      throw new Error(error.message);
    }

    yield put({
      type: UserManagementConstants.USER_MANAGEMENT_ADD_USER_SUCCESS,
      payload: data?.addUser?.result,
    });
  }
  catch (error) {
    yield put({
      type: UserManagementConstants.USER_MANAGEMENT_ADD_USER_FAILURE,
      payload: {
        message: error.message,
      },
    });
  }
}

export function* deleteUser({ payload }) {
  try {
    const { data } = yield call(client, {
      ...yield getRequestOptions(),
      ...yield mutationDeleteUser(payload.email),
      method: 'POST',
      operationName: 'deleteUser',
    });

    const successful = data?.deleteUser?.successful;

    if (!successful) {
      const [ error ] = data?.deleteUser?.messages;
      throw new Error(error.message);
    }

    yield put({
      type: UserManagementConstants.USER_MANAGEMENT_DELETE_USER_SUCCESS,
    });
  }
  catch (error) {
    yield put({
      type: UserManagementConstants.USER_MANAGEMENT_DELETE_USER_FAILURE,
      payload: {
        message: error.message,
      },
    });
  }
}

export function* getUserList() {
  try {
    const { data, errors } = yield call(client, {
      ...yield getRequestOptions(),
      ...yield queryGetUserList(),
      method: 'POST',
      operationName: 'getUserList',
    });

    if (!data?.users) {
      throw new Error(errors[0].message);
    }

    yield put({
      type: UserManagementConstants.USER_MANAGEMENT_GET_LIST_SUCCESS,
      payload: {
        data: data?.users,
      },
    });
  }
  catch (error) {
    yield put({
      type: UserManagementConstants.USER_MANAGEMENT_GET_LIST_FAILURE,
      payload: {
        message: error.message,
      },
    });
  }
}

export function* getRoles() {
  try {
    const { data, errors } = yield call(client, {
      ...yield getRequestOptions(),
      ...yield queryGetRoles(),
      method: 'POST',
      operationName: 'getRoles',
    });

    if (!data?.profiles) {
      throw new Error(errors[0].message);
    }

    yield put({
      type: UserManagementConstants.USER_MANAGEMENT_GET_ROLES_SUCCESS,
      payload: {
        data: data?.profiles,
      },
    });
  }
  catch (error) {
    yield put({
      type: UserManagementConstants.USER_MANAGEMENT_GET_ROLES_FAILURE,
      payload: {
        message: error.message,
      },
    });
  }
}

export function* updateUser({ payload }) {
  try {
    const { data } = yield call(client, {
      ...yield getRequestOptions(),
      ...yield mutationUpdateUser(payload),
      method: 'POST',
      operationName: 'updateUser',
    });

    const successful = data?.updateUser?.successful;

    if (!successful) {
      const [ error ] = data?.updateUser?.messages;
      throw new Error(error.message);
    }

    yield put({
      type: UserManagementConstants.USER_MANAGEMENT_UPDATE_USER_SUCCESS,
    });
  }
  catch (error) {
    yield put({
      type: UserManagementConstants.USER_MANAGEMENT_UPDATE_USER_FAILURE,
      payload: {
        message: error.message,
      },
    });
  }
}

export default function* root() {
  yield all([
    takeLatest(UserManagementConstants.USER_MANAGEMENT_ADD_USER_REQUEST, addUser),
    takeLatest(UserManagementConstants.USER_MANAGEMENT_DELETE_USER_REQUEST, deleteUser),
    takeLatest(UserManagementConstants.USER_MANAGEMENT_GET_LIST_REQUEST, getUserList),
    takeLatest(UserManagementConstants.USER_MANAGEMENT_GET_ROLES_REQUEST, getRoles),
    takeLatest(UserManagementConstants.USER_MANAGEMENT_UPDATE_USER_REQUEST, updateUser),
  ]);
};
