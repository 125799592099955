import React from 'react';

function CashIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      fill="none"
      viewBox="0 0 24 24"
    >
      <path
        stroke="#080808"
        strokeLinejoin="round"
        strokeWidth="2"
        d="M22 18V6H2v12h20z"
      />
      <circle
        cx="12" cy="12" r="2" stroke="#080808"
        strokeWidth="2"
      />
      <path
        stroke="#080808"
        strokeWidth="2"
        d="M6 17a3 3 0 00-3-3M18 17a3 3 0 013-3M3 10a3 3 0 003-3M21 10a3 3 0 01-3-3"
      />
    </svg>
  );
}

export default CashIcon;
