import React from 'react';

function CashOutIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      fill="none"
      viewBox="0 0 24 24"
    >
      <path stroke="#080808" strokeWidth="2" d="M20 13a9 9 0 11-9-9" />
      <path
        stroke="#000"
        strokeWidth="2"
        d="M9 11c0 1 .8 1.68 2 2 1.2.32 2 1 2 2M13 11a2 2 0 10-4 0M9 15a2 2 0 104 0"
      />
      <path fill="#080808" d="M10 7H12V9H10z" />
      <path fill="#080808" d="M10 17H12V19H10z" />
      <path
        stroke="#000"
        strokeLinejoin="round"
        strokeWidth="2"
        d="M14 5l4-4 4 4"
      />
      <path stroke="#000" strokeWidth="2" d="M18 1v9" />
    </svg>
  );
}

export default CashOutIcon;
