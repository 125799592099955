import React from 'react';

function TransactionIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="22"
      height="18"
      fill="none"
      viewBox="0 0 22 18"
    >
      <path
        fill="#323E49"
        d="M21.707 6.707l-4.5 4.5-1.414-1.414L18.586 7H12a5 5 0 01-5-5V1h2v1a3 3 0 003 3h6.586l-2.793-2.793L17.207.793l4.5 4.5a1 1 0 010 1.414zM.293 12.707l4.5 4.5 1.414-1.414L3.414 13H10a3 3 0 013 3v1h2v-1a5 5 0 00-5-5H3.414l2.793-2.793-1.414-1.414-4.5 4.5a1 1 0 000 1.414z"
      />
    </svg>
  );
}

export default TransactionIcon;
