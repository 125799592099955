import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { Link as CULink } from '@sumup/circuit-ui/dist/cjs/components/ComponentsContext/components/Link';

const AnchorLink = (props) => {
  const { href, to, ...rest } = props;

  if (href || to) {
    return <Link to={to ?? href} href={href} {...rest} />;
  }

  return <CULink {...props} />;
};

AnchorLink.propTypes = {
  href: PropTypes.string,
  to: PropTypes.string,
};

export default AnchorLink;
