import is from 'is-lite';

/**
 * Define Identifier kind
 * @param {string} value
 * @returns {string}
 */
export const accountHolderIdentifier = (value) => {
  let kind;
  const lengthOnlyNumbers = value.replace(/\D/g, '').length;
  if (/^[a-zA-Z]/.test(value)) {
    kind = 'MERCHANT_CODE';
  }
  else if(lengthOnlyNumbers === 10) {
    kind = 'ACCOUNT_NUMBER';
  }
  else {
    kind = 'NATIONAL_ID';
  };
  return kind;
};

/**
 * get a random integer from range
 * @param {number} minRange
 * @param {number} maxRange
 * @returns {number}
 */
export const getRandomInt = (minRange, maxRange) => {
  const minValue = Math.ceil(minRange);
  const maxValue = Math.floor(maxRange);
  return Math.floor(Math.random() * (maxValue - minValue + 1)) + minValue;
};

/**
 * Creates an object with an mirrored key / values
 * @param {object} obj
 * @returns {object} {}
 */
export const keyMirror = (obj) => {
  const mirrored = {};
  let key;

  if (!(obj instanceof Object && !Array.isArray(obj))) {
    throw new Error('keyMirror(...): Argument must be an object.');
  }

  for (key in obj) {
    // eslint-disable-next-line no-prototype-builtins
    if (obj.hasOwnProperty(key)) {
      mirrored[key] = key;
    }
  }

  return mirrored;
};

/**
 * Get current environment
 * @returns {string}
 */
export const getEnv = () => {
  const ENV = require('core/constants').ENVIRONMENTS;
  const locationArray = window.location.host.split('.');
  const isFleetEnv = locationArray[1] === 'fleet';
  const locationENV = locationArray[1];
  const locationENVFleet = locationArray[2];

  if (isFleetEnv && locationENVFleet === ENV.STAGE) {
    return locationENVFleet;
  }
  if (isFleetEnv && locationENVFleet === ENV.LIVE) {
    return ENV.PRODUCTION;
  }
  if (locationENV === ENV.STAGE || locationENV === ENV.PRODUCTION) {
    return locationENV;
  }

  return ENV.DEVELOPMENT;
};

export const getCountryUrlEnv = () => {
  const ENV = require('core/constants').ENVIRONMENTS;
  const locationArray = window.location.host.split('.');
  const isFleetEnv = locationArray[1] === 'fleet';
  const locationENV = locationArray[1];
  const locationENVFleet = locationArray[2];

  if ((isFleetEnv && locationENVFleet === ENV.STAGE) || locationENV === ENV.STAGE) {
    return 'api.sam-app.ro';
  }
  if ((isFleetEnv && locationENVFleet === ENV.LIVE) || locationENV === ENV.PRODUCTION) {
    return 'api.sumup.com';
  }

  return 'api-theta.sam-app.ro';
};

/**
 * Get Default User Name and Get User Initials
 */

export const getUserInitials = (value = '') => !!value && value.match(/\b(\w)/g).join('');


/**
 * @param {string} value
 * @returns {boolean}
 */
export const isMID = (value) => /[a-zA-Z]+/.test(value);

/**
 * Log grouped messages to the console
 * @param {string} type
 * @param {string} title
 * @param {*} data
 * @param {Object} [options]
 */
export function logger(type, title, data, options = {}) {
  /* istanbul ignore else */
  if (['dev', 'stage'].includes(getEnv())) {
    /* eslint-disable no-console */
    const { collapsed = true, hideTimestamp = false, typeColor = 'gray' } = options;
    const groupMethod = collapsed ? console.groupCollapsed : console.group;
    const date = new Date();
    const parts = [`%c ${type}`];
    const styles = [`color: ${typeColor}; font-weight: lighter;`, 'color: inherit;'];

    if (!hideTimestamp) {
      styles.push('color: gray; font-weight: lighter;');
    }

    const time = `${pad(date.getHours(), 2)}:${pad(date.getMinutes(), 2)}:${pad(date.getSeconds(),2)}`;

    parts.push(`%c${title}`);

    if (!hideTimestamp) {
      parts.push(`%c@ ${time}`);
    }

    /* istanbul ignore else */
    if (!window.HIDE_LOGGER) {
      groupMethod(parts.join(' '), ...styles);
      console.log(data);
      console.groupEnd();
    }
    /* eslint-enable */
  }
}

/**
 * Parses the error object to provide friendly messeges to the user
 * @param {object} data
 * @returns {string}
 */
export function parseErrorObject(data) {
  let output = '';

  if (!is.plainObject(data)) {
    return output;
  }

  try {
    if (Array.isArray(data.errors)) {
      output = data.errors[0].message;
    }
    else if (is.plainObject(data.error)) {
      output = data.error.message;
    }
    else if (data.error && data.error_description) {
      output = data.error_description;
    }
  }
  catch (error) {
    console.error(error); //eslint-disable-line no-console
  }

  return output;
}

/**
 * Compare versions using semver.
 *
 * @param {string} versionA
 * @param {string} versionB
 * @returns {boolean}
 */
export const semverCompare = (versionA, versionB) => {
  const versionsA = versionA.split(/\./g);
  const versionsB = versionB.split(/\./g);

  while (versionsA.length || versionsB.length) {
    const a = Number(versionsA.shift());
    const b = Number(versionsB.shift());

    // eslint-disable-next-line no-continue
    if (a === b) continue;
    // eslint-disable-next-line no-restricted-globals
    return a > b || isNaN(b);
  }

  return false;
};

/**
 * Pad a number with zeros
 * @param {number} num
 * @param {number} length
 * @returns {string}
 */
export function pad(num, length = 2) {
  return `${num}`.padStart(length, '0');
}

/**
 * @param {object} obj
 * @returns {object}
 */
export function removeFalsyValues(obj) {
  /* eslint-disable no-unused-expressions, array-callback-return */
  Object.keys(obj).map(key => {
    (obj[key] && typeof obj[key] === 'object') && (obj[key] = removeFalsyValues(obj[key]));
    (obj[key] === '' || obj[key] === null || obj[key] === undefined) && delete obj[key];
  });

  return Object.keys(obj).length > 0 ? obj : undefined;
};
