import React, { useCallback, useEffect } from 'react';
import { useSelector } from 'react-redux';
import qs from 'qs';

import { BaseStyles, ModalProvider } from '@sumup/circuit-ui';
import { light } from '@sumup/design-tokens';
import { ThemeProvider } from '@emotion/react';

import { OptimizelyProvider } from '@optimizely/react-sdk';

import Routes from 'core/routes';

import { resetApp } from 'core/modules/client';
import { useWindowSize } from 'core/hooks';

import ErrorBoundary from 'core/containers/ErrorBoundary';
import Intl from 'core/containers/Intl';
import Sidebar from 'core/containers/Sidebar';
import Toast from 'core/containers/Toast';

import { getOptimizelyUser, optimizelyClient } from 'core/modules/optimizely';
import { semverCompare } from 'core/modules/helpers';
import ComponentsProvider from 'core/components/ComponentsProvider';
import { GoogleAuthProvider } from 'core/components/GoogleAuth';

import messages from 'config/locales';

import { version as currentVersion } from '../../../../package.json';

import { AppStyled } from './App.styles';

const { AppWrapper } = AppStyled;

const App = () => {
  const { environment, i18n, version } = useSelector(state => state.app);
  const { isLogged, user } = useSelector(state => state.auth);
  const logoutForInactivity = useCallback(() => {
    const timer = 600000;
    const callback = () => resetApp(true);

    let wait = setTimeout(callback, timer);

    // eslint-disable-next-line no-multi-assign
    document.onmousemove = document.mousedown = document.mouseup = document.onkeydown = document.onkeyup = document.focus = () => {
      clearTimeout(wait);
      wait = setTimeout(callback, timer);
    };
  }, []);

  useWindowSize();

  useEffect(() => {
    window.BANKOFFICE = {
      environment,
      version: currentVersion,
    };

    if (!version || semverCompare(window.BANKOFFICE.version, version)) {
      resetApp(true);
    }
  },
  [environment, version]);

  useEffect(() => {
    if (window.location && window.location.search) {
      const params = qs.parse(window.location.search.slice(1));

      if (typeof params.clean !== 'undefined') {
        resetApp();
      }
      else if (typeof params.reset !== 'undefined') {
        resetApp(true);
      }
    }
  }, []);

  useEffect(() => {
    logoutForInactivity();
  }, [logoutForInactivity]);

  let output;

  if (isLogged) {
    output = (
      <AppWrapper>
        <Sidebar />
        <Routes />
      </AppWrapper>
    );
  }
  else {
    output = <Routes />;
  }

  return (
    <Intl locale={i18n.locale.value} messages={messages[i18n.locale.value]}>
      <ThemeProvider theme={light}>
        <OptimizelyProvider
          optimizely={optimizelyClient}
          user={getOptimizelyUser(user)}
        >
          <ComponentsProvider>
            <ErrorBoundary>
              <BaseStyles />
              <ModalProvider>
                <GoogleAuthProvider>
                  {output}
                  <Toast />
                </GoogleAuthProvider>
              </ModalProvider>
            </ErrorBoundary>
          </ComponentsProvider>
        </OptimizelyProvider>
      </ThemeProvider>
    </Intl>
  );
};

export default App;
