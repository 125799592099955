import { handleActions } from 'redux-actions';
import produce from 'immer';

import { SearchConstants } from 'core/constants/search';

export const searchState = {
  value: '',
};

export default {
  search: handleActions({
    [SearchConstants.SET_SEARCH_VALUE]: (state, { payload }) => produce(state, draft => {
      draft.value = payload.value;
    }),
  }, searchState),
};


