export const queryGetAccountHolder = {
  getAccountHolder: (kind, value) => ({
    payload: {
      query: `
      query Account($id: Identifier!) {
        accountHolder(identifier: $id) {
          address {
            city
            complement
            country
            district
            kind
            number
            state
            street
            zipcode
          }
          balance
          birthDate
          currency
          email
          fullName
          gender
          id
          insertedAt
          kycStatus
          ledgerAccountId
          mothersName
          nationalId
          number
          onboardingDocumentRequested
          personType
          phoneNumber
          legalType
          source
          state
          sumupMerchantCode
        }
      }
      `,
      variables: {
        id: {
          kind,
          value,
        },
      },
    },
  }),
  getAccountHolderPJ: (kind, value) => ({
    payload: {
      query: `
      query Account($id: Identifier!) {
        accountHolder(identifier: $id) {
          fantasyName
          companyName
          address {
            city
            complement
            country
            district
            kind
            number
            state
            street
            zipcode
          }
          balance
          currency
          email
          id
          insertedAt
          ledgerAccountId
          nationalId
          number
          personType
          legalType
          phoneNumber
          sumupMerchantCode
          source
          state
          kycStatus
          representative {
            fullName
            birthDate
            phoneNumber
            email
            nationalId
            mothersName
            gender
          }
        }
      }
      `,
      variables: {
        id: {
          kind,
          value,
        },
      },
    },
  }),
};

export const queryGetEarningReportAvailableYears = (merchantCode) => ({
  payload: {
    query: `
      query EarningReportAvailableYears($merchantCode: String!) {
        getEarningReportAvailableYears(merchantCode: $merchantCode) {
          availableYears
        }
    }`,
    variables: { merchantCode },
  },
});

export const queryGetShipmentOrders = (merchantCode) => ({
  payload: {
    query: `
      query($merchantCode: String!) {
        shipmentOrders(merchantCode: $merchantCode) {
          address {
            street
            number
            district
            city
            state
          }
          estimatedDeliveryDate
          globalShipmentId
          insertedAt
          state
          statuses {
            name
            type
            statusChangedAt
          }
          trackingCode
        }
      }`,
    variables: { merchantCode },
  },
});

export const queryGetTransactions = (
  filter = {},
  first,
  nationalId,
  pageNumber
) => ({
  payload: {
    query: `
    query($filter: TransactionFilter!, $nationalId: String!, $first: Int!, $pageNumber: Int) {
      transactionsConnection(filter: $filter, nationalId: $nationalId, first: $first, pageNumber: $pageNumber){
        currentPage
        totalCount
        totalPages
        startDate
        endDate
        pageInfo {
          hasNextPage
          hasPreviousPage
        }
        edges {
          node {
            amount
            direction
            id
            isRefund
            isRefundable
            metadata
            shortDescription
            endToEndId
            transferId
            type
            utcDatetime
          }
        }
      }
    }`,
    variables: {
      filter,
      first,
      nationalId,
      pageNumber,
    },
  },
});

export const queryGetKYCBackgroundCheck = (accountNumber) => ({
  payload: {
    query: `
      query Kyc($accountNumber: String) {
        accountKycBackgroundProcess(accountNumber: $accountNumber) {
          nameMatches
          pepMatches
          dobMatches
          regularKeysiDocument
        }
    }`,
    variables: { accountNumber },
  },
});

export const queryGetCompanyKycProcess = (accountNumber) => ({
  payload: {
    query: `
      query Kyc($accountNumber: String) {
        companyKycProcess(accountNumber: $accountNumber) {
          accountNumber
          pepAndSanction {
            pass
            details {
              name
              result
            }
          }
          companyIdentity {
            pass
            details {
              name
              result
            }
          }
        }
      }
    `,
    variables: { accountNumber },
  },
});

export const queryGetAccountOcr = (accountNumber) => ({
  payload: {
    query: `
      query Kyc($accountNumber: String) {
        accountOcr(accountNumber: $accountNumber) {
          fullName
          motherName
          nationalId
          birthDate
        }
    }`,
    variables: { accountNumber },
  },
});

export const queryGetKYCFacematch = (accountNumber) => ({
  payload: {
    query: `
      query Kyc($accountNumber: String) {
        accountKycFaceMatchProcess(accountNumber: $accountNumber){
          calculatedResponse
          score
          faceMatch
          liveness
          kycFiles {
            documentType
            id
          }
        }
    }`,
    variables: { accountNumber },
  },
});

export const queryGetKYCFIle = (fileID) => ({
  payload: {
    query: `
      query AccountKYCFiles($kycFileIdInput: String) {
        accountKycFiles(kycFileId: $kycFileIdInput) {
          fileContent
          filename
        }
      }`,
    variables: {
      kycFileIdInput: fileID,
    },
  },
});

export const queryGetKYCPepAndSanctions = (accountNumber) => ({
  payload: {
    query: `
      query Kyc($accountNumber: String) {
        accountKycPepAndSanctionsProcess(accountNumber: $accountNumber){
          pepStatus
        }
    }`,
    variables: { accountNumber },
  },
});

export const queryGetUserLogs = (accountId) => ({
  payload: {
    query: `
      query userLogs($accountId: String) {
        userLogs(accountId: $accountId){
          id
          actionDate
          actionOwner
          eventType
          metadata {
            newData
            newState
            oldData
            oldState
            reason
            sentToEmail
            truncatedNumber
          }
        }
    }`,
    variables: { accountId },
  },
});
