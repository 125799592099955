export const queryGetPixInfraction = (dictInfractionId) => ({
  payload: {
    query: `
      query($dictInfractionId: String!) {
        pixInfraction(dictInfractionId: $dictInfractionId) {
          analysisDetails
          analyzedAt
          analyzedBy
          details
          dictInfractionId
          dictInsertedAt
          dictUpdatedAt
          endToEndId
          ledgerTransferId
          payerPspName
          reason
          receiverPspName
          reporterIspb
          reporterPspName
          status
          sumupperNationalDocumentId
          sumupperNationalDocumentKind
        }
      }`,
    variables: { dictInfractionId },
  },
});

export const queryGetPixTransfer = (variables) => ({
  payload: {
    query: `
      query PixTransfer($endToEndId: String!) {
        pixTransfer(endToEndId: $endToEndId){
          __typename
          id
          method
          type
          status
          description
          amount
          accountId
          endToEndId
          ledgerTransferId
          transactionId
          jdpiTransactionId
          jdpiTransactionDate
          return
          receiver {
            __typename
            nationalDocumentKind
            nationalDocumentId
            name
            branchNumber
            accountType
            accountParticipantName
            accountParticipantCode
            accountParticipant
            accountNumber
          }
          payer {
            __typename
            nationalDocumentKind
            nationalDocumentId
            name
            branchNumber
            accountType
            accountParticipantName
            accountParticipantCode
            accountParticipant
            accountNumber
          }
          ledgerTransactions {
            __typename
            id
            direction
            amount
            type
            transferId
            metadata
            shortDescription
            utcDatetime
            isRefund
          }
        }
      }
    `,
    variables,
  },
});

export const queryListPixInfractions = (variables) => ({
  payload: {
    query: `
      query($filter: InfractionFilter!, $after: String, $first: Int!, $pageNumber: Int!) {
        pixInfractions(filter: $filter, first: $first, after: $after, pageNumber: $pageNumber) {
          totalCount
          totalPages
          currentPage
          pageInfo {
              hasPreviousPage
              hasNextPage
          }
          edges {
              node {
                  dictInfractionId
                  ledgerTransferId
                  endToEndId
                  reporterIspb
                  reporterPspName
                  receiverIspb
                  receiverPspName
                  payerIspb
                  payerPspName
                  reason
                  status
                  dictInsertedAt
                  dictUpdatedAt
              }
          }
        }
      }`,
    variables,
  },
});

export const queryListPixAddressKeys = (accountId) => ({
  payload: {
    query: `
    query AddressKey($accountId: String!) {
      pixAddressKeys(accountId: $accountId) {
          id
          ownerName
          keyType
          key
          currentStatus
          active
          accountType
          accountId
      }
  }`,
    variables: { accountId },
  },
});

export const queryGetPixRefund = (dictRefundRequestId) => ({
  payload: {
    query: `
      query($dictRefundRequestId: String!) {
        pixRefund(dictRefundRequestId: $dictRefundRequestId) {
          type
          status
          reporterIspb
          reporterPspName
          rejectionReason
          refundDetails
          receiverIspb
          originalTransactionId
          endToEndIdRefund
          endToEndId
          dictUpdatedAt
          dictRefundRequestId
          dictInfractionId
          dictInsertedAt
          correlationId
          analysisStatus
          analysisDetails
          amount
        }
      }`,
    variables: { dictRefundRequestId },
  },
});

export const queryListPixRefunds = (filter, first, pageNumber) => ({
  payload: {
    query: `
      query($filter: RefundFilter!, $first: Int!, $pageNumber: Int) {
        pixRefunds(filter: $filter, first: $first, pageNumber: $pageNumber) {
          totalCount
          totalPages
          currentPage
          pageInfo {
              hasPreviousPage
              hasNextPage
          }
          edges {
              node {
                type
                reporterIspb
                reporterPspName
                rejectionReason
                receiverIspb
                receiverPspName
                originalTransactionId
                endToEndIdRefund
                endToEndId
                dictUpdatedAt
                dictRefundRequestId
                dictInsertedAt
                correlationId
                analysisStatus
                amount
              }
          }
        }
      }`,
    variables: { filter, first, pageNumber },
  },
});

export const queryCheckPixTransfer = (endToEndId) => ({
  payload: {
    query: `
    query checkPixTransfer($endToEndId: String!) {
      checkPixTransfer(endToEndId: $endToEndId){
      transactionStatus
      endToEndId
      transactionDate
      error {
          code
          description
        }
      }
    }`,
    variables: { endToEndId },
  },
});

export const queryListPixClaims = (accountId) => ({
  payload: {
    query: `
    query Claims($accountId: String!) {
      pixClaims(accountId: $accountId) {
          addressKey{
              id
              ownerName
              keyType
              key
              currentStatus
              active
              accountType
              accountId
          }
          claimType
      }
  }
    `,
    variables: { accountId },
  },
});


export const queryListFraudMark = (nationalId) => ({
  payload: {
    query: `
    query FraudMark($nationalId: String!) {
      fraudMarks(nationalId: $nationalId) {
        correlationId
        creationDate
        dictFraudMarkDatetime
        fraudMarkId
        fraudMarkSituation
        fraudType
        key
        nationalId
      }
  }
    `,
    variables: { nationalId },
  },
});

