import styled from '@emotion/styled';
import { css } from '@emotion/react';

import { Input } from '@sumup/circuit-ui';

const breakPoints = {
  afterTera: css`
    max-width: 1440px;
  `,
  tera: css`
    max-width: 1280px;
  `,
  gigaToTera: css`
    max-width: 1024px;
  `,
  giga: css`
    max-width: 960px;
  `,
  megaToGiga: css`
    max-width: 768px;
  `,
  mega: css`
    max-width: 100%;
  `,
  kiloToMega: css`
    max-width: 100%;
  `,
  kilo: css`
    max-width: 100%;
  `,
  untilKilo: css`
    max-width: 100%;
  `,
};

export const LayoutStyled = {
  ContainerWrapper: styled('div')(({ theme, screenSize }) => css`
    margin: 0 auto;
    width: 100%;
    padding: 0 ${theme.spacings.tera};
    ${breakPoints[screenSize]};
  `),
};

export const DataStyled = {
  DataWrapper: styled('div')(
    ({ darkMode, direction, height, theme, withPadding }) => css`
      background-color: ${darkMode ? theme.colors.n300 : 'white'};
      border-radius: ${theme.borderRadius.byte};
      box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.01), 0 2px 4px 0 rgba(0, 0, 0, 0.1);
      display: flex;
      flex-direction: ${direction};
      flex-wrap: wrap;
      min-height: ${height || 'auto'};
      overflow: hidden;
      padding: ${withPadding ? `${theme.spacings.mega} ${theme.spacings.giga} ${theme.spacings.giga}` : '0'};

      & + & {
        margin-top: ${theme.spacings.mega};
      }
    `
  ),
  DataHeader: styled('div')(
    ({ theme, bordered }) => css`
      align-items: center;
      color: ${theme.colors.n900};
      display: flex;
      flex-wrap: nowrap;
      font-size: ${theme.iconSizes.kilo};
      font-weight: 600;
      justify-content: space-between;
      width: 100%;
      ${bordered && `
        padding-top: ${theme.spacings.giga};
        margin-top: ${theme.spacings.giga};
        border-top: 1px solid hsla(0,0%,0%,.1);
      `}
    `
  ),
  DataRow: styled('div')(
    ({ theme }) => css`
      align-items: center;
      box-shadow: 0 1px 0 ${theme.colors.n200};
      color: ${theme.colors.n900};
      display: flex;
      flex-wrap: nowrap;
      font-size: 14px;
      padding: ${theme.spacings.kilo} 0;
      width: 100%;
    `
  ),
  DataCol: styled('div')(
    ({ theme, muted }) => css`
      color: ${theme.colors.n900};
      padding: ${theme.spacings.byte} 0;
      flex-grow: 1;
      opacity: ${muted ? 0.5 : 1};

      label {
        margin-bottom: 0;

        span {
          font-weight: bold;
        }
      }
    `
  ),
  DataInput: styled(Input)(
    ({ editMode, textTransformat, theme }) => css`
      background-color: ${!editMode && 'transparent'};
      border: ${!editMode && '0'};
      box-shadow: ${!editMode && 'none'} !important;
      color: ${theme.colors.n700};
      font-size: 14px;
      font-weight: 400;
      padding: ${!editMode && '0'};
      text-transform: ${textTransformat ? 'uppercase' : 'none'};

      :hover {
        box-shadow: ${!editMode && 'none'};
      }
    `
  ),
  DataLabel: styled('div')(
    ({ theme }) => css`
      font-size: ${theme.typography.body.two.fontSize};
      font-weight: bold;
    `
  ),
  DataValue: styled('div')(
    ({ theme, editMode }) => css`
      background-color: ${editMode && theme.colors.n200};
      border-radius: ${editMode && '8px'};
      box-shadow: ${editMode && `0 0 0 1px ${theme.colors.n500}`};
      color: ${theme.colors.n700};
      font-weight: 400;
      line-height: ${editMode && theme.spacings.giga};
      font-size: ${theme.typography.body.two.fontSize};
      display: flex;

      padding: ${editMode ? `${theme.spacings.kilo} ${theme.spacings.mega}` : '0'};
    `
  ),
};
