export const RoutesConstants = {
  PUBLIC: {
    LANDING: '/',
    SIGN_IN: '/signin',
    DEMO: '/demo',
  },
  PRIVATE: {
    DASHBOARD: '/',
  },
};
