import produce from 'immer';
import { handleActions } from 'redux-actions';

import { STATUS } from 'core/constants';
import { PixConstants } from '../constants';

export const pixState = {
  answerInfraction: {
    data: [],
    message: '',
    status: STATUS.IDLE,
  },
  infractions: {
    data: null,
    message: '',
    status: STATUS.IDLE,
  },
  infraction: {
    data: [],
    message: '',
    status: STATUS.IDLE,
  },
  refund: {
    data: [],
    message: '',
    status: STATUS.IDLE,
  },
  refunds: {
    data: [],
    message: '',
    status: STATUS.IDLE,
  },
  transfer: {
    data: null,
    message: '',
    status: STATUS.IDLE,
  },
  reportInfraction: {
    data: null,
    message: '',
    status: STATUS.IDLE,
  },
  addressKeys: {
    data: null,
    message: '',
    status: STATUS.IDLE,
  },
  deleteAddressKey: {
    initialStatus: {
      data: [],
      message: '',
      status: STATUS.IDLE,
    },
  },
  checkTransfer: {
    data: null,
    message: '',
    status: STATUS.IDLE,
  },
  createFraudMark: {
    data: null,
    message: '',
    status: STATUS.IDLE,
  },
  fraudMarks: {
    data: null,
    message: '',
    status: STATUS.IDLE,
  },
  claims: {
    data: null,
    message: '',
    status: STATUS.IDLE,
  },
};

export default {
  pix: handleActions(
    {
      [PixConstants.ANSWER_PIX_INFRACTION_FAILURE]: (
        state,
        { payload: { message } }
      ) =>
        produce(state, (draft) => {
          draft.answerInfraction = {
            data: null,
            message,
            status: STATUS.ERROR,
          };
        }),
      [PixConstants.ANSWER_PIX_INFRACTION_REQUEST]: (state) =>
        produce(state, (draft) => {
          draft.answerInfraction.status = STATUS.RUNNING;
        }),
      [PixConstants.ANSWER_PIX_INFRACTION_SUCCESS]: (
        state,
        { payload: { data } }
      ) =>
        produce(state, (draft) => {
          draft.answerInfraction.data = data;
          draft.answerInfraction.status = STATUS.SUCCESS;
        }),
      [PixConstants.CHECK_PIX_TRANSFER_FAILURE]: (
        state,
        { payload: { message } }
      ) =>
        produce(state, (draft) => {
          draft.checkTransfer = {
            data: null,
            message,
            status: STATUS.ERROR,
          };
        }),
      [PixConstants.CHECK_PIX_TRANSFER_REQUEST]: (state) =>
        produce(state, (draft) => {
          draft.checkTransfer.status = STATUS.RUNNING;
        }),
      [PixConstants.CHECK_PIX_TRANSFER_SUCCESS]: (
        state,
        { payload: { data } }
      ) =>
        produce(state, (draft) => {
          draft.checkTransfer.data = data;
          draft.checkTransfer.status = STATUS.SUCCESS;
        }),
      [PixConstants.GET_PIX_INFRACTION_FAILURE]: (
        state,
        { payload: { message } }
      ) =>
        produce(state, (draft) => {
          draft.infraction = {
            data: null,
            message,
            status: STATUS.ERROR,
          };
        }),
      [PixConstants.GET_PIX_INFRACTION_REQUEST]: (state) =>
        produce(state, (draft) => {
          draft.infraction.status = STATUS.RUNNING;
        }),
      [PixConstants.GET_PIX_INFRACTION_SUCCESS]: (
        state,
        { payload: { data } }
      ) =>
        produce(state, (draft) => {
          draft.infraction.data = data;
          draft.infraction.status = STATUS.SUCCESS;
        }),
      [PixConstants.GET_PIX_REFUND_FAILURE]: (
        state,
        { payload: { message } }
      ) =>
        produce(state, (draft) => {
          draft.refund = {
            data: null,
            message,
            status: STATUS.ERROR,
          };
        }),
      [PixConstants.GET_PIX_REFUND_REQUEST]: (state) =>
        produce(state, (draft) => {
          draft.refund.status = STATUS.RUNNING;
        }),
      [PixConstants.GET_PIX_REFUND_SUCCESS]: (state, { payload: { data } }) =>
        produce(state, (draft) => {
          draft.refund.data = data;
          draft.refund.status = STATUS.SUCCESS;
        }),
      [PixConstants.GET_PIX_TRANSFER_FAILURE]: (
        state,
        { payload: { message } }
      ) =>
        produce(state, (draft) => {
          draft.transfer = {
            data: null,
            message,
            status: STATUS.ERROR,
          };
        }),
      [PixConstants.GET_PIX_TRANSFER_REQUEST]: (state) =>
        produce(state, (draft) => {
          draft.transfer.status = STATUS.RUNNING;
        }),
      [PixConstants.GET_PIX_TRANSFER_SUCCESS]: (state, { payload: { data } }) =>
        produce(state, (draft) => {
          draft.transfer.data = data;
          draft.transfer.status = STATUS.SUCCESS;
        }),
      [PixConstants.LIST_INFRACTIONS_FAILURE]: (
        state,
        { payload: { message } }
      ) =>
        produce(state, (draft) => {
          draft.infractions = {
            data: null,
            message,
            status: STATUS.ERROR,
          };
        }),
      [PixConstants.LIST_INFRACTIONS_REQUEST]: (state) =>
        produce(state, (draft) => {
          draft.infractions.status = STATUS.RUNNING;
        }),
      [PixConstants.LIST_INFRACTIONS_SUCCESS]: (state, { payload: { data } }) =>
        produce(state, (draft) => {
          draft.infractions.data = data;
          draft.infractions.status = STATUS.SUCCESS;
        }),
      [PixConstants.LIST_REFUNDS_FAILURE]: (state, { payload: { message } }) =>
        produce(state, (draft) => {
          draft.refunds = {
            data: null,
            message,
            status: STATUS.ERROR,
          };
        }),
      [PixConstants.LIST_REFUNDS_REQUEST]: (state) =>
        produce(state, (draft) => {
          draft.refunds.status = STATUS.RUNNING;
        }),
      [PixConstants.LIST_REFUNDS_SUCCESS]: (state, { payload: { data } }) =>
        produce(state, (draft) => {
          draft.refunds.data = data;
          draft.refunds.status = STATUS.SUCCESS;
        }),
      [PixConstants.REPORT_INFRACTION_FAILURE]: (
        state,
        { payload: { message } }
      ) =>
        produce(state, (draft) => {
          draft.reportInfraction = {
            data: null,
            message,
            status: STATUS.ERROR,
          };
        }),
      [PixConstants.REPORT_INFRACTION_REQUEST]: (state) =>
        produce(state, (draft) => {
          draft.reportInfraction.status = STATUS.RUNNING;
        }),
      [PixConstants.REPORT_INFRACTION_SUCCESS]: (
        state,
        { payload: { data } }
      ) =>
        produce(state, (draft) => {
          draft.reportInfraction.data = data;
          draft.reportInfraction.status = STATUS.SUCCESS;
        }),
      [PixConstants.LIST_PIX_ADDRESS_KEYS_FAILURE]: (
        state,
        { payload: { message } }
      ) =>
        produce(state, (draft) => {
          draft.addressKeys = {
            data: null,
            message,
            status: STATUS.ERROR,
          };
        }),
      [PixConstants.LIST_PIX_ADDRESS_KEYS_REQUEST]: (state) =>
        produce(state, (draft) => {
          draft.addressKeys.status = STATUS.RUNNING;
        }),
      [PixConstants.LIST_PIX_ADDRESS_KEYS_SUCCESS]: (state, { payload }) => {
        const { data } = payload;
        return produce(state, (draft) => {
          draft.addressKeys.data = data;
          draft.addressKeys.status = STATUS.SUCCESS;
        });
      },
      [PixConstants.LIST_PIX_CLAIMS_FAILURE]: (
        state,
        { payload: { message } }
      ) =>
        produce(state, (draft) => {
          draft.claims = {
            data: null,
            message,
            status: STATUS.ERROR,
          };
        }),
      [PixConstants.LIST_PIX_CLAIMS_REQUEST]: (state) =>
        produce(state, (draft) => {
          draft.claims.status = STATUS.RUNNING;
        }),
      [PixConstants.LIST_PIX_CLAIMS_SUCCESS]: (state, { payload }) => {
        const { data } = payload;
        return produce(state, (draft) => {
          draft.claims.data = data;
          draft.claims.status = STATUS.SUCCESS;
        });
      },
      [PixConstants.DELETE_PIX_ADDRESS_KEY_FAILURE]: (
        state,
        { payload: { message, key } }
      ) =>
        produce(state, (draft) => {
          draft.deleteAddressKey = {
            ...draft.deleteAddressKey,
            [key]: {
              data: null,
              message,
              status: STATUS.ERROR,
            },
          };
        }),
      [PixConstants.DELETE_PIX_ADDRESS_KEY_REQUEST]: (
        state,
        { payload: { key } }
      ) =>
        produce(state, (draft) => {
          draft.deleteAddressKey = {
            ...draft.deleteAddressKey,
            [key]: { status: STATUS.RUNNING, data: null },
          };
        }),
      [PixConstants.DELETE_PIX_ADDRESS_KEY_SUCCESS]: (
        state,
        { payload: { data, key } }
      ) =>
        produce(state, (draft) => {
          draft.deleteAddressKey = {
            ...draft.deleteAddressKey,
            [key]: { data, status: STATUS.SUCCESS },
          };
        }),
      [PixConstants.INCLUDE_FRAUD_MARK_FAILURE]: (
        state,
        { payload: { message } }
      ) =>
        produce(state, (draft) => {
          draft.createFraudMark = {
            data: null,
            status: STATUS.ERROR,
            message,
          };
        }),
      [PixConstants.INCLUDE_FRAUD_MARK_REQUEST]: (
        state, { payload }
      ) =>
        produce(state, (draft) => {
          draft.createFraudMark = { data: payload, status: STATUS.RUNNING };
        }),
      [PixConstants.INCLUDE_FRAUD_MARK_SUCCESS]: (
        state, { payload }
      ) =>
        produce(state, (draft) => {
          draft.createFraudMark = {
            data: payload,
            status: STATUS.SUCCESS,
          };
        }),
      [PixConstants.LIST_FRAUD_MARK_FAILURE]: (
        state,
        { payload: { message } }
      ) =>
        produce(state, (draft) => {
          draft.fraudMarks = {
            data: null,
            status: STATUS.ERROR,
            message,
          };
        }),
      [PixConstants.LIST_FRAUD_MARK_REQUEST]: (
        state, { payload }
      ) =>
        produce(state, (draft) => {
          draft.fraudMarks = { data: payload, status: STATUS.RUNNING };
        }),
      [PixConstants.LIST_FRAUD_MARK_SUCCESS]: (state, { payload }) => {
        const { data } = payload;
        return produce(state, (draft) => {
          draft.fraudMarks.data = data;
          draft.fraudMarks.status = STATUS.SUCCESS;
        });
      },
    },
    pixState
  ),
};
