import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Sidebar as CircuitSidebar } from '@sumup/circuit-ui';
import * as Sentry from '@sentry/browser';

import { toggleSidebar } from 'core/actions/app';
import Logo from 'core/components/Logo';
import Logout from 'core/components/Logout';
import Profile from 'core/containers/Profile';

import Reset from '../../components/Reset/Reset';

import { SidebarStyled } from './Sidebar.styles';
import options from './options';
import SidebarMenu from './SidebarMenu';

const { SidebarWrapper, SidebarNav, SidebarToggle, SidebarFooter } =
  SidebarStyled;

const Sidebar = () => {
  const { sidebar } = useSelector((state) => state.app);
  const { expanded } = sidebar;
  const dispatch = useDispatch();

  const handleCollapse = () => {
    dispatch(toggleSidebar(!expanded));

    Sentry.addBreadcrumb({
      category: 'ui',
      message: `Toggle Sidebar: "${expanded}" modal`,
      level: 'info',
    });
  };

  return (
    <SidebarWrapper data-testid="sidebar" expanded={expanded} label="sidebar">
      <SidebarNav expanded={expanded}>
        <Profile expanded={expanded} />
        <CircuitSidebar.NavList>
          <SidebarMenu
            options={options}
            expanded={expanded}
            handleCollapse={handleCollapse}
          />
          <Logout expanded={expanded} />
          <Reset expanded={expanded} />
        </CircuitSidebar.NavList>
        <SidebarFooter expanded={expanded}>
          <Logo inTopbar={true} expanded={expanded} />
        </SidebarFooter>
      </SidebarNav>
      <SidebarToggle expanded={expanded} onClick={handleCollapse} />
    </SidebarWrapper>
  );
};

export default Sidebar;
