import { keyMirror } from 'core/modules/helpers';

export const AuthConstants = keyMirror({
  AUTH_CREDENTIALS_REQUEST: undefined,
  AUTH_CREDENTIALS_SUCCESS: undefined,
  AUTH_CREDENTIALS_FAILURE: undefined,
  AUTH_LOGIN_REQUEST: undefined,
  AUTH_LOGIN_SUCCESS: undefined,
  AUTH_LOGIN_FAILURE: undefined,
  AUTH_LOGOUT: undefined,
  AUTH_REFRESH_TOKEN_REQUEST: undefined,
  AUTH_REFRESH_TOKEN_SUCCESS: undefined,
  AUTH_REFRESH_TOKEN_FAILURE: undefined,
  AUTH_TOKEN_REQUEST: undefined,
  AUTH_TOKEN_SUCCESS: undefined,
  AUTH_TOKEN_FAILURE: undefined,
});
