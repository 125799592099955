import * as Sentry from '@sentry/browser';
import { applyMiddleware, createStore, compose, combineReducers } from 'redux';
import { persistStore, persistReducer } from 'redux-persist';
import { connectRouter, routerMiddleware } from 'connected-react-router';
import createSagaMiddleware from 'redux-saga';
import storage from 'redux-persist/lib/storage';
import autoMergeLevel2 from 'redux-persist/lib/stateReconciler/autoMergeLevel2';
import createSentryMiddleware from 'redux-sentry-middleware';

import rootSagas from 'core/sagas';
import reducers from 'core/reducers';

import { history } from 'core/modules/history';
import { datadog } from 'core/modules/datadog';

import config from 'config';

import authMiddleware from './auth-middleware';

Sentry.init({
  ...config.sentry,
  beforeBreadcrumb(breadcrumb) {
    return breadcrumb.category === 'ui.click' ? null : breadcrumb;
  },
});

datadog();

const { NODE_ENV } = process.env;

const sagaMiddleware = createSagaMiddleware();
const routersMiddleware = routerMiddleware(history);
const sentryMiddleware = createSentryMiddleware(Sentry, {});

const middlewares = [
  routersMiddleware,
  sagaMiddleware,
];

if (NODE_ENV !== 'test') {
  middlewares.push(authMiddleware());
}

/* istanbul ignore next */
if (NODE_ENV === 'development') {
  const { createLogger } = require('redux-logger');
  const invariant = require('redux-immutable-state-invariant').default;

  middlewares.push(invariant());
  middlewares.push(createLogger({
    collapsed: true,
    predicate: () => !window.HIDE_ACTIONS,
  }));
}

// PR to change that term.
// The central objective is to stop using terms that no longer fit into an inclusive culture and that seeks diversity.
// https://github.com/rt2zz/redux-persist/pull/1242
const persistConfig = {
  key: 'bankoffice',
  storage,
  blacklist: ['recon', 'router', 'search'],
  stateReconciler: autoMergeLevel2,
};

const createAppReducer = combineReducers({
  ...reducers,
  router: connectRouter(history),
});

const createRootReducers = (state, action) => {
  if (action.type === 'AUTH_LOGOUT') {
    window.location.reload();
  }

  return createAppReducer(state, action);
};

const persistedReducer = persistReducer(persistConfig, createRootReducers);
const composeEnhancer = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

middlewares.push(sentryMiddleware);

const configStore = (preloadedState = {}) => {
  const store = createStore(
    persistedReducer,
    preloadedState,
    composeEnhancer(applyMiddleware(...middlewares)),
  );

  const persistor = persistStore(store);

  sagaMiddleware.run(rootSagas);

  /* istanbul ignore next */
  if (module.hot) {
    module.hot.accept('reducers', () => {
      store.replaceReducer(persistedReducer);
    });
  }

  return { persistor, store };
};

const { persistor, store } = configStore();

global.store = store;

export { persistor, store };
