import React from 'react';

function MobileTopupIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="22"
      fill="none"
      viewBox="0 0 16 22"
    >
      <path
        fill="#323E49"
        fillRule="evenodd"
        d="M13 4H9V2h4a3 3 0 013 3v14a3 3 0 01-3 3H5a3 3 0 01-3-3v-3h2v3a1 1 0 001 1h8a1 1 0 001-1V5a1 1 0 00-1-1z"
        clipRule="evenodd"
      />
      <path
        fill="#323E49"
        d="M10 17a1 1 0 11-2 0 1 1 0 012 0zM0 4c0 1.683 1.375 2.602 2.742 2.966C3.775 7.242 4 7.683 4 8a1 1 0 11-2 0H0c0 1.306.835 2.418 2 2.83V12h2v-1.17A3.001 3.001 0 006 8c0-1.683-1.375-2.602-2.742-2.966C2.225 4.758 2 4.317 2 4a1 1 0 112 0h2a3.001 3.001 0 00-2-2.83V0H2v1.17A3.001 3.001 0 000 4z"
      />
    </svg>
  );
}

export default MobileTopupIcon;
