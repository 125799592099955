import React, { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Flip, ToastContainer, toast } from 'react-toastify';

import { hideAlert } from 'core/actions';

import {
  AnimationFlip,
  Container,
  Progress,
} from './Toast.styles';

const WrappedToastContainer = ({ ...props }) => {
  const alerts = useSelector(state => state.app.alerts);
  const dispatch = useDispatch();
  const handleClose = useCallback(id => dispatch(hideAlert(id)),[dispatch]);

  useEffect(() => {
    /* istanbul ignore else */
    if (alerts.length > 0) {
      alerts.map(alert => toast[alert.status](
        alert.message, {
          onClose: () => handleClose(alert.id),
          position: toast.POSITION.TOP_CENTER,
          autoClose: alert.timeout || 3000,
        }
      ));
    }
  }, [alerts, handleClose]);

  return (
    <Container data-testid="toast">
      <Progress>
        <AnimationFlip>
          <ToastContainer transition={Flip} {...props} />
        </AnimationFlip>
      </Progress>
    </Container>
  );
};

export default WrappedToastContainer;
