import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import { getUserInitials } from 'core/modules/helpers';

import { ProfileStyled } from './Profile.styles';

const {
  UserEmail,
  UserDetails,
  UserGroup,
  UserName,
  UserThumb,
  UserNoThumb,
} = ProfileStyled;

const fallbackImage = `${process.env.PUBLIC_URL}/images/user/profile.svg`;

const UserInfo = ({ email, expanded, handleClick, name, thumb}) => {
  const [profileImage, setProfileImage] = useState(fallbackImage);

  useEffect(() => {
    fetch(thumb).then(res => {
      if(res.ok) {
        setProfileImage(thumb);
      }
    });
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);


  return (
    <UserGroup onClick={handleClick}>
      {profileImage
        ? <UserThumb image={profileImage} alt={name} />
        : <UserNoThumb>{getUserInitials(name)}</UserNoThumb>}
      <UserDetails expanded={expanded}>
        {expanded && (
          <>
            <UserName>{name}</UserName>
            <UserEmail>{email}</UserEmail>
          </>
        )}
      </UserDetails>
    </UserGroup>
  );
};

UserInfo.propTypes = {
  email: PropTypes.bool,
  expanded: PropTypes.bool,
  handleClick: PropTypes.func.isRequired,
  name: PropTypes.string.isRequired,
  thumb: PropTypes.string.isRequired,
};

export default UserInfo;
