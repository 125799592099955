export const queryGetViewer = () => ({
  payload: {
    query: `
    query Viewer {
      viewer {
        allowedActions
        email 
        fullName
        profile
      }
    }`,
  },
});