import React from 'react';

function AtmWithdrawIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="22"
      height="22"
      fill="none"
      viewBox="0 0 22 22"
    >
      <path
        fill="#212933"
        d="M14 15.5a1.5 1.5 0 100-3 1.5 1.5 0 000 3zM9.5 8a1.5 1.5 0 11-3 0 1.5 1.5 0 013 0zM12.7 7l-5.715 8h2.458l5.714-8H12.7z"
      />
      <path
        fill="#212933"
        fillRule="evenodd"
        d="M11 0C4.925 0 0 4.925 0 11s4.925 11 11 11 11-4.925 11-11S17.075 0 11 0zM2 11a9 9 0 1118 0 9 9 0 01-18 0z"
        clipRule="evenodd"
      />
    </svg>
  );
}

export default AtmWithdrawIcon;
