const checkBackgroundCheckType = (value) => {
  let checked;

  if(typeof(value) === 'boolean') {
    checked = value;
  }
  else {
    checked = parseInt(value, 10);
  }

  return checked;
};

export const backgroundToKycChecks = (data) => Object.keys(data).map(item => ({
      isRegular: data[item] === null
        ? null
        : !!checkBackgroundCheckType(data[item]),
      name: item,
      result: data[item],
    }));

export const companyToKycChecks = (data) => {
  const companyCheckResults = {
    'not_applicable': null,
    'fail': false,
    'pass': true,
    'undefined_check': null,
  };

  return data
  .filter(check => check.result !== 'undefined_check')
  .map(check => ({
    isRegular: companyCheckResults[check.result],
    name: check.name,
    result: check.result,
  }));
};
